













































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { vuex } from "@/store";
import { ICellRendererParams } from "ag-grid-community";

import { GroupVm, PortalUserVM } from "@/interfaces/PortalUserVM";
import GroupMemberTable from "./GroupMemberTable.vue";
import { namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class GroupMemberActionCellRenderer extends Vue {
  params!: ICellRendererParams;
  @globals.Getter selectedGroup!: GroupVm;

  // $refs!: {
  // };

  mounted() {
    // if (!this.params || this.params.value == undefined) return;
    // this.checked = this.params.value;
  }

  onUserPerformanceOverview() {
    (this.params.context as GroupMemberTable).onUserPerformanceOverview(this.params.data as PortalUserVM);
  }

  onNewMessage() {
    (this.params.context as GroupMemberTable).onNewMessage(this.params.data as PortalUserVM);
  }

  onShowDeletePupilConfirmationDlg() {
    (this.params.context as GroupMemberTable).onShowDeletePupilConfirmationDlg(this.params.data as PortalUserVM);
  }

  get isGroupAdmin() {
    return vuex.auth.isGroupAdmin;
  }

  get user() {
    let rowData = (this.params?.data as PortalUserVM);
    return rowData;
  }

  get isDynamicGroup() {
    return (this.params.context as GroupMemberTable).selectedGroup.isDynamic;
  }
}
