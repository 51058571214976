














































































































  import _ from "lodash";
  import rest from "@/rest";
  import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
  import { Action, namespace } from "vuex-class";
  import { vuex } from "@/store";
  import { List } from "linq-collections";

  // Components
  import TusFileDrop from "@/components/Shared/TusFileDrop.vue";

  // Interfaces
  import {
    ExBundleAssignmentGroupVM,
    ExBundleAssignmentVM,
  } from "../../interfaces/ExBundleAssignmentGroupVM";
  import {
    GroupVm,
    PortalUserVM,
    SelectListItem,
    SubGroupVm,
  } from "@/interfaces/PortalUserVM";
  import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
  import { GroupExtVm } from "@/interfaces/GroupExtVm";

  //const auth = namespace("auth");
  const globals = namespace("globals");

  @Component({
    components: {
      TusFileDrop
    },
  })
  export default class GroupMembersExcelUploadDlg extends Vue {
    @PropSync("showDialog") syncedShowDialog!: boolean;
    @Prop() group!: GroupVm;

    @globals.Getter selectedGroup!: GroupExtVm;

    busyOnImport = false;
    fileUploadBusy = false;
    bundleSearch = "";
    bundleNames: string[] = [];
    selectedBundleName = "";
    fileUploadList: string[] = [];
    processedFile: string = "";
    outputList: string[] = [];

    mounted() {}

    @Watch("syncedShowDialog")
    async onShowDialogChanged(val: boolean) {
      if (this.syncedShowDialog) {
        this.busyOnImport = false;
        this.fileUploadBusy = false;
        this.selectedBundleName = "";
        this.bundleNames = [];
        this.processedFile = "";
        this.outputList = [];

        // Disable body scroll on iOS
        this.$nextTick(async () => {
          await this.$globalHelper.delay(10);
          const modal = document.querySelector(".modal");
          disableBodyScroll(modal);
        });
      } else {
        // Dialog closing
        clearAllBodyScrollLocks();
      }
    }

    beforeDestroy() {
      clearAllBodyScrollLocks();
    }

    async getExcelTemplate() {
      await rest
        .url("groupAdmin/loadGroupMembersExcel")
        .post(this.selectedGroup)
        .then((fileName) => {
          if(fileName)
            this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
        })
    }

    onFileUploadedToTempDir(response: any) {
      if (!response.success) {
        this.$store.commit("ux/SB_FAILURE", {
          message: response.message,
          timeout: 0
        });
        return;
      }

      // Add file to list and start import on server
      this.fileUploadList.push(response.fileName);
      this.processFileUploadList();
    }

    async processFileUploadList() {
      if (this.fileUploadList.length == 0 || this.fileUploadBusy)
        return;

      this.fileUploadBusy = true;
      this.processedFile = this.fileUploadList.pop()!;

      await this.onImport();
    }

    async onImport() {
      this.busyOnImport = true;
      await rest
      .url("groupAdmin/uploadGroupMemberFile")
      .query({ fileName: this.processedFile, groupId: this.selectedGroup.id })
      .get()
      .then((response) => {
        if (response && response.length > 0)
        this.outputList = this.outputList.concat(response);
      })
      .finally(() => {
        this.fileUploadBusy = false;
        this.busyOnImport = false;
        this.$emit('fileLoaded:ExcelUpload');
        this.processFileUploadList();
      })
    }

    onDeleteOutput() {
      this.outputList = [];
    }

    onClose() {
      clearAllBodyScrollLocks();
      this.syncedShowDialog = false;
    }
  }
  