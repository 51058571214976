import {
  ArcElement,
  BarElement,
  CategoryScale,
  TimeScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// https://github.com/chartjs/chartjs-adapter-moment
import 'chartjs-adapter-moment';

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement,
  ArcElement,
  ChartDataLabels
);
