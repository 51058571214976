import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { RootStore } from "../types";
import { OrganizationVm, PortalUserVM } from "@/interfaces/PortalUserVM";
import { ExBundleVM } from "@/interfaces/ExBundleVM";
import { ExerciseVM } from "@/interfaces/ExerciseVM";
import { ExSubject } from "@/interfaces/ExSubject";
import rest from "@/rest";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory3 } from "@/interfaces/ExCategory3";
import { action, createModule, mutation } from "vuex-class-component";
import { SubGroupVm } from "@/interfaces/SubGroupVm";
import { SurveyDetailedVm } from "@/interfaces/SurveyDetailedVm";
import { ExSurveyVm } from "@/interfaces/ExSurveyVm";

const VuexModule = createModule({
  strict: false,
  namespaced: "globals",
});

export class Globals extends VuexModule {
  sSelectedOrg: OrganizationVm | null = null;
  sSelectedGroup: GroupExtVm | null = null;
  sSelectedSubGroup: SubGroupVm | null = null;
  sSelectedGroupMembers: any = null;
  sSelectedUser: any = null;
  sSelectedCategory1: ExCategory1 | null = null;
  sSelectedCategory2: ExCategory2 | null = null;
  sSelectedCategory3: ExCategory3 | null = null;
  sSelectedExBundleAssignment: any = null;
  sGroupAssignment: ExBundleAssignmentVM | null = null;
  sSelectedExercise: any = null;
  _SelectedSurvey: ExSurveyVm | null = null;
  sIsInPrintMode = false;
  sShowPrintUsersQrCode = false;

  // CompMultipleSteps
  // childQuestions: null,
  // selectedChildQuestionId: 0,
  // selectedStepQuestion: null,

  // groupExerciseOverview
  sGroupExerciseOverviewSortByAlphabet = true
  sGroupExerciseOverviewSortOrderAscending = true

  // getters
  get selectedUser() {
    return this.sSelectedUser;
  }
  get selectedOrg() {
    return this.sSelectedOrg;
  }
  // get pupilsOfSelectedClass() {
  //   return this.sPupilsOfSelectedClass;
  // }
  get selectedGroup() {
    return this.sSelectedGroup;
  }
  get selectedSubGroup() {
    return this.sSelectedSubGroup;
  }
  get selectedCategory1() {
    return this.sSelectedCategory1;
  }
  get selectedCategory2() {
    return this.sSelectedCategory2;
  }
  get selectedCategory3() {
    return this.sSelectedCategory3;
  }
  get selectedExBundleAssignment() {
    return this.sSelectedExBundleAssignment;
  }
  get selectedGroupAssignment() {
    return this.sGroupAssignment;
  }
  get selectedExercise() {
    return this.sSelectedExercise;
  }
  get selectedSurvey() {
    return this._SelectedSurvey;
  }
  get isInPrintMode() {
    return this.sIsInPrintMode;
  }
  get showPrintUsersQrCode() {
    return this.sShowPrintUsersQrCode;
  }
  get groupExerciseOverviewSortByAlphabet() {
    return this.sGroupExerciseOverviewSortByAlphabet;
  }
  get groupExerciseOverviewSortOrderAscending() {
    return this.sGroupExerciseOverviewSortOrderAscending;
  }
  // childQuestions(){
  //   return this.childQuestions;
  // }
  // selectedChildQuestionId(){
  //   return this.selectedChildQuestionId;
  // }
  // selectedChildQuestion(){
  //   if (!this.childQuestions)
  //     return null;

  //   return this.childQuestions[this.selectedChildQuestionId];
  // }

  @action async SelectGroup(group: GroupExtVm | null) {
    this.setGroup(group);
    // Get users
    // let users: PortalUserVM[] = [];
    // if (group)
    //   users = await rest.url("groupAdmin/getUsers").query({ groupId: group.id }).get();

    // this.setUsersOfGroup(users);
  }

  // mutations
  @mutation setUser(user: PortalUserVM | null) {
    this.sSelectedUser = user;
  }
  @mutation setUsersOfGroup(users: PortalUserVM[]) {
    this.sSelectedGroupMembers = users;
  }
  @mutation setOrg(org: OrganizationVm | null) {
    this.sSelectedOrg = org;
  }
  @mutation setGroup(group: GroupExtVm | null) {
    this.sSelectedGroup = group;
  }
  @mutation setSubGroup(subGroup: SubGroupVm | null) {
    this.sSelectedSubGroup = subGroup;
  }
  @mutation setCategory1(cat1: ExCategory1 | null) {
    this.sSelectedCategory1 = cat1;
  }
  @mutation setCategory2(cat2: ExCategory2 | null) {
    this.sSelectedCategory2 = cat2;
  }
  @mutation setCategory3(cat3: ExCategory3 | null) {
    this.sSelectedCategory3 = cat3;
  }
  @mutation setExBundleAssignment(exBundleAssignment: ExBundleAssignmentVM) {
    this.sSelectedExBundleAssignment = exBundleAssignment;
  }
  @mutation setGroupAssignment(exBundleAssignment: ExBundleAssignmentVM) {
    this.sGroupAssignment = exBundleAssignment;
  }
  @mutation setExercise(exercise: ExerciseVM) {
    this.sSelectedExercise = exercise;
  }
  @mutation setSurvey(survey: ExSurveyVm) {
    this._SelectedSurvey = survey;
  }
  @mutation setShowPrintUsersQrCode() {
    this.sShowPrintUsersQrCode = !this.showPrintUsersQrCode;
    this.sIsInPrintMode = true;
  }
  @mutation setViewToPrintMode(enablePrintView: boolean) {
    this.sIsInPrintMode = enablePrintView;
  }
  @mutation setGroupExerciseOverviewSortByAlphabet(value: boolean) {
    this.sGroupExerciseOverviewSortByAlphabet = value;
  }
  @mutation setGroupExerciseOverviewSortOrderAscending(value: boolean) {
    this.sGroupExerciseOverviewSortOrderAscending = value;
  }
  // setChildQuestions(value: ExQuestion[] | null) => {
  //   this.childQuestions = value;
  // }
  // setSelectedChildQuestionId(value: number) => {
  //   this.selectedChildQuestionId = value;
  // }
  // setSelectedChildQuestion(value: ExQuestion) => {
  //   if (!this.childQuestions)
  //     return;

  //   this.childQuestions[this.selectedChildQuestionId] = value;
  // }
};
