





























// import _, { forEach } from "lodash";
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";

import router from "@/router";

import { List, Enumerable } from 'linq-collections';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
// import * as scorm from "scorm-again";
// import { Scorm12API } from  "scorm-again/dist/scorm12";
import "scorm-again/dist/scorm12";
import { ExerciseVM } from "@/interfaces/ExerciseVM";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import { ExResultVM } from "@/interfaces/ExDataSet";

// // import { quizPlayerEventBus } from '@/main';
// Components

// Interfaces

const globals = namespace("globals");
const auth = namespace("auth");
const ux = namespace("ux");
const quizplayerstore = namespace("quizplayerstore");

@Component({
  components: {}
})
export default class ScormPlayer extends Vue {
  @globals.Getter selectedExercise!: ExerciseVM;
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
  // @quizplayerstore.Getter childQuestions!: ExQuestion[];
  // @quizplayerstore.Getter selectedChildQuestion!: ExQuestion;
  // @quizplayerstore.Getter selectedChildQuestionId!: number;
  // @quizplayerstore.Getter choicesRightAnswered!: boolean;
  // @quizplayerstore.Getter allChoicesSelected!: boolean;
  // @quizplayerstore.Action setQuestion: any;
  // @quizplayerstore.Action getSelectListsFromQuestion: any;
  @quizplayerstore.Getter browserBackNavigationInProgress: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isLearner: any;
  @ux.State darkTheme: any;
  @ux.State reserveSpaceForDrawer: any;

  // $refs!: {};

  mounted() {
    // if (this.selectedExercise == null) {
    //   router.push("/");
    //   return;
    // }

    // router.beforeEach((to, from) => {
    //   // console.log("router.beforeEach: ", value);
    //   console.log("||router.beforeEach: " + from.name + " => " + to.name);
    // });

    // router.afterEach((to, from) => {
    //   console.log("||router.afterEach: " + from.name + " => " + to.name);
    // });

    if (!this.selectedExercise.serviceUri) {
      vuex.ux.SB_FAILURE({
        message: "Schulung kann nicht gestartet werden",
        timeout: 3000
      });

      return;
    }

    // https://github.com/pssolanki111/scorm-again/tree/main
    let settings: any = {};

    if (this.selectedExBundleAssignment.writeResults)
      settings.lmsCommitUrl = "./api/exercises/scorm12Commit";

    settings.asyncCommit = true;
    settings.autocommit = true;
    settings.autocommitSeconds = 300;
    settings.logLevel = 4; // 1 => DEBUG, 2 => INFO, 3 => WARN, 4 => ERROR, 5 => NONE
    settings.alwaysSendTotalTime = true;
    // (SCORM 1.2) Used to override a module's cmi.core.lesson_status so that a pass/fail is determined based on a mastery score
    // and the user's raw score, rather than using whatever status is provided by the module.
    // An example of this would be if a module is published using a Complete/Incomplete final status,
    // but the LMS always wants to receive a Passed/Failed for quizzes, then we can use this setting to override the given final status.
    // HINT: If set to true you will have to provide the mastery_score in 'this.cmi.student_data.mastery_score'
    settings.mastery_override = (this.selectedExercise.scormSettings?.minScore ?? 0) > 0;

    // Add authentication
    settings.xhrWithCredentials = true;
    let token = window.localStorage.getItem("digiClassAuth");
    settings.xhrHeaders = { Authorization: "Bearer " + token };

    // console.log("SCORM API settings:", settings);

    // https://eslint.org/docs/latest/rules/no-undef
    /*global Scorm12API, a*/
    /*eslint no-undef: "error"*/
    (window as any).API = new Scorm12API(settings);

    // (window as any).API.on('LMSSetValue.cmi.*', function(CMIElement, value) {
    //   (window as any).API.storeData(true);
    //   console.log('CMI data changed. Current Type and value: \n');
    //   console.log(typeof (window as any).API.cmi);
    //   console.log((window as any).API.cmi);

    //   var render = (window as any).API.renderCommitCMI(true);

    //   fetch("./scorm14", {
    //     method: "POST",
    //     headers: {'Content-Type': 'application/json',
    //               'Access-Control-Allow-Origin': '*'},
    //     body: JSON.stringify(render)
    //   }).then(res => {
    //     console.log("Request complete! response:", res);
    //   });
    // });

    // (window as any).API.on("LMSInitialize", function() {
    //   console.log("LMSInitialize Event");
    // });
    // (window as any).API.on("LMSCommit", function() {
    //   console.log("LMSCommit Event");
    //   // debugger;
    // });
    // (window as any).API.on("LMSGetValue", function(value) {
    //   console.log("LMSGetValue", value);
    //   if (value == "cmi.core.lesson_status") {
    //     console.log("get lesson_status");
    //   }
    //   if (value == "cmi.suspend_data") {
    //     console.log("get cmi.suspend_data");
    //   }
    // });
    (window as any).API.on("LMSSetValue", function(name, value) {
      // console.log("LMSSetValue Event", name, value);

      // Workaround because some SCOs are showing alert messages on errors caused by wrong usage of the api
      // In our case the SCO generated with ScormHero tries to write cmi.core.score.scaled for a SCORM 1.2 package which is only available for SCORM 2004
      // console.log("clear error");
      (window as any).API.clearSCORMError("true");
    });

    // Do not write lesson status back to LMS if in test mode
    if (this.selectedExBundleAssignment.writeResults) {
      (window as any).API.on('LMSSetValue.cmi.core.lesson_status', async function(CMIElement, value) {
        // Typical status values: "not attempted", "not started", "in progress", "incomplete", "completed", "passed", and "failed"
        console.log("set lesson_status: " + value);
        console.log("CMI:", (window as any).API.cmi);
        if (typeof(value) === "string" && value.toLowerCase() == "passed") {
          console.log("Exercise passed!!!");
          // (window as any).API.storeData(true);
          //   var render = (window as any).API.renderCommitCMI(true);
          // console.log("cmi.core.score.raw", (window as any).API.cmi.core.score.raw);

          let minScore = (window as any).API.cmi.core.score.min;
          minScore = isNaN(minScore) || isNaN(parseInt(minScore)) ? 0 : minScore;
          let maxScore = (window as any).API.cmi.core.score.max;
          maxScore = isNaN(maxScore) || isNaN(parseInt(maxScore)) ? 0 : maxScore;
          let score = (window as any).API.cmi.core.score.raw;
          console.log("score: " + score);
          score = isNaN(score) || isNaN(parseInt(score)) ? 0 : score;

          let result = <ExResultVM>{
            exerciseId: (window as any).API.cmi.core.entry,
            minScore: minScore,
            maxScore: maxScore,
            score: score
          };
          let updatedAssignment: ExBundleAssignmentVM = await rest.url("exercises/writeScormResult").post(result);
        }
      });

      (window as any).API.on('LMSSetValue.cmi.core.score.raw', async function(CMIElement, value) {
        console.log("set core.score.raw: " + value);
        let lessonStatus = (window as any).API.cmi.core.lesson_status;
        console.log("typeof lessonStatus: " + typeof lessonStatus);
        console.log("core.score.lesson_status: " + lessonStatus);

        if (typeof(lessonStatus) === "string" && lessonStatus.toLowerCase() == "passed") {
          console.log("Update result");

          let minScore = (window as any).API.cmi.core.score.min;
          minScore = isNaN(minScore) || isNaN(parseInt(minScore)) ? 0 : minScore;
          let maxScore = (window as any).API.cmi.core.score.max;
          maxScore = isNaN(maxScore) || isNaN(parseInt(maxScore)) ? 0 : maxScore;
          let score = (window as any).API.cmi.core.score.raw;
          score = isNaN(score) || isNaN(parseInt(score)) ? 0 : score;

          let result = <ExResultVM>{
            exerciseId: (window as any).API.cmi.core.entry,
            minScore: minScore,
            maxScore: maxScore,
            score: score
          };
          let updatedAssignment: ExBundleAssignmentVM = await rest.url("exercises/writeScormResult").post(result);
        }
      });
    }

    (window as any).API.on("LMSFinish", async function(value) {
      console.log("LMSFinish Event", (window as any).API.cmi);
      vuex.quizplayerstore.navigateBackToSavedRouteName();
    });

    (window as any).API.on("LMSGetErrorString", function(value) {
      console.log("LMSGetErrorString Event", value);
    });

    (window as any).API.on("SequencePrevious", function(value) {
      console.log("SequencePrevious Event", value);
    });

    (window as any).API.on("SequenceNext", function(value) {
      console.log("SequenceNext Event", value);
    });

    // Preset user data
    let dataFromLms = {
      cmi: {
        core: {
          entry: this.selectedExercise.id,
          student_id: this.selectedExBundleAssignment.userId,
          student_name: this.selectedExBundleAssignment.user?.fullName,
          credit: "credit" // have to be set to 'credit' to get mastery_overwrite to work
        },
        student_data: {
          mastery_score: this.selectedExercise.scormSettings?.minScore.toString()
        }
      }
    };

    // console.log("dataFromLms:", dataFromLms);

    // data passed from LMS
    if (this.selectedExercise.scormSuspendData) {
      console.log("Restore SCORM suspend Data from LMS");
      dataFromLms = JSON.parse(this.selectedExercise.scormSuspendData);
      // console.log("dataFromLms", dataFromLms);
    }

    // console.log("CMI:", (window as any).API.cmi);

    // let dataFinished = {
    //   cmi: {
    //     suspend_data: "[[\"WfB5hmQTOX\",\"950W6z6qGG\",\"AOxtLSQJsj\",\"OMVQXrqMIFu4IqdaN8E9\",\"a|0|1|2|3\",\"started\",\"result\",\"completed\",\"a|5|6|7\",\"b|T\",\"score\",\"totalAnsweredCorrectly\",\"totalAnsweredIncorrectly\",\"totalAnswered\",\"a|A|B|C|D\",\"n|1c\",\"n|2\",\"n|0\",\"o|E|F|G|H|G\",\"o|8|9|I|9\",\"answer\",\"submitted\",\"answeredCorrectly\",\"a|K|L|M\",\"GRJ4CJaZEd\",\"o|N|O|9|9\",\"2TWiNIV1M5\",\"E6UUK1kO1j\",\"a|Q|R\",\"o|N|S|9|9\",\"completedAt\",\"a|7|U\",\"2024-01-10T16:43:11.397Z\",\"o|V|9|W\",\"o|4|J|P|T|X\"],\"Y\"]",
    //     launch_data: "",
    //     comments: "",
    //     comments_from_lms: "",
    //     core: {
    //         student_id: "12345678",
    //         student_name: "Robert Gerstner",
    //         lesson_location: "",
    //         credit: "",
    //         lesson_status: "passed",
    //         entry: "exerciseId",
    //         lesson_mode: "normal",
    //         exit: "suspend",
    //         session_time: "00:01:25.77",
    //         score: {
    //             raw: "100",
    //             min: "0",
    //             max: "100"
    //         }
    //     },
    //     objectives: {},
    //     student_data: {
    //         mastery_score: "",
    //         max_time_allowed: "",
    //         time_limit_action: ""
    //     },
    //     student_preference: {
    //         audio: "",
    //         language: "",
    //         speed: "",
    //         text: ""
    //     },
    //     interactions: {}
    //   }
    // };

    (window as any).API.loadFromJSON(dataFromLms, '');

    if (this.selectedExercise.scormSettings?.openingMode == "OpenInWindow") {
      if (!this.$vuetify.breakpoint.mobile) {
        let topOffset = window.screen.height * 0.05;
        let leftOffset = window.screen.width * 0.1;
        let width = window.screen.width * 0.8;
        let height = window.screen.height * 0.8;
        window.open(this.selectedExercise.serviceUri, "_blank", `top=${topOffset}, left=${leftOffset}, width=${width}, height=${height}, popup, toolbar`);
        // this.openInNewWindow(this.selectedExercise.serviceUri);
      }
      else {
        window.open(this.selectedExercise.serviceUri, "_blank", `width=window.screen.width, height=window.screen.height, menubar=yes, status=yes` );
      }
    }
    if (this.selectedExercise.scormSettings?.openingMode == "OpenInTab") {
      window.open(this.selectedExercise.serviceUri, "_blank", "popup=false");
    }

    this.changeStyle();
  }

  // openInNewWindow(url: string) {
  //   let link = document.createElement("a");
  //   link.href = url;
  //   link.target = "_blank";
  //   link.click();
  // }

  created() {
    // Disable body scroll on iOS
    this.$nextTick(async () => {
      await this.$globalHelper.delay(10);
      const modal = document.querySelector('.modal');
      disableBodyScroll(modal);
    });
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }


  get fitView() {
    return this.reserveSpaceForDrawer ? "margin-left: 256px;" : "";
  }

  changeStyle() {
    // adjust old style
    let themeLink = document.getElementById('exerciseTheme');
    if (themeLink) {
      if (this.darkTheme)
        themeLink.setAttribute("href", "/styles/exercises-dark.css");
      else
        themeLink.setAttribute("href", "/styles/exercises-light.css");

      return;
    }

    // Create new one
    let styles = document.createElement('link');
    styles.type="text/css";
    styles.rel="stylesheet";
    styles.id="exerciseTheme";
    if (this.darkTheme)
      styles.href="/styles/exercises-dark.css";
    else
      styles.href="/styles/exercises-light.css";
    document.head.appendChild(styles);
  }

  get backgroundColor() {
    if (this.$vuetify.theme.dark)
      // return "background-image: linear-gradient(160deg, #6497BC 0%, #00355B 50%); ";
      return "background-image: linear-gradient(160deg, #4089BE 0%, #29597B 50%); ";

    return "background-image: linear-gradient(160deg, #E3F2FD 0%, #B5DBFF 70%); ";
  }

  // onKey() {
  //   console.log("Key pressed");
  // }

  goBack() {
    router.go(-1);
  }
}
