import { Criteria, GroupExtVm, PortalUserVM, SubGroupVm } from "@/interfaces/GroupExtVm";

export class GroupExtVmClass implements GroupExtVm {
  id = 0;
  organizationId = 0;
  orgName = "";
  name = "";
  isDynamic = false;
  criterias =  [] as Criteria[];
  allowModify = true;
  subGroups = [] as SubGroupVm[];
  userCount = 0;
  users = [] as  PortalUserVM[];
  readAccessUserCount = 0;
  readAccessUsers = [] as PortalUserVM[];
  lastChangedBy = "";
  lastChangeDate = new Date();
  searchValue = null;
}