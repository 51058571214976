


























import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import CompText from './CompText.vue';
import Linebreak from './CompLinebreak.vue';
import CompImage from './CompImage.vue';
import CompVideo from './CompVideo.vue';
import CompPdf from './CompPdf.vue';
import CompHtml from './CompHtml.vue';
import CompSpeak from './CompSpeak.vue';
import CompSelect from './CompSelect.vue';
import CompRight from './CompRight.vue';
import CompWrong from './CompWrong.vue';
import CompSignature from './CompSignature.vue';
import CompSurveyRating from './CompSurveyRating.vue';
import CompSurveySlider from './CompSurveySlider.vue';
import CompSurveyComment from "./CompSurveyComment.vue";


@Component({
  name: 'CompBlock',
  components: {
    dchtmlelement: () => import('./dchtmlelement.vue'),
    Linebreak,
    CompText,
    CompImage,
    CompVideo,
    CompPdf,
    CompHtml,
    CompSpeak,
    CompSelect,
    CompRight,
    CompWrong,
    CompSignature,
    CompSurveyRating,
    CompSurveySlider,
    CompSurveyComment
  }
})
export default class CompBlock extends Vue {
  @Prop() alignment!: string;
  @Prop() elements!: any[];
  @Prop() finalized!: boolean;
  @Prop() size!: string; // e.g. 24px
  @Prop() color!: string; // e.g. #00A1EF
  @Prop() weight!: string; // normal, bold, bolder, lighter, 100, 200 ... 900
  @Prop() fontStyle!: string; // normal, italic, oblique

  // $refs!: {};

  mounted() {}

  // After using vuex as to store choice state, this emit can be removed
  // async onChoiceSelection(id: number, isRight: boolean) {
  //   this.$emit("changed:CompSelect", id, isRight);
  // }

  // async onTextClicked(isRight: boolean) {
  //   this.$emit("clicked:Text", isRight);
  // }

  get fontSize() {
    if (this.size == null)
      return "18px";
    if (isNaN(Number(this.size))) // Number(this.size) != NaN)
      return this.size;
    return this.size + "px";
  }
}
