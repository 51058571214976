








































































































































import _ from "lodash";
import rest from "@/rest";
import wretch, { WretcherError } from "wretch";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { vuex } from "@/store";
import { List } from "linq-collections";
import SurveyExerciseOverview from "@/components/SurveyComponents/SurveyExerciseOverview.vue";
import SurveyQuantityChart from "@/components/SurveyComponents/SurveyQuantityChart.vue";
import SurveyTimeChart from "@/components/SurveyComponents/SurveyTimeChart.vue";
import SurveyScoreResult from "@/components/SurveyComponents/SurveyScoreResult.vue";
import SurveyRatingResult from "@/components/SurveyComponents/SurveyRatingResult.vue";
import SurveyCommentList from "@/components/SurveyComponents/SurveyCommentList.vue";
// interfaces
import { RestResponse } from "@/interfaces/RestResponse";
import { PortalUserVM, OrganizationVm } from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import { GroupVm } from "@/interfaces/GroupVm";
import { SubGroupVm } from "@/interfaces/SubGroupVm";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory3 } from "@/interfaces/ExCategory3";
import { Criteria, GroupExtVm } from "@/interfaces/GroupExtVm";
import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";
import { SurveyDetailedVm, SurveyExerciseVm, SurveyResultVm } from "@/interfaces/SurveyDetailedVm";
import { ExSurveyVm } from "@/interfaces/ExSurveyVm";

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    SurveyExerciseOverview,
    SurveyQuantityChart,
    SurveyTimeChart,
    SurveyScoreResult,
    SurveyRatingResult,
    SurveyCommentList
  }
})
export default class SurveyEvaluationView extends Vue {
  @globals.Getter selectedOrg!: OrganizationVm;
  @globals.Getter selectedGroup!: GroupVm;
  @globals.Getter selectedSubGroup!: SubGroupVm;
  @globals.Getter selectedCategory1!: ExCategory1 | null;
  @globals.Getter selectedCategory2!: ExCategory2 | null;
  @globals.Getter selectedCategory3!: ExCategory3 | null;
  @globals.Getter selectedSurvey!: ExSurveyVm | null;
  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isLearner: any;

  orgs: OrganizationVm[] = [];
  localSelectedOrg: OrganizationVm | null = null;
  groups: GroupExtVm[] | null = null;
  selectedGroupId: number = 0;
  // selectedSubGroupId: number = 0;
  survey: SurveyDetailedVm[] | null = [];
  selectedExercise: number = 0;
  paramDir: ParamDictionary = { dictionary: {}};

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Umfragen', disabled: false, href: '/surveys' },
    { text: 'Umfrageanalyse', disabled: true, href: '/surveyEvaluation' },
 ];

  async mounted() {
    // if (this.selectedGroup == null) {
    //   router.push("/");
    //   return;
    // }
    // if (this.selectedGroup)
    //   this.selectedGroupId = this.selectedGroup?.id ?? 0;
    // else
    //   this.selectedGroupId = 0;

    await this.updateOrgList();
    await this.getGroups();

    // if (this.selectedGroup)
    this.getSurvey();
  }

  getQuantityChartColumnCnt(result: SurveyResultVm): number {
    let cnt = 4;
    if (!result || result.scoreMax == 0)
      cnt += 4;

    if (!result.trendAnalysis || result.trendAnalysis.length == 0)
      cnt += 4;

    return cnt;
  }

  async updateOrgList() {
    // this.orgs = await rest.url("groupAdmin/getGroupAdminOrganizations").get();
    let params = <ParamDictionary>{dictionary: {}};
    params.dictionary!["LoadAllOrgs"] = "false";
    params.dictionary!["AddAllOrgsSelection"] = "false";
    this.orgs = await rest.url("userManagement/loadOrganizations").post(params);

    // if (this.isAdmin || this.isOrgAdmin) {
    //   let organizations = new List(this.orgs);
    //   organizations.insert(0, <OrganizationVm>{ id: 0, name: "Alle Organisationen" });
    //   this.orgs = organizations.toArray();
    // }

    if (this.orgs.length > 0) {
      if (this.selectedOrg) {
        if (new List(this.orgs).any(s => s.id == this.selectedOrg.id)) {
          let org = new List(this.orgs).single(s => s.id == this.selectedOrg.id);
          this.localSelectedOrg = org;
          return;
        }
      }
      vuex.globals.setOrg(this.orgs[0]);
    } else {
      vuex.globals.setOrg(null);
    }
    this.localSelectedOrg = this.selectedOrg;
  }

  async onOrgChanged() {
    vuex.globals.setOrg(this.localSelectedOrg);
    await this.getGroups();
    // await this.getUsers();
    // await this.loadCategories("cat1");
    this.getSurvey();
  }

  async getGroups() {
    if (this.selectedOrg == null) return;
    this.selectedGroupId = 0;
    // this.selectedSubGroupId = 0;
    this.groups = await rest
      .query({ orgId: this.selectedOrg.id, asGroupAdmin: false })
      .url("groupAdmin/getMyGroups").get();
    if (this.groups == undefined || this.groups.length == 0) {
      vuex.globals.setGroup(null);
      vuex.globals.setSubGroup(null);
      // console.log("no group selected");
      return;
    }

    // Add "Alle Gruppen" to groups lists
    new List(this.groups).insert(0, <GroupExtVm>{
      id: 0,
      name: "Alle Gruppen",
      isDynamic: false,
      criterias: <Criteria[]>{},
      organizationId: 0,
      orgName: this.selectedOrg?.name ?? "",
      allowModify: false,
      subGroups:[],
      readAccessUserCount: 0,
      readAccessUsers: [],
      searchValue: "",
      lastChangedBy: "",
      lastChangeDate: new Date(),
      userCount: 0,
      users: []
    });

    // Try to select last selected group
    if (this.groups.length > 0 && this.selectedGroup != null) {
      let lastSelectedGroup = this.groups.find(g => g.id == this.selectedGroup.id) ?? null;
      if (lastSelectedGroup) {
        this.selectedGroupId = lastSelectedGroup.id;
        vuex.globals.setGroup(lastSelectedGroup);
        // console.log("last group selected");
      }
    }

    // Select first group
    if (this.selectedGroupId == 0 && this.groups.length > 0) {
      this.selectedGroupId = this.groups[0].id;
      vuex.globals.setGroup(this.groups[0]);
      // console.log("first group selected");
      // console.log("selectedGroup: ", this.selectedGroup);
    }
  }

  async onGroupChanged() {
    // console.log("onGroupChanged: ", this.selectedGroupId);
    let selectedGroup = this.groups!.find(g => g.id == this.selectedGroupId) ?? null;
    vuex.globals.setGroup(selectedGroup);
    // this.selectFirstSubGroup();
    // await this.getUsers();
    // await this.loadCategories("cat1");
    this.getSurvey();
  }

  async getSurvey() {
    // let pupilId: string = null;
    // if (this.selectedUser != null)
    //   pupilId = this.selectedUser.id;
    this.survey = null;

    if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["OrgId"] = (this.selectedOrg?.id ?? 0).toString();
    this.paramDir.dictionary["GroupId"] = (this.selectedGroupId).toString();
    this.paramDir.dictionary["BundleId"] = (this.selectedSurvey?.exBundle?.id ?? 0).toString();

    this.survey = await rest.url("groupAdmin/getSurvey").post(this.paramDir);
  }

  // Chart data functions
  // getRatingData(result: SurveyResultVm) {
  //   if (!result.scoreCounts)
  //     return null;

  //   const labels = new List(result.scoreCounts).select(x => x.score).toArray();
  //   const counts = new List(result.scoreCounts).select(x => x.count).toArray();

  //   // barColor = "#4089be50"
  //   let barColor = this.$store.state.ux.colorAccent + "80"; // Add transparency
  //   //const barColors = ["red", "green","blue","orange","brown","yellow", "pink"];
  //   let barColors: string[] = [];
  //   labels.forEach(element => {
  //     barColors.push(barColor);
  //   });

  //   return {
  //     labels: labels,
  //     datasets: [{
  //       backgroundColor: barColors,
  //       data: counts
  //     }]
  //   }
  // }

  goBack() {
    router.go(-1);
  }
}
