





























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { vuex } from "@/store";
import { ICellRendererParams } from "ag-grid-community";
// import moment, { duration } from "moment";

import { PortalUserVM } from "@/interfaces/PortalUserVM";

@Component({
  components: {
  }
})
export default class GroupMemberContentCellRenderer extends Vue {
  params!: ICellRendererParams;
  // $refs!: {
  // };

  mounted() {
    // if (!this.params || this.params.value == undefined) return;
    // this.checked = this.params.value;
  }

  // get isGroupAdmin() {
  //   return vuex.auth.isGroupAdmin;
  // }

  get user() {
    let rowData = (this.params?.data as PortalUserVM);
    return rowData;
  }
}
