






























import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";
import router from "@/router";
import QuizPlayer from '../components/ExerciseComponents/QuizPlayer.vue';
import ScormPlayer from '../components/ExerciseComponents/ScormPlayer.vue';
import ExFinished from '../components/ExerciseComponents/ExFinished.vue';
import { ExDataSet, ExChoice, ExQuestion } from '../interfaces/ExDataSet';
import { ExerciseVM } from '../interfaces/ExerciseVM';
// import { ExBundleVM } from '@/interfaces/ExBundleVM';
import de from 'vuetify/src/locale/de';
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";

const globals = namespace("globals");
const ux = namespace("ux");

@Component({
  components: {
    QuizPlayer,
    ScormPlayer,
    ExFinished
  }
})
export default class Exercise extends Vue {
  @globals.Getter selectedExercise!: ExerciseVM;
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
  @ux.State reserveSpaceForDrawer: any;

  exProgress: number = 0;
  showFinishDialog: boolean = false;
  resultInPercent: number = 0;

  // $refs!: {};

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Schulungen', disabled: true, href: '/exercises' },
  ];

  mounted() {
    if (this.selectedExercise == null) {
        router.push("/");
        return;
    }

    //this.$refs.backButton.scrollIntoView();
    this.$globalHelper.scrollToTop();
  }

  @Watch("showFinishDialog")
  async onShowFinishDialogChanged(val: boolean) {
    if (!this.showFinishDialog) {
        // window.alert("Finish dialog closed!");
        this.goBack();
    }
  }

  setProgress(progress: number) {
    this.exProgress = progress;
  }

  quizFinished(resultInPercent: number) {
    this.resultInPercent = resultInPercent;
    this.exProgress = 100;
    if (this.selectedExercise.type == "Rule" ||
      this.selectedExercise.type == "ExplanationVideo" ||
      this.selectedExercise.type == "Signature"
    ) {
        this.goBack();
    } else {
        this.showFinishDialog = true;
    }
  }

  get fitView() {
    return this.reserveSpaceForDrawer ? "calc(100vw - 256px)" : "100vw";
  }

  async goBack() {
    // console.log("this.selectedExercise.type", this.selectedExercise.type);
    if (this.selectedExercise.type?.toLowerCase() == "scorm") {
      // console.log("Exercises.vue: For SCORM exercises navigate to /exerciseBundle")
      // router.push("/exerciseBundle");
      console.log("Exercises.vue: navigateBackToSavedRouteName for SCORM exercise");
      vuex.quizplayerstore.navigateBackToSavedRouteName();
      return;
    }

    // console.log("Exercises.vue: goBack()");
    router.go(-1);
  }
}
