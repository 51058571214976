import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { signalrEventBus } from '@/main';
import Store from "@/store";

// https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app

export default class L3rnOnlineHub {
  connection: HubConnection = new HubConnectionBuilder()
  .withUrl("/signalr/l3rnonline-hub", {
    accessTokenFactory: () => window.sessionStorage.getItem("digiClassAuth")!
  })
  .configureLogging(LogLevel.Information)
  .build();

  startedPromise: Promise<void> | null = null;

  constructor() {
    this.connection.onclose(() => this.start());
    this.start();

    this.connection.on('SendMessage', (message, messageType) => {
      // console.log("SignalR SendMessage received: ", message);
      // const id = this.connection.connectionId;
      signalrEventBus.$emit('messageReceived', message, messageType);
    });

    this.connection.on('UserFileImportUpdateProgress', (progress) => {
      signalrEventBus.$emit('userFileImportUpdateProgress', progress);
    });

    this.connection.on('UserFileImportFinished', (message, status) => {
      signalrEventBus.$emit('userFileImportFinished', { message: message, status: status });
    });

    this.connection.on('ZipArchiveCreationFinished', (zipFileName, message, status) => {
      signalrEventBus.$emit('zipArchiveCreationFinished', { zipFileName: zipFileName, message: message, status: status });
    });

    // Update of dynamic groups
    this.connection.on('DynamicGroupUpdateProgress', (progress) => {
      // console.log("isAdmin: " + Store.getters["auth/isAdmin"]);
      // console.log("isGroupAdmin: " + Store.getters["auth/isGroupAdmin"]);
      if (!Store.getters["auth/isAdmin"] && !Store.getters["auth/isOrgAdmin"] && !Store.getters["auth/isGroupAdmin"])
        return;

      signalrEventBus.$emit('dynamicGroupUpdateProgress', progress);
    });

    this.connection.on('DynamicGroupUpdateFinished', (message, status) => {
      if (!Store.getters["auth/isAdmin"] && !Store.getters["auth/isOrgAdmin"] && !Store.getters["auth/isGroupAdmin"])
        return;

      signalrEventBus.$emit('dynamicGroupUpdateFinished', { message: message, status: status });
    });

    // Inform about new chats and update approval chat counts
    this.connection.on('UpdateApprovalChat', (userId) => {
      if (!Store.getters["auth/enableBundleApprovalView"] || Store.getters["auth/loggedInUserId"] == userId) {
        return;
      }

      signalrEventBus.$emit('updateApprovalChat');
    });
  }

  start(): Promise<void> {
      this.startedPromise = this.connection.start().catch(err => {
      console.error('Failed to connect with hub', err);
      // return new Promise((resolve, reject) =>
      //   setTimeout(() => start().then(resolve).catch(reject), 5000));
    })
    return this.startedPromise!;
  }

  async ensureConnection() {
    console.log("SignalR connection state: " + this.connection.state)
    if (this.connection.state != HubConnectionState.Disconnected)
      return;

    console.log("SignalR do reconnect");
    await this.start();
    console.log("SignalR reconnected");
  }

  get ConnectionId() {
    return this.connection.connectionId;
  }

  // not used
  sendMessageToServer(message: string) {
    // console.log("sendMessageToServer", message);
    if (!this.startedPromise)
      return;

    this.startedPromise
      .then(() => this.connection.invoke('ClientMessage', message))
      .catch(console.error);
  }
}