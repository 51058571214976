


































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import rest from "@/rest";
import de from 'vuetify/src/locale/de';
import { ParamDictionary } from "@/interfaces/ParamDictionary";

@Component({})
export default class FileDrop extends Vue {
  $refs!: {
    upload: HTMLElement;
  };

  @Prop() url!: string;
  @Prop() localPath!: string;
  @Prop() bundleId!: string;
  @Prop() label!: string | null;
  fileParam: ParamDictionary = { dictionary: {}};
  dragover = false;
  uploading = false;
  dropzone!: Element;
  fileupload!: HTMLElement;
  files: FileList | null = null;
  fileIndex = 0;
  fileProgress: string | null = null;
  lastResponse: any = null;
  isBusy = false;

  mounted () {
    // To register listeners, we have to know the html elements
    this.dropzone = this.$el;

    // register drag and drop listeners on your dropzone / v-sheet
    if (this.dropzone) {
      // register all drag & drop event listeners
      this.dropzone.addEventListener("dragenter", e => {
        e.preventDefault();
        this.dragover = true;
      });

      this.dropzone.addEventListener("dragleave", e => {
        e.preventDefault();
        this.dragover = false;
      });

      this.dropzone.addEventListener("dragover", e => {
        e.preventDefault();
        this.dragover = true;
      });

      this.dropzone.addEventListener("drop", e => {
        e.preventDefault();
        const dragevent = e as DragEvent;
        if(dragevent.dataTransfer) {
          if (this.isBusy)
            return;

          this.dragover = false;
          this.uploading = true;
          // this.uploadFile(dragevent.dataTransfer.files[0]);
          this.uploadFiles(dragevent.dataTransfer.files);
        }
      });

      // register input listener
      // this.dropzone.addEventListener("click", e => {
      //   e.preventDefault();
      //   if(fileupload) {
      //     debugger;
      //     fileupload.click();
      //   }
      // });

      // this.dropzone.addEventListener("keypress", e => {
      //   e.preventDefault();
      //   const keyEvent = e as KeyboardEvent;
      //   if (keyEvent.key === "Enter") {
      //     if(this.fileupload)
      //       this.fileupload.click();
      //   }
      // });
    }
  }

  onUploadClick() {
    this.$refs.upload.click();
  }

  onFileSelected(e: any) {
    // let excel = e.target.files || e.dataTransfer.files
    // if (!excel.length)
    //     return;
    // let file = excel[0];
    // this.uploadFile(file);
    const target = (e.target as HTMLInputElement);
    if(target.files) {
        // this.uploadFile(target.files[0]);
        if (this.isBusy)
          return;

        this.uploadFiles(target.files);
    }
  }

  uploadFiles(files: FileList) {
    if (!files || files.length == 0)
      return;

    this.isBusy = true;

    this.files = files;
    this.fileIndex = 0;
    this.fileProgress = `${this.fileIndex + 1} of ${this.files.length}`;
    this.uploadFile(this.files[this.fileIndex]);
  }

  uploadFile(file: File) {
    if (this.fileParam == null || this.fileParam.dictionary == null)
      return;

    this.fileParam.dictionary["BundleId"] = this.bundleId;
    this.fileParam.dictionary["LocalPath"] = this.localPath;
    this.fileParam.dictionary["FileName"] = file.name;

    let reader = new FileReader();
    reader.onload = async e => {
      if (this.fileParam == null || this.fileParam.dictionary == null)
        return;

      this.fileParam.dictionary["Data"] = (e as any).target.result;
      if (this.url) {
        console.log('Upload file: ' + file.name);
        await rest.url(this.url).post(this.fileParam)
            .then((response) => {
              //vuex.ux.SB_SUCCESS(this.$t(this.$hg.tl.shared.fileUploadSuccess, {file: file.name}));
              this.lastResponse = response;
            })
            .finally(() => {
            });
      } else {
        // console.log('emit loaded:FileDrop');
        this.$emit('loaded:FileDrop', this.fileParam);
      }

      this.fileIndex++;

      if (this.files != null && this.fileIndex < this.files.length) {
        this.fileProgress = `${this.fileIndex + 1} of ${this.files.length}`;
        this.uploadFile(this.files[this.fileIndex]);
        return;
      }

      // Finished
      this.isBusy = false;
      this.$emit('uploaded:FileDrop', this.lastResponse);
      this.uploading = false;
    };

    reader.readAsDataURL(file);
  }

  get dlgBackgroundColor() {
    return this.$vuetify.theme.dark ? "#ececec" : "#B0BEC5";
  }
}
