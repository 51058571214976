



























































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

import router from "@/router";

@Component({
  components: {}
})
export default class Gdpr extends Vue {
  @Prop({ default: true }) showBackButton!: boolean;

  mounted() {
    this.$globalHelper.scrollToTop();
  }

  goBack() {
    router.go(-1);
  }
}
