





































































































import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { vuex } from "@/store";
import rest from "@/rest";

// Import pdfmake-wrapper and the fonts to use
import { ICreatePDF, PdfMakeWrapper } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import { Txt, IText } from 'pdfmake-wrapper';
import { Img, IImg } from 'pdfmake-wrapper';

// components
import UserTable from "@/components/UserManagement/UserTable.vue";

// interfaces
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
import { PortalUserVM } from "@/interfaces/PortalUserVM";
import { GroupVm } from "@/interfaces/GroupVm";
import { UserCertificateVm } from "@/interfaces/UserCertificateVm";
import moment from "moment";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

@Component({
  components: {
    // EditUser
  }
})
export default class UserDetailsDlg extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() user!: PortalUserVM;
  panel = [];
  userGroups: GroupVm[] | null = null;
  userCertificates: UserCertificateVm[] | null = null;
  busy = false;

  // $refs!: {
  // };

  mounted() {
  }

  @Watch("syncedShowDialog")
  async onShowDialog(value: boolean) {
    if (!value) return;
    this.userGroups = null;
    this.userCertificates = null;
    this.userGroups = await rest.url("orgAdmin/loadUserGroups").post(this.user);
    this.userCertificates = await rest.url("orgAdmin/loadUserCertificates").post(this.user);
  }

  certificateTitle(certificate: UserCertificateVm) {
    if (!certificate)
      return "";
    if (certificate.title)
      return certificate.title;
    return certificate.bundleName;
  }

  async onLoadPdf(certificate: UserCertificateVm) {
    let pdf = await this.createPdfDocument(certificate);
    pdf.create().download(`Zertifikat - ${this.certificateTitle(certificate)}.pdf`);
  }

  async onPrintPdf(certificate: UserCertificateVm) {
    let pdf = await this.createPdfDocument(certificate);
    pdf.create().print();
  }

  onLoadCertificatsPdf() {
    let params: ParamDictionary = { dictionary: {} };
    params.dictionary!["userName"] = this.user!.userName!.toString();
    params.dictionary!["startDate"] = "";
    params.dictionary!["endDate"] = "";
    this.$globalHelper.blobDownloadWithAuth("api/orgAdmin/loadUserCertificatesPdf", `${new Date().toISOString().substring(0, 10)}-${this.user.firstName} ${this.user.lastName}-Zertifikate.pdf`, params);
  }

  async createPdfDocument(certificate: UserCertificateVm): Promise<PdfMakeWrapper> {
    // Docu: https://pdfmakewrapper.org/docs/getting-started/essential-concepts/main-classes; https://github.com/Lugriz/pdfmake-wrapper
    //       https://pdfmakewrapper.org/docs/api-references/document-definition
    //       https://github.com/bpampuch/pdfmake; https://pdfmake.github.io/docs/
    // vue to pdf: https://github.com/mizuka-wu/vue-pdfmake

    // Set the fonts to use
    PdfMakeWrapper.setFonts(pdfFonts);

    // Define document
    const pdf = new PdfMakeWrapper();
    pdf.pageSize('A4'); // A4 paper is 595.35 x 841.995 pt. Since A4 is 210 x 297 mm => 1 mm = 2.835 pt
    // pdf.pageMargins([ 40, 60, 40, 60 ]);
    pdf.info({
      title: 'L3RN-online Zertifikat',
      subject: this.certificateTitle(certificate) ?? "",
      author: 'L3RN-online',
    });
    let def = pdf.getDefinition();
    // console.log("doc definitions:", def);

    // Set Background image
    const backgroundImage: IImg = await new Img('Zertifikat.png').fit([595.35, 841.995]).build();
    pdf.background(backgroundImage);

    // User name
    // const text = new Txt([
    //   new Txt(`${certificate.firstName} ${certificate.lastName}\n\n`).bold().fontSize(24).color("#4089be").end,
    //   new Txt("hat die Schulung\n").fontSize(18).color("#494949").end,
    //   new Txt(`${{this.certificateTitle(certificate)}\n`).bold().fontSize(24).color("#4089be").end,
    //   new Txt(`am ${moment(certificate.closingDate).format("DD.MM.yyyy")}\ erfolgreich absolviert.`).fontSize(18).color("#494949").end,
    // ]).alignment("center").lineHeight(1.5).absolutePosition(180,350).end;
    // pdf.add(text);

    let textArray = [
      new Txt(`${certificate.firstName} ${certificate.lastName}\n\n`).bold().fontSize(24).color("#4089be").end,
      new Txt("hat die Schulung\n").fontSize(18).color("#494949").end,
      new Txt(`${this.certificateTitle(certificate)}\n`).bold().fontSize(24).color("#4089be").end,
      new Txt(`am ${moment(certificate.closingDate).format("DD.MM.yyyy")}\ erfolgreich absolviert.`).fontSize(18).color("#494949").end,
    ];

    if (certificate.comment && certificate.comment.length > 0)
      textArray.push(new Txt(`\n\n${certificate.comment}`).fontSize(12).color("#494949").end);

    let text = new Txt(textArray).alignment("center").lineHeight(1.5).absolutePosition(180,350).end;
    pdf.add(text);

    if (certificate.signature && certificate.signature.length > 0) {
      const signature = (await new Img(certificate.signature).width(180).absolutePosition(180, 650).build()); //alignment("center").absolutePosition(180, 500);
      pdf.add(signature);
      let name = new Txt(`${certificate.firstName} ${certificate.lastName}`).fontSize(14).color("#494949").absolutePosition(210, 710).end;
      pdf.add(name);
    }

    // L3RN online
    // const l3rnOnline = new Txt([
    //   new Txt("L").color("#494949").end,
    //   new Txt("3").bold().color('#0C8AAD').end,
    //   new Txt("RN online").color("#494949").end,
    // ]).absolutePosition(480,785).end;
    // pdf.add(l3rnOnline);

    return pdf;
  }

  get userName() {
    if (!this.user) return "-";
    return this.user.fullName;
  }

  /**
   * Helper
   */

  formatDate(date) {
    if (date == null)
      return "-";

    return moment(date).format("DD.MM.yyyy");
  }
}
