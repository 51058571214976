

















































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExSubject } from "@/interfaces/ExSubject";
// import { ExBundleVM } from '@/interfaces/ExBundleVM';
import { ParamBool } from '@/interfaces/ParamBool';
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';
import ExBundleStatus from "../components/ExerciseComponents/ExBundleStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
// import { GroupVm } from "@/interfaces/GroupVm";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory3 } from "@/interfaces/ExCategory3";
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";
import { vuex } from "@/store";

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExBundleStatus
  }
})
export default class MyExercisesView extends Vue {
  // @globals.Getter selectedGroup!: GroupVm;
  // @globals.Action SelectGroup: any;
  // @globals.Getter selectedUser!: PortalUserVM;
  // @globals.Getter selectedSubject!: ExSubject;
  // @globals.Getter selectedCategory2!: ExCategory2;
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isLearner: any;

  // users: PortalUserVM[] | null = null;
  // selectedUserId: string | null = null;
  // groups: GroupExtVm[] | null = null;
  // selectedGroupId: number = 0;
  bundleAssignments: ExBundleAssignmentVM[] | null = [];
  subjectParam: ParamBool = { value: true };
  paramDir: ParamDictionary = { dictionary: {}};
  category1Items: ExCategory1[] = [];
  selectedCat1Item: ExCategory1 | null = null;
  category2Items: ExCategory2[] = [];
  selectedCat2Item: ExCategory2 | null = null;
  category3Items: ExCategory3[] = [];
  selectedCat3Item: ExCategory3 | null = null;

  resultsOverview: ResultOverviewVM | null = null;
  selectAll: boolean = true;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'MeineSchulungen', disabled: true, href: '/myExercises' },
 ];

  async mounted() {
    vuex.globals.setUser(vuex.auth.portalUser);
    await this.getResults();
    await this.loadCategories("cat1");
    this.getExBundles();
  }

  async cat1Changed(cat1: ExCategory1) {
    // console.log("grade changed, get exercises")
    this.selectedCat1Item = cat1;
    await this.loadCategories("cat1");
    this.getExBundles();
  }

  async cat2Changed(cat2: ExCategory2) {
    // console.log("grade changed, get exercises")
    this.selectedCat2Item = cat2;
    await this.loadCategories("cat2");
    this.getExBundles();
  }

  async cat3Changed(cat3: ExCategory3) {
    // console.log("grade changed, get exercises")
    this.selectedCat3Item = cat3;
    await this.loadCategories("cat3");
    this.getExBundles();
  }

  async loadCategories(changeEvent: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["UserId"] = "";
    this.paramDir.dictionary["OrgId"] = "0";
    this.paramDir.dictionary["GroupId"] = "0";
    this.paramDir.dictionary["Category1Id"] = this.selectedCat1Item?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCat2Item?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = this.selectedCat3Item?.id.toString() ?? "0";

    await rest.url("exercises/loadUserCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      this.selectedCat1Item = result.selectedCat1Item
      this.category2Items = result.category2Items!;
      this.selectedCat2Item = result.selectedCat2Item
      this.category3Items = result.category3Items!;
      this.selectedCat3Item = result.selectedCat3Item
    })
  }

  get selectedCat1ItemName() {
    if (!this.selectedCat1Item)
      return "-";
    return this.selectedCat1Item.name;
  }

  get selectedCat2ItemName() {
    if (!this.selectedCat2Item)
      return "-";
    return this.selectedCat2Item.name;
  }

  get selectedCat3ItemName() {
    if (!this.selectedCat3Item)
      return "-";
    return this.selectedCat3Item.name;
  }

  async getResults() {
    this.resultsOverview = await rest.url("exercises/getResultsOverview").get();
    // console.log(this.resultsOverview);
  }

  async getExBundles() {
    this.bundleAssignments = []; // Workaround to make sure that placeholder image shows up if bundle logo is not available;
    let param: ParamGetExercises = {
      orgId: 0, // not relevant
      groupId: 0, // not relevant
      subGroupId: 0, // not relevant
      userId: null, // not relevant
      category1Id: this.selectedCat1Item?.id ?? 0,
      category2Id: this.selectedCat2Item?.id ?? 0,
      category3Id:  this.selectedCat3Item?.id ?? 0,
      bundleId: 0, // not relevant
      enabled: true,
      writeResults: true,
      showUserView: true
    };
    this.bundleAssignments = await rest.url("exercises/getAssignedExBundles").post(param);
  }

  showExerciseBundle(assignment: ExBundleAssignmentVM) {
    this.$store.commit('globals/setExBundleAssignment', assignment);
    router.push("/exerciseBundle");
    // if (this.selectedUserId) {
    // } else {
    //   router.push("/groupExerciseOverview");
    // }
  }

  goBack() {
    router.go(-1);
  }
}
