var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","align":"center"}},[_c('v-card',{staticClass:"mt-4",attrs:{"max-width":"600px"}},[_c('v-toolbar',{attrs:{"height":"45px","dark":"","color":_vm.$store.state.ux.colorHeader1}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""}},[_vm._v("mdi-wrench-clock")]),_vm._v("Werkzeuge")],1)],1),_c('v-divider',{attrs:{"color":_vm.$store.state.ux.colorDivider1}}),_c('v-list',{style:({background: _vm.$store.state.ux.colorListBackground})},[_c('v-list-item',{on:{"click":function($event){_vm.showTemperatureDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-snowflake-thermometer")])],1),_c('v-list-item-content',[_vm._v("Kühlschranktemperatur")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"450px"},model:{value:(_vm.showTemperatureDialog),callback:function ($$v) {_vm.showTemperatureDialog=$$v},expression:"showTemperatureDialog"}},[_c('v-card',{staticClass:"dialogCard"},[_c('v-toolbar',{attrs:{"dark":"","color":_vm.$store.state.ux.colorHeader1,"text":""}},[_c('v-toolbar-title',{},[_c('v-icon',{staticClass:"mr-3",attrs:{"large":""}},[_vm._v("mdi-snowflake-thermometer")]),_vm._v(" Temperaturerfassung ")],1),_c('v-spacer')],1),_c('v-divider',{attrs:{"color":_vm.$store.state.ux.colorDivider1}}),_c('v-card-text',{staticClass:"modal"},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',{staticStyle:{"font-size":"18px"},attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.currentTime)+" ")]),_c('v-sheet',{staticClass:"my-2 pa-2 rounded-lg",attrs:{"color":"#80808030"}},[_vm._v(" Min. Temperatur: "),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-inline"},[_c('v-select',{staticStyle:{"width":"90px"},attrs:{"label":"Grad C","item-value":"text","items":[
                    { text: '15' },
                    { text: '14' },
                    { text: '13' },
                    { text: '12' },
                    { text: '11' },
                    { text: '10' },
                    { text: '9' },
                    { text: '8' },
                    { text: '7' },
                    { text: '6' },
                    { text: '5' },
                    { text: '4' },
                    { text: '3' },
                    { text: '2' },
                    { text: '1' },
                    { text: '0' },
                    { text: '- 0' },
                    { text: '- 1' },
                    { text: '- 2' },
                    { text: '- 3', },
                    { text: '- 4' } ]},model:{value:(_vm.minTemp1),callback:function ($$v) {_vm.minTemp1=$$v},expression:"minTemp1"}})],1),_c('span',{staticClass:"mx-3 mt-9",staticStyle:{"font-size":"30px"}},[_vm._v(",")]),_c('div',{staticClass:"d-inline"},[_c('v-select',{staticStyle:{"width":"90px"},attrs:{"item-value":"text","items":[
                    { text: '9', value: 9 },
                    { text: '8', value: 8 },
                    { text: '7', value: 7 },
                    { text: '6', value: 6 },
                    { text: '5', value: 5 },
                    { text: '4', value: 4 },
                    { text: '3', value: 3 },
                    { text: '2', value: 2 },
                    { text: '1', value: 1 },
                    { text: '0', value: 0 } ]},model:{value:(_vm.minTemp2),callback:function ($$v) {_vm.minTemp2=$$v},expression:"minTemp2"}})],1)])],1),_c('v-sheet',{staticClass:"my-2 pa-2 rounded-lg",attrs:{"color":"#80808030"}},[_vm._v(" Max. Temperatur: "),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-inline"},[_c('v-select',{staticStyle:{"width":"90px"},attrs:{"label":"Grad C","item-value":"text","items":[
                    { text: '15', value: 15 },
                    { text: '14', value: 14 },
                    { text: '13', value: 13 },
                    { text: '12', value: 12 },
                    { text: '11', value: 11 },
                    { text: '10', value: 10 },
                    { text: '9', value: 9 },
                    { text: '8', value: 8 },
                    { text: '7', value: 7 },
                    { text: '6', value: 6 },
                    { text: '5', value: 5 },
                    { text: '4', value: 4 },
                    { text: '3', value: 3 },
                    { text: '2', value: 2 },
                    { text: '1', value: 1 },
                    { text: '0', value: 0 },
                    { text: '-0', value: 0 },
                    { text: '-1', value: -1 },
                    { text: '-2', value: -2 },
                    { text: '- 3', value: -3 },
                    { text: '- 4', value: -4 } ]},model:{value:(_vm.maxTemp1),callback:function ($$v) {_vm.maxTemp1=$$v},expression:"maxTemp1"}})],1),_c('span',{staticClass:"mx-3 mt-9",staticStyle:{"font-size":"30px"}},[_vm._v(",")]),_c('div',{staticClass:"d-inline"},[_c('v-select',{staticStyle:{"width":"90px"},attrs:{"item-value":"text","items":[
                    { text: '9', value: 9 },
                    { text: '8', value: 8 },
                    { text: '7', value: 7 },
                    { text: '6', value: 6 },
                    { text: '5', value: 5 },
                    { text: '4', value: 4 },
                    { text: '3', value: 3 },
                    { text: '2', value: 2 },
                    { text: '1', value: 1 },
                    { text: '0', value: 0 } ]},model:{value:(_vm.maxTemp2),callback:function ($$v) {_vm.maxTemp2=$$v},expression:"maxTemp2"}})],1)])],1),(_vm.increaseTemp)?_c('v-sheet',{staticClass:"my-2 pa-2 rounded-lg",staticStyle:{"font-size":"18px"},attrs:{"color":_vm.$store.state.ux.colorWarning}},[_vm._v(" ACHTUNG: Temperatureinstellung anheben! ")]):_vm._e(),(_vm.decreaseTemp)?_c('v-sheet',{staticClass:"my-2 pa-2 rounded-lg",staticStyle:{"font-size":"18px"},attrs:{"color":_vm.$store.state.ux.colorWarning}},[_vm._v(" ACHTUNG: Temperatureinstellung absenken! ")]):_vm._e(),(_vm.increaseTemp || _vm.decreaseTemp)?_c('v-sheet',{staticClass:"my-2 pa-2 rounded-lg",staticStyle:{"font-size":"18px"},attrs:{"color":_vm.$store.state.ux.colorInfo}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","label":"Apotheker informieren"}}),_c('v-spacer'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-phone")])],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","label":"Technik informieren"}}),_c('v-spacer'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-phone")])],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","color":_vm.$store.state.ux.colorInfo},on:{"click":function($event){_vm.showTemperatureDialog = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("close")]),_vm._v("Abbrechen")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.$store.state.ux.colorSuccess,"outlined":""},on:{"click":function($event){_vm.showTemperatureDialog = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("save")]),_vm._v("Speichern")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }