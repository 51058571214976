





















































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";
import rest from "@/rest";
import { List } from "linq-collections";
// interfaces
import { CustomerMessageVm } from "@/interfaces/CustomerMessageVm";
// components
import Login from "@/components/Login.vue"; // @ is an alias to /src
import ContentFrameWithImage from "@/components/LandingPage/ContentFrameWithImage.vue";

const globals = namespace("globals");

@Component({
  components: {
    Login,
    ContentFrameWithImage
  },
})
export default class L3onlineLandingPage extends Vue {
  showLoginDialog: boolean = false;
  gdprAccepted = false;
  messageSending = false;

  menuItems = [
    {text: "Übersicht", value: "overview"},
    // {label: "Neuigkeiten", value: "news"},
    {text: "Preise", value: "prices"},
    {text: "Über uns", value: "aboutUs"},
    {text: "Kontakt", value: "contact"},
  ];
  selectedMenuItemValue = this.menuItems[0].value;
  selectedWindow = 0;

  // contact view
  kontaktFormValid = false;
  customerMsg = <CustomerMessageVm>{
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phoneNumber: "",
    message: ""
  }

  // $refs!: {};

  mounted() {}

  openLoginDialog() {
    // value is use as trigger => just invert it
    this.showLoginDialog = !this.showLoginDialog;
  }

  onMenuItemClicked(item) {
    this.selectedMenuItemValue = item.value;
  }

  @Watch("selectedMenuItemValue")
  onMenuItemChanged(value) {
    let selectedItem = new List(this.menuItems).single(i => i.value == value);
    let idxOfSelectedItem = this.menuItems.indexOf(selectedItem);
    this.selectedWindow = idxOfSelectedItem;
  }

  selectPrevTab() {
    this.selectedWindow = this.selectedWindow - 1 < 0 ? 0 : this.selectedWindow - 1;
  }

  selectNextTab() {
    this.selectedWindow = this.selectedWindow + 1 === this.menuItems.length ? this.menuItems.length - 1 : this.selectedWindow + 1;
  }

  @Watch("selectedWindow")
  onCarouselDotSelectionChange(value) {
    let selectedItem = this.menuItems[this.selectedWindow];
    this.selectedMenuItemValue = selectedItem.value;
  }

  getMenuButtonColor(item) {
    if (this.selectedMenuItemValue == item.value) return this.$store.state.ux.colorAccent3;
    return this.$store.state.ux.colorAccent5;
  }

  async onSendMessage() {
    this.messageSending = true;

    await rest.url("public/sendMessage")
      .post(this.customerMsg)
      .then((result) => {
        if (result !== true)
          return;

        vuex.ux.SB_SUCCESS({
          message: `Danke für Ihre Kontaktanfrage. Eine automatische Eingangsbestätigung wurde an Ihre Email Adresse ${this.customerMsg.email} versant.\r\nFalls Sie keine Nachricht erhalten haben, überprüfen Sie bitte auch Ihren Spam Email Ordner.`,
          timeout: 15000
        });
        this.customerMsg.firstName = "";
        this.customerMsg.lastName = "";
        this.customerMsg.company = "";
        this.customerMsg.email = "";
        this.customerMsg.phoneNumber = "";
        this.customerMsg.message = "";
        this.gdprAccepted = false;
        this.kontaktFormValid = true;
      })
      .finally(() => {
        this.messageSending = false
      });
  }

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  firstNameRules = [
    (v: string) => !!v || "Vorname erforderlich",
    (v: string) => (v && v.length > 1) || "Vornamename zu kurz."
  ];

  lastNameRules = [
    (v: string) => !!v || "Nachname erforderlich",
    (v: string) => (v && v.length > 1) || "Nachname zu kurz."
  ];

  messageRules = [
    (v: string) => !!v || "Nachricht erforderlich",
    (v: string) => (v && v.length > 9) || "Nachricht zu kurz."
  ];

}
