





































































































































































  import _ from "lodash";
  import rest from "@/rest";
  import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
  import { Action, namespace } from "vuex-class";
  import { vuex } from "@/store";

  import { PortalUserVM, SelectListItem, GroupVm, OrganizationVm } from "@/interfaces/PortalUserVM";
  import SetAvatar from "@/components/SetAvatar.vue";
  // import { VRowExpandTransition } from "vuetify/lib"; // ???
  // import de from 'vuetify/src/locale/de';
  import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
  import moment from "moment";
  import { GroupExtVm } from "@/interfaces/GroupExtVm";
  import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
  import { List } from "linq-collections";
  import { ParamAddUsersVm } from "@/interfaces/ParamAddUsersVm";
  import { UserTagVm } from "@/interfaces/UserTagVm";
  import { ParamsLoadOrgUsers } from "@/interfaces/ParamsLoadOrgUsers";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

  const auth = namespace("auth");
  const globals = namespace("globals");

  @Component({
    components: {
      SetAvatar
    }
  })
  export default class AddUsers extends Vue {
    @PropSync("showDialog") syncedShowDialog!: boolean;
    // @Prop() user!: PortalUserVM;
    @Prop() bundleId!: number; // Used for adding approvers to bundle
    @Prop() saveUserUrl!: string;

    @globals.Getter selectedGroup!: GroupExtVm;
    @globals.Getter selectedOrg!: OrganizationVm;
    @auth.Getter isAdmin: any;
    @auth.Getter isOrgAdmin: any;
    @auth.Getter isGroupAdmin: any;
    @auth.Getter canEditDynGroups: any;

    init = true;
    windowInnerHeight = 0;
    busy = false;

    organizations: OrganizationVm[] = [];
    localSelectedOrg: OrganizationVm | null = null;

    userList: PortalUserVM[] | null = null;
    searchValue: string | null = null;
    isLoading = false;
    // isAllOrgsLoading = false;
    // allOrgs = false;
    userTags: UserTagVm[] = [];
    selectedTags: UserTagVm[] = [];
    tagSearch: string | null = null;

    $refs!: {
      tagSelectBox: HTMLElement
    };

    mounted() {
      this.setWindowHeight();
    }

    created() {
      window.addEventListener("resize", this.setWindowHeight);
    }

    beforeDestroy() {
      window.removeEventListener("resize", this.setWindowHeight);
      clearAllBodyScrollLocks();
    }

    setWindowHeight() {
      this.windowInnerHeight = window.innerHeight;
    }

    @Watch("syncedShowDialog")
    async onShowDialogChanged(open: boolean) {
      if (open) {
        this.busy = false;
        this.isLoading = false;
        // this.allOrgs = false;
        this.searchValue = null;
        this.userList = [];
        this.loadUserTags();
        await this.updateOrgList();
        await this.loadUserList();
        this.init = false;

        // Disable body scroll on iOS
        this.$nextTick(async () => {
          await this.$globalHelper.delay(10);
          const modal = document.querySelector('.modal');
          disableBodyScroll(modal);
        });
      } else {
        // Dialog closing
        // this.editUser = new PortalUserVmClass();
        this.init = true;
        clearAllBodyScrollLocks();
      }
    }

    @Watch("searchValue")
    onSearchValueChanged(val: string | null) {
      if (this.init) return;
      // console.log("onSearch: ", this.searchValue);

      // if (this.isLoading) return
      // this.isLoading = true
      // await this.loadUserList();
      // this.isLoading = false;
      this.debounce();
    }
    debounce = _.debounce(this.loadUserList, 700, { leading: true, trailing: true});

    async updateOrgList() {
      let params = <ParamDictionary>{dictionary: {}};
      params.dictionary!["LoadAllOrgs"] = "true";
      params.dictionary!["AddAllOrgsSelection"] = "true";
      this.organizations = await rest.url("userManagement/loadOrganizations").post(params);

      if (this.organizations.length > 0) {
        if (this.selectedOrg) {
          let org = new List(this.organizations).singleOrDefault(s => s.id == this.selectedOrg.id);
          if (org) {
            this.localSelectedOrg = org;
            return;
          }
        }
        vuex.globals.setOrg(this.organizations[0]);
      } else {
        vuex.globals.setOrg(null);
      }
      this.localSelectedOrg = this.selectedOrg;
    }

    async onOrgChanged() {
      vuex.globals.setOrg(this.localSelectedOrg);
      await this.loadUserList();
    }

    async loadUserList() {
      this.isLoading = true
      let param = <ParamsLoadOrgUsers>{ groupId: this.selectedGroup?.id, orgId: this.selectedOrg?.id ?? 0, allOrgs: false, bundleId: this.bundleId, searchValue: this.searchValue ?? "", userTags: this.selectedTags }
      await rest.url("userManagement/loadOrgUsers")
        // .query({ groupId: this.selectedGroup?.id ?? 0, orgId: this.selectedOrg?.id ?? 0, allOrgs: this.allOrgs, searchValue: this.searchValue ?? "" })
        .post(param)
        .then((response) => {
          // Select users again
          if (this.userList) {
            let selectedUserIds = new List(this.userList).where(u => u.selected).select(u => u.id);
            new List(response as PortalUserVM[]).where(u => selectedUserIds.contains(u.id)).forEach(u => u.selected = true);
          }

          this.userList = response;
          if (this.userList && this.userList?.length == 100) {
            let dummy = new PortalUserVmClass();
            dummy.disabled = true;
            dummy.fullName = "Für weitere Ergebnisse Suchfeld nutzen";
            this.userList.push(dummy);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });;
    }

    async loadUserTags() {
      await rest.url("userManagement/loadUserTags")
        .get()
        .then((response) => {
          this.userTags = response;
        })
    }

    // async onAllOrgsChange() {
    //   this.isAllOrgsLoading = true;
    //   await this.loadUserList();
    //   this.isAllOrgsLoading = false;
    // }

    onItemSelected(user) {
      user.selected = !user.selected;
    }

    onSelectAll() {
      if (!this.userList) return;
      new List(this.userList).where(u => !u.disabled).forEach(u => u.selected = true);
    }

    onDeselectAll() {
      if (!this.userList) return;
      new List(this.userList).forEach(u => u.selected = false);
    }

    async addUsers() {
      if (this.busy) return;
      if (!this.userList) return;

      let selectedUsers = new List(this.userList).where(u => !u.disabled && u.selected).toArray();

      if (!this.saveUserUrl) {
        this.$emit('usersUpdated:AddUsers', selectedUsers);
        this.closeDialog();
        return;
      }

      this.busy = true;
      let userIds = new List(selectedUsers).select(u => u.id).toArray();
      let params = <ParamAddUsersVm>{ groupId: this.selectedGroup?.id ?? 0, orgId: this.selectedOrg?.id ?? 0, bundleId: this.bundleId, userIds: userIds };

      await rest.url(this.saveUserUrl)
        .post(params)
        .then((response) => {
          this.$emit('usersUpdated:AddUsers', true);
          this.closeDialog();
        })
        .finally(() => {
          this.busy = false;
        });
    }

    closeDialog() {
      this.syncedShowDialog = false;
    }

    get listHeight() {
      if (this.$vuetify.breakpoint.mdAndUp)
        return this.windowInnerHeight * 0.9 - 235;
      else if (this.$vuetify.breakpoint.sm)
        return this.windowInnerHeight * 0.9 - 275;
      else if (this.$vuetify.breakpoint.xs)
        return this.windowInnerHeight - 365; // no *0.9 because dialog is fullscreen in xs view
      else
        return this.windowInnerHeight * 0.9 - 190;
    }

    // async onNewUserSelected() {
    //   // console.log("onNewUserSelected");
    //   if (!this.isNewUser) return;
    //   this.editUser = null;
    //   await this.$globalHelper.delay(10);
    //   this.editUser = this.newUser!;
    //   // if (this.editUser.birthday!.getTime() != this.defaultUser.birthday!.getTime()) {
    //   if (this.editUser.birthday!.getTime() != new PortalUserVmClass().birthday!.getTime()) {
    //     this.birthdayAsIso = moment(this.editUser.birthday).format("yyyy-MM-DD");
    //     this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
    //   } else {
    //     this.birthdayAsIso = null;
    //     this.birthdayAsText = null;
    //   }
    // }

    // onSearchUserSelected() {
    //   if (!this.isNewUser) return;
    //   this.editUser = null;
    // }

    // async onUserSelected() {
    //   if (!this.isNewUser) return;
    //   if (!this.editUser)  return;

    //   this.editUser.groupId = this.selectedGroup?.id ?? 0;
    //   this.editUser.organizationId = this.selectedOrg?.id ?? 0;
    //   if (this.editUser.birthday) {
    //     this.birthdayAsIso = moment(this.editUser.birthday).format("yyyy-MM-DD");
    //     this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
    //   } else {
    //     // this.birthdayAsIso = null;
    //     this.birthdayAsText = null;
    //   }

    //   await this.$globalHelper.delay(10);
    //   // this.$refs.editUserForm.validate();
    // }

    // addNewUser() {
    //   this.foundUser = null;
    //   this.editUser = new PortalUserVmClass();
    //   this.editUser.groupId = this.selectedGroup.id;
    //   this.isNewUser = true;
    // }

    // createNewKey() {
    //   if (!this.editUser) return;
    //   if (this.birthdayAsIso) {
    //     this.editUser.activationKey = moment(this.birthdayAsIso).format("DDMMyyyy");
    //     vuex.ux.SB_SUCCESS({
    //       message: "Geburtsdatum als Aktivierungsschlüssel gesetzt.",
    //       timeout: 3000
    //     });
    //     return;
    //   }

    //   this.editUser.activationKey = "";
    //   for (let i = 0; i <= 7; i++) {
    //     this.editUser.activationKey += Math.floor(Math.random() * 10);
    //   }
    // }

    // onBirthdayTextChanged(): void {
    //   if (!this.editUser) return;
    //   if (!this.birthdayAsText) return;
    //   if (!this.isValidDate(this.birthdayAsText)) {
    //     this.birthdayAsText = null;
    //     this.birthdayAsIso = null;
    //     return;
    //   }

    //   let isoDate = this.convertDeDateToIso(this.birthdayAsText)
    //   // Check if valid date
    //   if (!moment(isoDate).isValid()) {
    //     this.birthdayAsText = null;
    //     this.birthdayAsIso = null;
    //     return;
    //   }
    //   this.birthdayAsIso = isoDate;
    //   if (this.isNewUser)
    //     this.editUser.activationKey = moment(this.birthdayAsIso).format("DDMMyyyy");
    // }

    // onBirthdayPicker() {
    //   if (!this.editUser) return;
    //   if (!this.birthdayAsIso) {
    //     this.birthdayAsText = null;
    //     this.showBirthdayDlg = false;
    //     return;
    //   }

    //   this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
    //   this.showBirthdayDlg = false;
    //   if (this.isNewUser)
    //     this.editUser.activationKey = moment(this.birthdayAsIso).format("DDMMyyyy");
    // }

    // isValidDate(val: string | null): boolean {
    //   if (!val)
    //     return true;
    //   let dateSplit = val.split('.');
    //   if (dateSplit?.length != 3)
    //     return false;
    //   if (dateSplit[2].length < 4)
    //     return false;
    //   if (dateSplit[1].length < 1 || dateSplit[1].length > 2)
    //     return false;
    //   if (dateSplit[0].length < 1 || dateSplit[0].length > 2)
    //     return false;

    //   return moment(this.convertDeDateToIso(val)).isValid();
    // }

    // convertDeDateToIso(val: string): string {
    //   let dateSplit = this.birthdayAsText?.split('.');
    //   let isoDate = dateSplit![2] + "-";
    //   if (dateSplit![1].length < 2)
    //     isoDate += "0";
    //   isoDate += dateSplit![1] + "-";
    //   if (dateSplit![0].length < 2)
    //     isoDate += "0";
    //   isoDate += dateSplit![0];
    //   return isoDate;
    // }

    // get birthdayFormated() {
    //   return this.formatDate(this.birthdayAsIso);
    // }

    // formatDate(date) {
    //   if (date == null)
    //     return "-";

    //   return moment(date).format("DD.MM.yyyy");
    // }

    // onAvatarChanged(avatar: string) {
    //   if (!this.editUser) return;
    //   this.editUser.avatar = avatar;
    // }

    // get formIsReadOnly() {
    //   if (this.isNewUser && this.editMode == "searchUser")
    //     return true;
    //   return false;
    // }

    // get toolBarHeight() {
    //   if (this.isNewUser) return 120;
    //   return 65;
    // }

    // get dialogTitle() {
    //   if (!this.isNewUser)
    //     return  "Anwender bearbeiten";

    //   if (this.editMode == "newUser") {
    //     if (this.$vuetify.breakpoint.xs)
    //       return "Anwender anlegen";

    //     return "Neuen Anwender anlegen";
    //   }
    //   if (this.$vuetify.breakpoint.xs)
    //     return "Anwender hinzufügen";

    //   return "Bestehenden Anwender hinzufügen";
    // }

    // async saveUser() {
    //   if (!this.editUser) return;
    //   if (!this.$refs.editUserForm.validate()) return;
    //   if (this.busy) return;

    //   this.busy = true;
    //   this.editUser.userName = this.editUser.userName?.trim() ?? null;
    //   this.editUser.firstName = this.editUser.firstName?.trim() ?? null;
    //   this.editUser.lastName = this.editUser.lastName?.trim() ?? null;
    //   this.editUser.nickname = this.editUser.nickname?.trim() ?? null;
    //   this.editUser.email = this.editUser.email?.trim() ?? null;
    //   this.editUser.corporateId = this.editUser.corporateId?.trim() ?? null;
    //   this.editUser.birthday = this.birthdayAsIso ? new Date(this.birthdayAsIso) : null;
    //   // this.editUser.birthday = this.birthdayAsIso ? moment.utc(this.birthdayAsIso).toDate() : null;

    //   await rest.url(this.saveUserUrl)
    //     .post(this.editUser)
    //     .then((response) => {
    //       this.$emit('userSaved:EditUser', true);
    //       this.closeDialog();
    //     })
    //     .finally(() => {
    //       this.busy = false;
    //     });
    // }

    // async copyToClipboard(value: string) {
    //   await navigator.clipboard.writeText(value);
    //   this.$store.commit("ux/SB_SUCCESS", {
    //     message: value + " in Zwischenablage kopiert",
    //     timeout: 2000
    //   });
    // }

    // dateRules = [
    //   (v: string) => this.isValidDate(v) || "falsches Datumsformat"
    // ];

    // mailRules = [
    //   (v: string) => !!v || "Email is required",
    //   (v: string) =>
    //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Keine gültige E-Mail-Adresse"
    // ];

    // usernameRules = [
    //   (v: string) => !!v || "Benutzername erforderlich",
    //   (v: string) => (v && v.length >= 3) || "Benutzername zu kurz."
    // ];

    // keyRules = [
    //   //(v: string) => !!v || "Schlüssel erforderlich",
    //   (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    //   (v: string) => (v && v.length > 7) || "Schlüssel zu kurz"
    // ];

    // keyEditRules = [
    //   (v: string) => !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz"
    // ];

    // nameRules = [
    //   (v: string) => !!v || "Name erforderlich",
    //   (v: string) => (v && v.length > 1) || "Name zu kurz"
    // ];

    // phoneNumberRules = [
    //   (v: string) => (!v || (v && v.length <= 50)) || "Telefonnummer zu lang"
    // ];

    // departmentRules = [
    //   (v: string) => (!v || (v && v.length <= 100)) || "Abteilung zu lang"
    // ];

    // locationRules = [
    //   (v: string) => (!v || (v && v.length <= 100)) || "Standort zu lang"
    // ];

    // corporateIdRules = [
    //   (v: string) => (!v || (v && v.length <= 50)) || "Personalnummer zu lang"
    // ];

    // passwordRules = [
    //   (v: string) => !!v || "Passwort erforderlich",
    //   (v: string) => (v && v.length > 7) || "Passwort ist zu kurz"
    // ];
  }
