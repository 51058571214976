








import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { vuex } from "@/store";
import rest from "@/rest";
import { ICellRendererParams } from "ag-grid-community";
import UserTable from "@/components/UserManagement/UserTable.vue";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
import { PortalUserVM } from "@/interfaces/PortalUserVM";

@Component({
  components: {
  }
})
export default class UserMngActionCellRenderer extends Vue {
  params!: ICellRendererParams;

  // $refs!: {
  // };

  mounted() {
    // if (this.params.node.rowPinned == "top")
    //   this.showCell = false;
  }


  onUserDetails() {
    (this.params.context as UserTable).onUserDetails(this.params.data as PortalUserVM);
  }

  onEditUser() {
    (this.params.context as UserTable).onEditUser(this.params.data as PortalUserVM);
  }

  onDeleteUser() {
    (this.params.context as UserTable).onDeleteUser(this.params.data as PortalUserVM);
  }

  // Hide action buttons on empty rows
  // get showActionButtons() {
  //   if (!this.isAdmin) return false;

  //   let rowData = this.params?.data as PortalUserVM;
  //   if (!rowData) return false;
  //   return true;
  // }

  // get isAdmin() {
  //   return vuex.auth.isAdmin;
  // }

  // get userName() {
  //   // if (!this.params)
  //   //   return "-";
  //   let user = this.params?.data as PortalUserVM;
  //   if (!user) return "-";
  //   return user.fullName;
  // }

  // onCellRightClick(e) {
  //   let eventData = { event: e, params: this.params };
  //   console.log(eventData);

  //   this.$emit("onRightClick:aggridCell", eventData);
  // }

  /**
   * Helper
   */
}
