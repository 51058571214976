






import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { Line as LineChartGenerator } from "vue-chartjs"
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, TimeScale, LinearScale, PointElement } from "chart.js"
import { ChartData, ChartDataset } from "chart.js";
import { Options as DataLabelOptions } from "chartjs-plugin-datalabels/types/options";
import { ChartJsDataPoint, QuantityVm } from "@/interfaces/SurveyDetailedVm";
import { List } from "linq-collections";
import moment from "moment";

@Component({
  components: {
    LineChartGenerator
  }
})
export default class SurveyTimeChart extends Vue {
  @Prop() data!: ChartJsDataPoint[];
  @Prop() timeUnit!: string;
  // chartData = {
  //   labels: [ "January", "February", "March" ],
  //   datasets: [ { data: [40, 20, 12] } ]
  // };

  mounted() {
  }


  get chartData() {
    if (!this.data || this.data.length == 0)
      return null;

    // const labels = new List(this.data).select(d => d.x).toArray();

    // barColor = "#4089be50"
    let barColor = this.$store.state.ux.colorAccent; // + "80"; // Add transparency
    // let barColors: string[] = [];
    // labels.forEach(element => {
    //   barColors.push(barColor);
    // });

    return <ChartData>{
      // labels: labels,
      datasets: [
        <ChartDataset>{
          // label: "Beteiligung",
          data: this.data as any as PointElement[],
          borderColor: barColor,
          fill: false,
          tension: 0.4,
          datalabels: <DataLabelOptions>{
            // rotation: -90,
            // align: "top"
            display: false
          }
        }
      ]
    }
  }

  chartOptions = {
    type: "line",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // legend: {
      //   position: "bottom",
      //   align: "start"
      // },
      legend: {display: false},
      tooltip: {
        yAlign: "bottom",
        displayColors: false,
        titleColor: this.$store.state.ux.colorAccent,
        bodyColor: this.$store.state.ux.colorAccent,
        backgroundColor: "#ffffffd0",
        borderColor: this.$store.state.ux.colorAccent + "80",
        borderWidth: 2,
        cornerRadius: 10,
        caretSize: 0,
        // titleFont: "",
        // bodyFont: ""
      }
    },
    // scales: {
    //   xAxes: [{
    //     type: "time",
    //     distribution: "linear",
    //   }],
    // }
    scales: {
      x: {
        type: "time",
        distribution: "linear",
        time: {
          tooltipFormat: "DD.MM.YYYY",
          unit: this.timeUnit
        },
        // title: {
        //   display: true,
        //   text: "Date"
        // }
      },
      y: {
        min: 0,
      }
    }
  }
}
