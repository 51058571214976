






import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ICellRendererParams } from "ag-grid-community";

@Component
export default class SwitchCellRenderer extends Vue {
  params!: ICellRendererParams;
  checked: boolean = false;
  // $refs!: {
  // };

  mounted() {
    // console.log("switch mount params", this.params);

    if (!this.params || this.params.value == undefined) return;
    // console.log("switch mount", this.params.value);
    this.checked = this.params.value;
  }

  onChanged() {
    if (!this.params?.column) return;

    let colId = this.params.column.getColId();
    // console.log("Switch handler colId", colId);
    // console.log("Switch handler checked", this.checked);
    this.params.node.setDataValue(colId, this.checked);
  }
  /**
   * Helper
   */

  // delay(ms: number) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
}
