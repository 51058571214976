



































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import router from "@/router";
import rest from "@/rest";
import Gdpr from "@/views/Gdpr.vue";
import { PortalUserVM } from "@/interfaces/PortalUserVM";

@Component({
  components: {
    Gdpr
  }
})
export default class GdprApproval extends Vue {
  agreeToGdpr = false;
  showGdprDlg = false;

  async onAgree() {
    let result = await rest.url("auth/agreeToGdpr").post();
      if (result == true) {
      this.$store.commit("ux/SB_SUCCESS", {
        message: "Zustimmung gespeichert",
        timeout: 3000
      });
    }
    // Update user
    let portalUser: PortalUserVM = await rest.url("Auth/getPortalUser").get();
    if (portalUser != null)
      this.$store.commit("auth/setPortalUser", portalUser);
    // goto home
    router.push("/home");
  }

  goBack() {
      router.go(-1);
    }
  }
