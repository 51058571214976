const firebaseConfig = {
  apiKey: "AIzaSyAGaoRG6wd__N8aMz6rKnWQ0zx3J-ANxZ8",
  authDomain: "l3rn-online-prod.firebaseapp.com",
  projectId: "l3rn-online-prod",
  storageBucket: "l3rn-online-prod.appspot.com",
  messagingSenderId: "515681052021",
  appId: "1:515681052021:web:ceb1325d6d3953e9fd5f3c"
};

export default class FirebaseCfg {
  // constructor() { }

  firebaseConfig = firebaseConfig;
  vapidKey = "BPc5ZMJbX5SwNnRMQ595C16wt9SLKohb5xXHxMWpvuetc0P0AUxE2xE1qRg1dg-6SxWyNJf0ZDxZN7_Vh5RuxY8";
}