














































































































































import _ from "lodash";
// import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
  components: {}
})
export default class ExStatus extends Vue {
  @Prop() result!: number;
  @Prop() type!: string;
  @Prop() level!: number;
  @Prop() maxLevel!: number;
  @Prop() trophyCount!: number;
  @Prop() maxTrophyCount!: number;
  @Prop() snooze!: boolean;
  @Prop() finished!: boolean;
  @Prop() enabled!: boolean;
  @Prop() hasSignature!: boolean;
  @Prop() isSigned!: boolean;
  // @Prop() isGroupAdmin!: boolean;

  // get value() {
  //     // Do rounding
  //     if (this.result < 99)
  //         return (this.result + 1) * 3 / 100;
  //     else
  //         return this.result * 3 / 100;
  // }
  // $refs!: {};

  mounted() {}

  get testFinished() {
    // if (this.maxLevel == 0)
    //   return true;
    if (this.trophyCount == this.maxTrophyCount)
      return true;

    return false;
  }

  get exerciseTrophyCount() {
    if (this.type == "Test") {
      return this.level;
    } else if (this.type == "ManualApproval") {
      return this.finished ? 1 : 0;
    }

    return 0;
  }

  get exerciseMaxTrophyCount() {
    if (this.type == "Test") {
      return this.maxLevel;
    } else if (this.type == "ManualApproval") {
      return 1;
    }

    return 0;
  }
}
