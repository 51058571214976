




























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { SurveyResultVm } from "@/interfaces/SurveyDetailedVm";

@Component({
  components: {
  }
})
export default class SurveyScoreResult extends Vue {
  @Prop() result!: SurveyResultVm;

  get average() {
    return this.result.scoreAverage.toLocaleString(undefined, { maximumFractionDigits: 2});
  }
}
