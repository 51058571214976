






import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { ChartData, ChartDataset } from 'chart.js';
import { Options as DataLabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { QuantityVm } from "@/interfaces/SurveyDetailedVm";
import { List } from "linq-collections";
import { forEach } from "lodash";

@Component({
  components: {
    Bar
  }
})
export default class SurveyQuantityChart extends Vue {
  @Prop() data!: QuantityVm[];
  // chartData = {
  //   labels: [ 'January', 'February', 'March' ],
  //   datasets: [ { data: [40, 20, 12] } ]
  // };
  get chartData() {
    if (!this.data || this.data.length == 0)
      return null;

    const labels = new List(this.data).select(x => x.name).toArray();
    const counts = new List(this.data).select(x => x.count).toArray();

    // barColor = "#4089be50"
    let barColor = this.$store.state.ux.colorAccent + "80"; // Add transparency
    // let barColors: string[] = [];
    // labels.forEach(element => {
    //   barColors.push(barColor);
    // });

    return <ChartData>{
      labels: labels,
      datasets: [
        <ChartDataset>{
          label: "Anzahl",
          backgroundColor: barColor,
          data: counts,
          type: 'bar',
          datalabels: <DataLabelOptions>{
            rotation: -90,
            align: 'top'
          }
        }
      ]
    }
  }

  chartOptions = {
    type: 'bar',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // legend: {
      //   position: 'bottom',
      //   align: 'start'
      // },
      legend: {display: false},
      tooltip: {
        yAlign: 'bottom',
        displayColors: false,
        titleColor: this.$store.state.ux.colorAccent,
        bodyColor: this.$store.state.ux.colorAccent,
        backgroundColor: "#ffffffd0",
        borderColor: this.$store.state.ux.colorAccent + "80",
        borderWidth: 2,
        cornerRadius: 10,
        caretSize: 0,
        // titleFont: 'Fujitsu Sans 2020',
        // bodyFont: 'Fujitsu Sans 2020'
      }
    },
    scales: {
      x: {
          ticks: {
            callback: function(val, index) {
              const label = (this as any).getLabelForValue(val);
              const words = label.split(" ");
              let lableArray = <string[]>[];
              let sentence = "";
              for (let word of words) {
                if (lableArray.length == 2) {
                  sentence += word;
                  break;
                }

                if (sentence.length > 0)
                  sentence += " ";

                sentence += word.trim();

                if (sentence.length >= 15) {
                  lableArray.push(sentence);
                  sentence = "";
                }
              }

              if (sentence.length > 0) {
                if (lableArray.length < 2)
                  lableArray.push(sentence);
                else
                  lableArray[1] += "...";
              }

              return lableArray;
            }
          }
        }
    }
  }
}
