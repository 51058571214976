














import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import rest from "@/rest";
import de from 'vuetify/src/locale/de';
import { ParamDictionary } from "@/interfaces/ParamDictionary";

@Component({})
export default class UploadArchiveMenuButton extends Vue {
  $refs!: {
    uploadArchive: HTMLElement;
  };

  // @Prop() label!: string;
  @Prop() url!: string;
  // @Prop() localPath!: string;
  fileParam: ParamDictionary = { dictionary: {}};
  dragover: boolean = false;
  dropzone!: Element;
  fileupload!: HTMLElement;

  mounted () {
  }

  uploadExArchiveFile() {
    this.$refs.uploadArchive.click();
  }

  onFileSelected(e: any) {
    // let excel = e.target.files || e.dataTransfer.files
    // if (!excel.length)
    //     return;
    // let file = excel[0];
    // this.uploadFile(file);
    const target = (e.target as HTMLInputElement);
    if(target.files) {
        this.uploadFile(target.files[0]);
    }
  }

  uploadFile(file) {
    if (this.fileParam.dictionary == null)
      return;

    // this.fileParam.dictionary["LocalPath"] = this.localPath;
    this.fileParam.dictionary["FileName"] = file.name;

    let reader = new FileReader();
    reader.onload = async e => {
      if (this.fileParam.dictionary == null)
        return;

      this.fileParam.dictionary["Data"] = (e as any).target.result;
      await rest.url(this.url).post(this.fileParam);
      this.$emit('uploaded:UploadArchive');
    };

    reader.readAsDataURL(file);
  }
}
