import { CustomizationVm } from "@/interfaces/CustomizationVm";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { action, createModule, mutation } from "vuex-class-component";
import rest from "@/rest";

const VuexModule = createModule({
  strict: false,
  namespaced: "customization",
});

export class Customization extends VuexModule {
  _settings: CustomizationVm | null = null;

  // getters
  get customizationSettings(): CustomizationVm | null {
    return this._settings;
  }

  @action async loadCustomizationSettings() {
    // TODO: Set default values on startup to make sure the app shows up right also if there are connection problems

    let settings: CustomizationVm = await rest.url("auth/getCustomizationSettings").get();
    if (settings != null)
      this.setCustomizationSettings(settings);

    console.log("CustomizationSettings loaded");
  }

  @action async saveCustomizationSettings(settings: CustomizationVm) {
    let values: CustomizationVm = await rest.url("admin/saveCustomizationSettings").post(settings);
    this.setCustomizationSettings(values);
  }

  // mutations
  @mutation setCustomizationSettings(settings: CustomizationVm | null) {
    this._settings = settings;
  }
};