






































// import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { quizPlayerEventBus } from '@/main';
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import moment from "moment";


const globals = namespace("globals");

// https://www.npmjs.com/package/vue-signature-pad
// https://github.com/szimek/signature_pad#demo
@Component({
    components: {}
})
export default class CompSignature extends Vue {
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;

  @Prop() text!: string;
  @Prop() textArray!: string[];
  @Prop() size!: string; // e.g. 24, 24px
  @Prop() color!: string; // e.g. #00A1EF
  @Prop() weight!: string; // normal, bold, bolder, lighter, 100, 200 ... 900
  @Prop() fontStyle!: string; // normal, italic, oblique
  @Prop() finalized!: boolean;
  @Prop() compSettings!: string;

  // Props for stand alone usage
  // @Prop({ default: true })  showSaveButton!: boolean;

  settings: any = null;
  isEmpty = true;

  $refs!: {
    signaturePad: any;
  };

  mounted() {
    if (this.compSettings) {
      this.settings = JSON.parse(this.compSettings);
    }
  }

  onBegin() {
  }

  onEnd() {
    this.isEmpty = this.$refs.signaturePad.isEmpty();
    this.$emit("isSigned:CompSignature", !this.isEmpty);
  }

  onReset() {
    this.$refs.signaturePad.clearSignature();
    this.isEmpty = this.$refs.signaturePad.isEmpty();
    this.$emit("isSigned:CompSignature", !this.isEmpty);
  }

  onUndo() {
    this.$refs.signaturePad.undoSignature();
    this.isEmpty = this.$refs.signaturePad.isEmpty();
    this.$emit("isSigned:CompSignature", !this.isEmpty);
  }

  // getSignature(): string {
  //   const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
  //   return data;
  // }

  // onSave() {
  //   const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
  //   console.log(isEmpty);
  //   console.log(data);
  // }
  onSave() {
    if (!this.settings || this.finalized)
      return;

    const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
    if (isEmpty)
      return;

    quizPlayerEventBus.$emit("changed:signature", { questionId: this.settings.questionId, signature: data });
    this.$emit("onSave:CompSignature", data);
  }

  get finishedByAdmin() {
    if (!this.selectedExBundleAssignment?.finishedByAdmin)
      return false;
    return this.selectedExBundleAssignment.finishedByAdmin;
  }

  get finishedByAdminName() {
    if (!this.selectedExBundleAssignment?.finishedByAdminName)
      return "";
    return this.selectedExBundleAssignment.finishedByAdminName;
  }

  get finishedDate() {
    if (!this.selectedExBundleAssignment?.finishedByAdminDate)
      return "";

    // let date = moment(this.selectedExBundleAssignment.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(this.selectedExBundleAssignment.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return " | " + date;
  }

  // get canvasWidth() {
  //   let width = 300;
  //   switch (this.$vuetify.breakpoint.name) {
  //     case 'xs':
  //       width = (this.$vuetify.breakpoint.width / 12) * 10;
  //       break;
  //     case 'sm':
  //       width = (this.$vuetify.breakpoint.width / 12) * 9;
  //       break;
  //     case 'md':
  //       width = (this.$vuetify.breakpoint.width / 12) * 6;
  //       break;
  //     case 'lg':
  //       width = (this.$vuetify.breakpoint.width / 12) * 5;
  //       break;
  //     case 'xl':
  //     default:
  //       width = (this.$vuetify.breakpoint.width / 12) * 4;
  //       break;
  //   }

  //   return width + "px";
  // }

  get showComment() {
    if (!this.settings || !this.settings.notShowComment)
      return true;

    return !this.settings.notShowComment;
  }

  get fontSize() {
    if (this.size == null)
      return "18px";
    if (isNaN(Number(this.size))) // Number(this.size) != NaN)
    return this.size;
    return this.size + "px";
  }

  get hyperText() {
    if (this.text != null)
      return this.text;
    if (this.textArray != null)
      return this.textArray.join('');

    return null;
  }

  get backgroundColor() {
    if (this.$vuetify.theme.dark) {
      return "#ffffff30";
    }

    return "#ffffff80";
  }
}
