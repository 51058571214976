





































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import AssignmentMenu from '../components/ExerciseAssignment/AssignmentMenu.vue';
import EditSubGroup from "@/components/Groups/EditSubGroup.vue";

// Interfaces
import { ExDataSet, ExChoice, ExQuestion } from '../interfaces/ExDataSet';
import { ExerciseVM } from '../interfaces/ExerciseVM';
// import { ExBundleVM, ExSubject } from '@/interfaces/ExBundleVM';
import de from 'vuetify/src/locale/de';
import { ParamBool } from "@/interfaces/ParamBool";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExBundleAssignmentGroupVM, ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentGroupVM";
import { OrganizationVm, PortalUserVM, SubGroupVm } from "@/interfaces/PortalUserVM";
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory3 } from "@/interfaces/ExCategory3";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";
import { vuex } from "@/store";
import { List } from "linq-collections";

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
    AssignmentMenu,
    EditSubGroup
  }
})
export default class ExerciseAssignment extends Vue {
  @globals.Getter selectedOrg!: OrganizationVm;
  @globals.Getter selectedGroup!: GroupExtVm;
  // get selectedGroup() {
  //   return vuex.globals.selectedGroup
  // }
  @globals.Getter selectedSubGroup!: SubGroupVm;
  @globals.Getter selectedUser!: PortalUserVM;
  @globals.Getter selectedCategory1!: ExCategory1 | null;
  @globals.Getter selectedCategory2!: ExCategory2 | null;
  @globals.Getter selectedCategory3!: ExCategory3 | null;
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;

  showResetResultsConfDialog = false;
  showDeleteAssignmentDialog = false;
  showEditSubGroupDialog = false;
  orgs: OrganizationVm[] = [];
  localSelectedOrg: OrganizationVm | null = null;
  selectedAssignment:ExBundleAssignmentVM | null = null;
  groups: GroupExtVm[] | null = null;
  selectedGroupId: number = 0;
  selectedSubGroupId: number = 0;
  paramDir: ParamDictionary = { dictionary: {}};
  category1Items: ExCategory1[] = [];
  category2Items: ExCategory2[] = [];
  category3Items: ExCategory3[] = [];
  exBundleGroups: ExBundleAssignmentGroupVM[] = [];
  bundleAssignments: ExBundleAssignmentVM[] | null = [];
  defaultSubGroup: SubGroupVm = {
    id: 0,
    parentGroupId: 0,
    parentGroupName: "",
    name: "",
    allowModify: true,
    userCount: 0,
    users: [] as  PortalUserVM[],
    lastChangedBy: "",
    lastChangeDate: new Date()
  }
  newSubGroup = this.defaultSubGroup;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Schulungsauswahl', disabled: true, href: '/exerciseAssignment' },
  ];

  async mounted() {
    vuex.globals.setUser(null);
    await this.updateOrgList();
    await this.getGroups();
    await this.loadCategories("cat1", "");
    this.getAssignmentsForAllExBundles();

    // if (this.selectedGroup) {
    //   // console.log("selectedGroup: ", this.selectedGroup);
    //   this.getAssignmentsForAllExBundles();
    // } else {
    //   // console.log("selectedGroup: none");
    //   this.selectedGroupId = 0;
    //   this.getAssignmentsForAllExBundles();
    // }
  }

  updateView() {
    this.getAssignmentsForAllExBundles();
  }

  async updateOrgList() {
    // this.orgs = await rest.url("groupAdmin/getGroupAdminOrganizations").get();
    let params = <ParamDictionary>{dictionary: {}};
    params.dictionary!["LoadAllOrgs"] = "false";
    params.dictionary!["AddAllOrgsSelection"] = "false";
    this.orgs = await rest.url("userManagement/loadOrganizations").post(params);

    this.orgs = new List(this.orgs).where(s => s.isOrgGroupAdmin).toArray();
    if (this.orgs.length > 0) {
      if (this.selectedOrg) {
        if (new List(this.orgs).any(s => s.id == this.selectedOrg.id)) {
          this.localSelectedOrg = this.selectedOrg
          return;
        }
      }
      vuex.globals.setOrg(this.orgs[0]);
    } else {
      vuex.globals.setOrg(null);
    }
    this.localSelectedOrg = this.selectedOrg;
  }

  async onOrgChanged() {
    vuex.globals.setOrg(this.localSelectedOrg);
    await this.getGroups();
    this.getAssignmentsForAllExBundles();
  }

  async getGroups() {
    if (this.selectedOrg == null) return;
    this.selectedGroupId = 0;
    this.selectedSubGroupId = 0;
    this.groups = await rest
      .query({ orgId: this.selectedOrg.id, asGroupAdmin: true })
      .url("groupAdmin/getMyGroups").get();
    if (this.groups == undefined || this.groups.length == 0) {
      vuex.globals.setGroup(null);
      vuex.globals.setSubGroup(null);
      // console.log("no group selected");
      return;
    }

    // Add "Ganze Gruppe" to all subgroup lists
    let groupList = new List(this.groups);
    groupList.forEach(g => {
      if (g.subGroups) {
        new List(g.subGroups).insert(0, <SubGroupVm>{
          id: 0,
          parentGroupId: g.id,
          parentGroupName: "",
          name: "Ganze Gruppe",
          lastChangedBy: "",
          lastChangeDate: new Date(),
          allowModify: false,
          userCount: 0,
          users: []
        });
      }
    })

    // Try to select last selected group
    if (this.groups.length > 0 && this.selectedGroup != null) {
      let lastSelectedGroup = this.groups.find(g => g.id == this.selectedGroup.id) ?? null;
      if (lastSelectedGroup) {
        this.selectedGroupId = lastSelectedGroup.id;
        vuex.globals.setGroup(lastSelectedGroup);
        // console.log("last group selected");
      }
    }

    // Select first group
    if (this.selectedGroupId == 0 && this.groups.length > 0) {
      this.selectedGroupId = this.groups[0].id;
      vuex.globals.setGroup(this.groups[0]);
      // console.log("first group selected");
      // console.log("selectedGroup: ", this.selectedGroup);
    }

    // Try to select last selected sub group
    if (this.selectedGroup?.subGroups && this.selectedSubGroup) {
      let lastSelectedSubGroup = this.selectedGroup.subGroups.find(s => s.id == this.selectedSubGroup.id);
      if (lastSelectedSubGroup) {
        this.selectedSubGroupId = lastSelectedSubGroup.id;
        vuex.globals.setSubGroup(lastSelectedSubGroup);
        // console.log("last sub group selected");
      }
    }

    // Select first sub group
    if (this.selectedSubGroupId == 0) {
      this.selectFirstSubGroup();
    }
  }

  selectFirstSubGroup(): void {
    if (this.selectedGroup?.subGroups && this.selectedGroup.subGroups.length > 0) {
      this.selectedSubGroupId = this.selectedGroup.subGroups[0].id;
      vuex.globals.setSubGroup(this.selectedGroup.subGroups[0]);
      // console.log("first sub group selected");
      // console.log("selectedSubGroup: ", this.selectedSubGroup);
      return;
    }
    this.selectedSubGroupId = 0;
    vuex.globals.setSubGroup(null);
  }

  async onGroupChanged() {
    // console.log("onGroupChanged: ", this.selectedGroupId);
    let selectedGroup = this.groups!.find(g => g.id == this.selectedGroupId) ?? null;
    vuex.globals.setGroup(selectedGroup);
    this.selectFirstSubGroup();
    this.getAssignmentsForAllExBundles();
  }

  async onSubGroupChanged() {
    // console.log("onSubGroupChanged: ", this.selectedSubGroupId);
    if (!this.selectedGroup?.subGroups) return;

    let selectedSubGroup = this.selectedGroup.subGroups.find(s => s.id == this.selectedSubGroupId);
    if (selectedSubGroup)
      vuex.globals.setSubGroup(selectedSubGroup);

    this.getAssignmentsForAllExBundles();
  }

  async onAddSubGroup() {
    // await this.loadGroupWithSubGroups();
    this.newSubGroup = _.cloneDeep(this.defaultSubGroup);
    this.showEditSubGroupDialog = true;
  }

  async onSubGroupUpdated(subGroupVm: SubGroupVm) {
    // console.log("subGroupVm: ", subGroupVm);
    await vuex.globals.setSubGroup(subGroupVm);
    await this.getGroups();
    this.getAssignmentsForAllExBundles();
  }

  async cat1Changed(cat1: ExCategory1) {
    // console.log("grade changed, get exercises")
    vuex.globals.setCategory1(cat1);
    await this.loadCategories("cat1", "");
    this.getAssignmentsForAllExBundles();
  }

  async cat2Changed(cat2: ExCategory2) {
    // console.log("grade changed, get exercises")
    vuex.globals.setCategory2(cat2);
    await this.loadCategories("cat2", "");
    this.getAssignmentsForAllExBundles();
  }

  async loadCategories(changeEvent: string, selectGuid: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["SelectGuid"] = selectGuid;
    // this.paramDir.dictionary["UserId"] = this.selectedUser.id!;
    // this.paramDir.dictionary["GroupId"] = this.selectedGroup.id.toString()!;
    this.paramDir.dictionary["Category1Id"] = this.selectedCategory1?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCategory2?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = this.selectedCategory3?.id.toString() ?? "0";

    await rest.url("exercises/loadAllCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      vuex.globals.setCategory1(result.selectedCat1Item);

      this.category2Items = result.category2Items!;
      vuex.globals.setCategory2(result.selectedCat2Item);

      this.category3Items = result.category3Items!;
      vuex.globals.setCategory3(result.selectedCat3Item);
    })
  }

  get selectedCat1ItemName() {
    if (!this.selectedCategory1)
      return "-";
    return this.selectedCategory1.name;
  }

  get selectedCat2ItemName() {
    if (!this.selectedCategory2)
      return "-";
    return this.selectedCategory2.name;
  }

  async getAssignmentsForAllExBundles() {
    // let userId: string | null = null;
    // if (this.selectedUser != null)
    //     userId = this.selectedUser.id;

    let param: ParamGetExercises = {
      orgId: 0,
      groupId: this.selectedGroupId,
      subGroupId: this.selectedSubGroupId,
      userId: null,
      category1Id: this.selectedCategory1?.id ?? 0,
      category2Id: this.selectedCategory2?.id ?? 0,
      category3Id: this.selectedCategory3?.id ?? 0,
      bundleId: 0,
      enabled: true,
      writeResults: false,
      showUserView: false
    };
    this.exBundleGroups = await rest.url("groupAdmin/getAssignmentsForAllExBundles").post(param);
  }

  showExerciseBundle(assignment: ExBundleAssignmentVM) {
    this.$store.commit('globals/setExBundleAssignment', assignment);
    router.push("/exerciseBundle");
  }

  openResetResultsConfDialog(assignment: ExBundleAssignmentVM) {
    this.selectedAssignment = assignment;
    this.showResetResultsConfDialog = true;
  }

  async resetResults() {
    await rest.url("groupAdmin/resetResults").post(this.selectedAssignment);
    this.showResetResultsConfDialog = false;
  }

  openDeleteAssignmentDialog(assignment: ExBundleAssignmentVM) {
    this.selectedAssignment = assignment;
    this.showDeleteAssignmentDialog = true;
  }

  async deleteAssignments() {
    await rest.url("admin/deleteAllMemberAssignments").post(this.selectedAssignment);
    this.getAssignmentsForAllExBundles();
    this.showDeleteAssignmentDialog = false;
  }

  goBack() {
    router.go(-1);
  }
}
