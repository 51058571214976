import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import rest from "@/rest";
import { RootStore } from "../types";
import Store, { vuex } from "@/store";
import { action, createModule, mutation } from "vuex-class-component";

const VuexModule = createModule({
  strict: false,
  namespaced: "approvalChat",
});

export class ApprovalChat extends VuexModule {
  // state
  newChatCount = 0;

  get newApprovalChatCount() {
    return this.newChatCount;
  }

  @action async updateNewApprovalChatCount() {
    if (!vuex.auth.isLoggedIn)
        return;

    let count = await rest
      .url("bundleApproval/getApprovalNewChatCount")
      .get();
    this.setNewApprovalChatCount(count);
  }

  @mutation setNewApprovalChatCount(value: number) {
    this.newChatCount = value;
  }

}