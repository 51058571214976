import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/src/locale/de";
import colors from 'vuetify/lib/util/colors';

import { createSimpleTransition } from "vuetify/lib/components/transitions/createTransition";

const abTransition = createSimpleTransition("ab-transition");
Vue.component("ab-transition", abTransition);

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: true,
    options: {
      // customProperties: true,
      variations: false,
    },
    themes: {
      dark: {
        // primary: '#1976D2',
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
        // primary: "#37BBA9",
        primary: "#00B4A1", // "#285372", // "#1976D2",
        secondary: "#999999",
        accent: "#006064",
        anchor: "#0086FF",
        error: "#F06543",
        info: "#246EB9",
        success: "#11b59f",
        warning: "#f57c00",
        appBackground: colors.grey.darken2, // Not automatically applied
        background: "#414142", // Not automatically applied
      },
      light: {
        primary: "#036065", // "#285372", //#0657D1", // "#1976D2",
        secondary: "#4D4D4D", //'#424242',
        accent: "#006064", // '#82B1FF',
        anchor: "#005BFF",
        error: "#F06543",
        info: "#42A5F5",
        success: "#11b59f", // "#66BB6A",
        warning: "#f57c00",
        appBackground: colors.grey.lighten1, // Not automatically applied
        background: "#FFFFFF", // Not automatically applied
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
