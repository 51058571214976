















































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { List, Enumerable } from 'linq-collections';
import { OrganizationVm, PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';
import { ExTagAchievementVM } from '@/interfaces/ExTagAchievementVM';
import { CategorySelectListsVm } from '@/interfaces/CategorySelectListsVm';

// Components
// import IOSClientVersions from '../components/PerformanceOverview/iOSClientVersions';
import { KeyValue } from '@/interfaces/KeyValue';
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory3 } from "@/interfaces/UnsubscribedTopic";


const globals = namespace("globals");

@Component({
  components: {
    // IOSClientVersions
    // 'qrcode': VueQrcode
  }
})
export default class UserPerformanceOverview extends Vue {
  @globals.Getter selectedOrg!: OrganizationVm;
  @globals.Getter selectedGroup!: GroupExtVm;
  @globals.Getter selectedUser!: PortalUserVM;

  sortAscending: boolean = true;
  showFilterMenu:boolean= false;
  category1Items: ExCategory1[] = [];
  selectedCat1Item: ExCategory1 | null = null;
  category2Items: ExCategory2[] = [];
  selectedCat2Item: ExCategory2 | null = null;
  category3Items: ExCategory3[] = [];
  selectedCat3Item: ExCategory3 | null = null;
  paramDir: ParamDictionary = { dictionary: {}};
  resultsOverview: ResultOverviewVM | null = null;
  tagAchievements: ExTagAchievementVM[] = [];

  // chartData = {};
  // iOsChartData = {};

  breadcrumbItems = [
      { text: 'Start', disabled: false, href: '/home' },
      { text: 'Gruppen', disabled: false, href: '/exercises' },
      { text: 'Leistungsstand', disabled: true, href: '/userPerformanceOverview' },
  ];

  timeFrames: KeyValue[] = [
    { key: 'alles', value: 'all' },
    { key: '1 Woche', value: 'oneWeek' },
    { key: '1 Monat', value: 'oneMonth' },
    { key: '6 Monate', value: 'sixMonth' },
    { key: '1 Jahr', value: 'oneYear' }
  ];
  selectedTimeFrame: KeyValue  = this.timeFrames[2];

  async mounted() {
    if (this.selectedGroup == null || this.selectedUser == null) {
      router.push("/");
      return;
    }

    // await this.getSubjects();
    await this.loadCategories("cat1");
    await this.getUserResults();
    await this.getTagAchievement();
    // this.getAssignmentsForAllExBundles();

    // this.chartData = {
    // labels: ['v4.08', 'v.4.0.10'],
    //   datasets: [
    //     {
    //       label: 'Data One',
    //       borderColor: '#f87979',
    //       //   backgroundColor: "#f87979",
    //       data: [40, 20]
    //     }
    //   ]
    // };

    // this.iOsChartData = {
    //   labels: ['v4.08', 'v.4.0.10'],
    //   datasets: [
    //     {
    //       borderColor: '#f0f0f050',
    //       //   backgroundColor: "#f87979",
    //       data: [40, 20],
    //       backgroundColor: ['#37BBA9', '#68E2D0']
    //     }
    //   ]
    // };
  }

  trainingColor(achivement: ExTagAchievementVM) {
    if (achivement.notWellTrained)
      return "grey";

    return this.$store.state.ux.colorAccent;
  }

  get starColor() {
    if (this.$vuetify.theme.dark)
      return "yellow";

    return "yellow accent-4";
  }

  async getUserResults() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    // this.paramDir = <ParamDictionary>{ dictionary: {}};
    this.paramDir.dictionary["PupilId"] = this.selectedUser.id!;
    this.paramDir.dictionary["GroupId"] = this.selectedGroup.id.toString()!;

    this.resultsOverview = await rest.url("groupAdmin/userPerformanceOverview").post(this.paramDir);
    // console.log(this.resultsOverview);
  }

  async getTagAchievement() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["sortAscending"] = this.sortAscending.toString()!;
    this.paramDir.dictionary["PupilId"] = this.selectedUser.id!;
    this.paramDir.dictionary["GroupId"] = this.selectedGroup.id.toString()!;
    this.paramDir.dictionary["Category1Id"] = (this.selectedCat1Item?.id ?? 0).toString();
    this.paramDir.dictionary["Category2Id"] = (this.selectedCat2Item?.id ?? 0).toString();
    this.paramDir.dictionary["Category3Id"] = (this.selectedCat3Item?.id ?? 0).toString();
    this.paramDir.dictionary["timeFrame"] = this.selectedTimeFrame.value!;

    this.tagAchievements = await rest.url("groupAdmin/loadTagAchievementList").post(this.paramDir);
    // console.log(this.resultsOverview);
  }

  // async getSubjects() {
  //   this.subjects = await rest.url("exercises/getSubjects").query({ selectAll: true }, true).get();
  //   if (this.selectedSubject != null && this.selectedSubject.id != 0) {
  //     let subject = this.subjects.find(s => s.id == this.selectedSubject.id);
  //     this.$store.commit("globals/setSubject", subject);
  //   } else {
  //     this.$store.commit("globals/setSubject", this.subjects[0]);
  //   }
  //   this.localSubject = this.selectedSubject;
  // }

  // async changeSubject() {
  //     // console.log("subject changed" + this.localSubject)
  //     this.$store.commit("globals/setSubject", this.localSubject);
  //     await this.getCategories();
  //     this.getTagAchievement();
  // }

  async loadCategories(changeEvent: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["UserId"] = this.selectedUser?.id ?? "";
    this.paramDir.dictionary["OrgId"] = this.selectedOrg?.id.toString() ?? "0";
    this.paramDir.dictionary["GroupId"] = this.selectedGroup?.id.toString() ?? "0";
    this.paramDir.dictionary["Category1Id"] = this.selectedCat1Item?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCat2Item?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = this.selectedCat3Item?.id.toString() ?? "0";

    await rest.url("exercises/loadUserCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      this.selectedCat1Item = result.selectedCat1Item
      this.category2Items = result.category2Items!;
      this.selectedCat2Item = result.selectedCat2Item
      this.category3Items = result.category3Items!;
      this.selectedCat3Item = result.selectedCat3Item
    });
  }

  async changeCategory() {
      this.getTagAchievement();
  }

  get showActiveFilter() {
    if (this.selectedCat1Item && this.selectedCat1Item.id > 0)
      return true;
    if (this.selectedCat2Item && this.selectedCat2Item.id > 0)
       return true;
    if (this.selectedCat3Item && this.selectedCat3Item.id > 0)
       return true;
    if (this.selectedTimeFrame && this.selectedTimeFrame.value != "all")
       return true;
   return false;
  }

  goBack() {
    router.go(-1);
  }
}
