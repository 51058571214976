





















































































































































































































































































































































































import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import moment from "moment";
import { List } from "linq-collections";
// Components
import EditEvent from "@/components/Home/EditEvent.vue"
// Interfaces
import { TopicVm } from "@/interfaces/TopicVm";
import { CalendarEventVm } from "@/interfaces/CalendarEventVm";

//import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

const auth = namespace("auth");
const ux = namespace("ux");
const globals = namespace("globals");
const notify = namespace("notify");

@Component({
  components: {
    EditEvent
  },
})

export default class Home extends Vue {
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isOrgAdmin: any;

  @notify.Action loadTopicNotifications: any;
  @notify.Action topicNotificationsNoted: any;
  @notify.Action updateTopics: any;
  @notify.Getter hasNewTopicNotifications: any;
  @notify.Getter newTopicNotificationCount: any;
  @notify.Getter isPushSupportedByBrowser: any;

  // $refs!: {
  //   calendar: HTMLElement;
  // };

  // calendar
  weekday = [1, 2, 3, 4, 5, 6, 0];
  events: any[] = [];
  colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];
  names = ['Meeting', 'Urlaub', 'Geburtstag', 'Geschäftsreise', 'Veranstaltung', 'Konferenz'];
  typeToLabel = { 'month': 'Monat', 'week': 'Woche', 'day': 'Tag', '4day': '4 Tage', };
  type = "month"
  focus: string = "";

  busy = false;
  showDlgSubscribe = false;
  showDlgEvent = false;
  topics: TopicVm[] = [];
  selectedTopics: string[] = [];
  selectedEvent: CalendarEventVm | null = null;
  selectedElement = null;
  selectedOpen = false;
  currentTime: string = new Date().toLocaleTimeString();
  // currentDate: string = new Date().toISOString().substr(0, 10);
  myTimer: number = 0;

  async mounted() {
    await this.loadTopicNotifications();
    await this.loadTopics();
    // By using lambda syntax for interval function <this> will have the same scope as parent class Home
    // So we can use this.currentTime in interval function
    // Start timer in mount function and not in constructor to make binding work.
    this.myTimer = setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.myTimer);
  }

  async loadTopics() {
    // this.unsubscribedTopics = await rest.url('notification/getUnsubscribedTopics').get();
    this.topics = await rest.url('notification/getTopics').get();
    this.topics?.forEach(t => {
      // t.selected = true;
      // if( this.unsubscribedTopics.some(u => u.id === t.id)) {
      //   t.selected = false;
      // }
      if (t.selected)
        this.selectedTopics.push(t.value!);
    });
  }

  get ListItemImgHeight() {
    if (this.$vuetify.breakpoint.xs)
      return 80;
    return 150;
  }

  get ListItemTextWidth() {
    let width = `calc(100% - ${this.ListItemImgHeight}px)`;
    // console.log("width: ", width);
    return width;
  }

  get HasNewTopicNotifications() {
    return this.hasNewTopicNotifications;
  }

  get NewTopicNotificationCount() {
    const count = this.newTopicNotificationCount;
    if (count > 99) {
      return '!';
    }
    return count;
  }

  get notifications() {
    if (!this.$store.state.notify.topicNotifications || this.selectedTopics.length == 0)
      return [];

    if(this.selectedTopics.length > 0) {
      const list = this.$store.state.notify.topicNotifications.filter(n =>
          n.topics.some(p => this.selectedTopics.some(s => s === p)))
      return list;
    }

    return this.$store.state.notify.topicNotifications;
  }

  get NotificationIcon() {
    if (this.isPushSupportedByBrowser)
      return "mdi-bell-outline";
    return "mdi-bell-sleep"
  }

  async subscribeTopics() {
    this.busy = true;
    await this.updateTopics(this.topics)
    .then(() => {
      this.showDlgSubscribe = false;
    })
    .finally(() => {
      this.busy = false;
    });
    await this.loadTopicNotifications();
  }

  getDescription(topic: TopicVm) : string {
    let idx = topic.descriptions!.findIndex(d => d.item1 === "de");
    if(idx < 0) {
      idx = topic.descriptions!.findIndex(d => d.item1 === 'en');
    }
    if(idx < 0) {
      idx = 0;
    }
    return topic.descriptions![idx].item2;
  }

  setToday() {
    this.focus = "";
  }

  async updateEvents() {
    let start = (this.$refs.calendar as any).lastStart;
    let end = (this.$refs.calendar as any).lastEnd;
    await this.getEvents({ start: start, end: end });
  }

  async getEvents ({ start, end }) {
    // const events: any[] = []

    // const min = new Date(`${start.date}T00:00:00`)
    // const max = new Date(`${end.date}T23:59:59`)
    // const days = (max.getTime() - min.getTime()) / 86400000
    // const eventCount = this.rnd(days, days + 20)

    // for (let i = 0; i < eventCount; i++) {
    //   const allDay = this.rnd(0, 3) === 0
    //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //   const second = new Date(first.getTime() + secondTimestamp)

    //   events.push({
    //     name: this.names[this.rnd(0, this.names.length - 1)],
    //     start: first,
    //     end: second,
    //     color: this.colors[this.rnd(0, this.colors.length - 1)],
    //     timed: !allDay,
    //   });
    // }
    // this.events = events;

    let results = await rest.url('groupAdmin/loadCalendarEvents')
      .query({ start: start.date, end: end.date })
      .get();

    results.forEach(e => {
      // e.start = moment(e.start).format("yyyy-MM-DDTHH:mm");
      // e.end = moment(e.end).format("yyyy-MM-DDTHH:mm");
      e.start = moment(e.start).toDate();
      e.end = moment(e.end).toDate();
    })
    this.events = results;
    this.selectedEvent = new List(this.events).singleOrDefault(e => e.id == this.selectedEvent?.id);
  };

  rnd (a, b) {
    return Math.floor((b - a + 1) * Math.random()) + a
  };

  startTime (tms) {
    const mouse = this.toTime(tms)

    // if (this.dragEvent && this.dragTime === null) {
    //   const start = this.dragEvent.start

    //   this.dragTime = mouse - start
    // } else {
    // }
    let createStart = this.roundTime(mouse)
    let createEvent = {
      name: `Event #${this.events.length}`,
      color: "primary",
      start: createStart,
      end: createStart,
      timed: true,
    }

    this.events.push(createEvent);
  }

  addCalendarEvent() {
    this.selectedEvent = null;
    this.showDlgEvent = true;
  }

  showEvent ({ nativeEvent, event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
    }

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  onEditEvent() {
    this.showDlgEvent = true;
  }

  viewDay ({ date }) {
    this.focus = date;
    this.type = 'day';
  }

  toTime (tms) {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
  }

  roundTime (time, down = true) {
    const roundTo = 15; // minutes
    const roundDownTime = roundTo * 60 * 1000;

    return down
      ? time - time % roundDownTime
      : time + (roundDownTime - (time % roundDownTime));
  }

  getEventColor (event) {
    return event.color
  };

  get isStartEqualEndDate() {
    console.log("isStartEqualEndDate");
    if (!this.selectedEvent)
      return false;

    return this.selectedEvent.start.toLocaleDateString() == this.selectedEvent.end.toLocaleDateString();
  }

  get dlgBackgroundColor() {
      return this.$vuetify.theme.dark ? this.$store.state.ux.colorAccent6 : "white";
  }
}
