

























import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { ExSurveyVm } from "@/interfaces/ExSurveyVm";


const globals = namespace("globals");

@Component({
    components: {}
})
export default class ExSurveyStatus extends Vue {
    @Prop() survey!: ExSurveyVm;
    @Prop() isGroupAdmin!: boolean;

    // $refs!: {};

    mounted() {}

    get progress() {
      if (this.survey.totalAssignmentsCount == 0)
        return 0;
      return (this.survey.finishedAssignmentsCount * 100 / this.survey.totalAssignmentsCount).toFixed(0);
    }
}
