


















































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
// components
import Login from "@/components/Login.vue"; // @ is an alias to /src
import ContentFrameWithImage from "@/components/LandingPage/ContentFrameWithImage.vue";

const globals = namespace("globals");

@Component({
  components: {
    Login,
    ContentFrameWithImage
  },
})
export default class L3schreinerLandingPage extends Vue {
  showLoginDialog: boolean = false;

  // $refs!: {};

  mounted() {}

  openLoginDialog() {
    // value is use as trigger => just invert it
    this.showLoginDialog = !this.showLoginDialog;
  }

}
