import Vue from "vue";
import Router from "vue-router";
import { VueInstance } from "./main";
import Store, { vuex } from "./store";
import LandingPage from "./views/LandingPage.vue";
import Freebies from "./views/Freebies.vue";
import Account from "./views/Account.vue";
import GdprApproval from "./views/GdprApproval.vue";
import Gdpr from "./views/Gdpr.vue";
import Imprint from "./views/Imprint.vue";
import TermsOfUse from "./views/TermsOfUse.vue";
import Home from "./views/Home.vue";
import Admin from "./views/Admin.vue";
import UserManagementView from "./views/UserManagementView.vue";
import ManageTopicNotifications from "./views/ManageTopicNotifications.vue";
import OrganizationView from "./views/OrganizationView.vue";
import GroupsView from "./views/GroupsView.vue";
import UsersView from "./views/UsersView.vue";
import ExercisesView from "./views/ExercisesView.vue";
import SurveysView from "./views/SurveysView.vue";
import SurveyEvaluationView from "./views/SurveyEvaluationView.vue";
import MyExercisesView from "./views/MyExercisesView.vue";
import MyCertificatesView from "./views/MyCertificatesView.vue";
import GroupExerciseOverview from "./views/GroupExerciseOverview.vue";
import ExerciseBundleView from "./views/ExerciseBundleView.vue";
// import ExerciseCatalog from "./views/ExerciseCatalog.vue";
import Exercise from "./views/Exercise.vue";
import ContentManager from "./views/ContentManager.vue";
import ScormManager from "./views/ScormManager.vue";
import BundleApprovalView from "./views/BundleApprovalView.vue";
import ExerciseAssignment from "./views/ExerciseAssignment.vue";
import UserPerformanceOverview from "./views/UserPerformanceOverview.vue";
import GroupPerformanceOverview from "./views/GroupPerformanceOverview.vue";
// import About from "./views/About.vue";
import ContentCreationHelp from "./views/ContentCreationHelp.vue";
import ToolsView from "./views/ToolsView.vue";
import { delay } from "lodash";
import CustomerSettingsView from "./views/CustomerSettingsView.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "landingPage",
      component: LandingPage,
    },
    {
      path: "/freebies",
      name: "freebies",
      component: Freebies,
    },
    {
      path: "/account",
      name: "account",
      component: Account,
    },
    {
      path: "/gdprApproval",
      name: "gdprApproval",
      component: GdprApproval,
    },
    {
      path: "/gdpr",
      name: "gdpr",
      component: Gdpr,
    },
    {
      path: "/imprint",
      name: "imprint",
      component: Imprint,
    },
    {
      path: "/termsOfUse",
      name: "termsOfUse",
      component: TermsOfUse,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"] ||
          VueInstance.$store.getters["auth/isNotificationAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isLearner"]
          // VueInstance.$store.getters["auth/isParent"] ||
          // VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"]) return next();
        router.push("/");
      },
    },
    {
      path: "/customerSettings",
      name: "customerSettings",
      component: CustomerSettingsView,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"]) return next();
        router.push("/");
      },
    },
    {
      path: "/userManagement",
      name: "userManagement",
      component: UserManagementView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"]
        ) return next();
        router.push("/");
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: ManageTopicNotifications,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isNotificationAdmin"]
        ) return next();
        router.push("/");
      },
    },
    {
      path: "/organizations",
      name: "organizations",
      component: OrganizationView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/groups",
      name: "groups",
      component: GroupsView,
      beforeEnter: (to, from, next) => {
        // console.log("isAdmin: ", vuex.auth.isAdmin);
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        // console.log("/groups not authorized");
        router.push("/");
      },
    },
    {
      path: "/users",
      name: "users",
      component: UsersView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exercises",
      name: "exercises",
      component: ExercisesView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isGroupViewer"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/surveys",
      name: "surveys",
      component: SurveysView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/surveyEvaluation",
      name: "surveyEvaluation",
      component: SurveyEvaluationView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/myExercises",
      name: "myExercises",
      component: MyExercisesView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isLearner"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/myCertificates",
      name: "myCertificates",
      component: MyCertificatesView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isLearner"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/GroupExerciseOverview",
      name: "groupExerciseOverview",
      component: GroupExerciseOverview,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isGroupViewer"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exerciseBundle",
      name: "exerciseBundle",
      component: ExerciseBundleView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isGroupViewer"] ||
          VueInstance.$store.getters["auth/isLearner"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/bundleApproval",
      name: "bundleApproval",
      component: BundleApprovalView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"] ||
          VueInstance.$store.getters["auth/isLearner"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exerciseAssignment",
      name: "exerciseAssignment",
      component: ExerciseAssignment,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exercise",
      name: "exercise",
      component: Exercise,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isLearner"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/userPerformanceOverview",
      name: "userPerformanceOverview",
      component: UserPerformanceOverview,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/groupPerformanceOverview",
      name: "groupPerformanceOverview",
      component: GroupPerformanceOverview,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/contentManager",
      name: "contentManager",
      component: ContentManager,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"]
        ) return next();
        router.push("/");
      },
    },
    {
      path: "/contentCreationHelp",
      name: "contentCreationHelp",
      component: ContentCreationHelp,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"]
        ) return next();
        router.push("/");
      },
    },
    {
      path: "/scormManager",
      name: "scormManager",
      component: ScormManager,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"]
        ) return next();
        router.push("/");
      },
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   component: About,
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   //component: () => import(/* webpackChunkName: "about" */ "./views/About.vue"),
    // },
    {
      path: "/tools",
      name: "tools",
      component: ToolsView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isOrgAdmin"] ||
          VueInstance.$store.getters["auth/isGroupAdmin"] ||
          VueInstance.$store.getters["auth/isLearner"]
        )
          return next();
        router.push("/");
      },
    },
  ],
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

// */
// Navigation Guards
// in order of lifecycle
// */

router.beforeResolve((to, from, next) => {
  if (to.name) {
    VueInstance.$wait.start("router");
  }
  next();
});

router.beforeEach((to, from, next) => {
  function proceed() {
    // console.log("Store.state: " + vuex.ux.ready);
    if (!vuex.ux.ready) return;
    // console.log("from: " + from.name);
    // console.log("to: " + to.name);

    if (to.name != "gdprApproval" && (VueInstance?.$store.state.auth.portalUser?.gdprApprovalRequired ?? false)) {
      router.push("/gdprApproval");
      // console.log("From: " + from.name  +" => To: " + to.name);
    }
    if (from.name == "home" && to.name != "home") {
      // VueInstance.$store.dispatch("notify/topicNotificationsNoted");
      vuex.notify.topicNotificationsNoted();
    }
    if (
      !vuex.auth.loggedIn &&
      to.name != "landingPage" &&
      to.name != "freebies" &&
      to.name != "account" &&
      to.name != "gdprApproval" && // TODO: Should be only accessible to authenticated users
      to.name != "gdpr" &&
      to.name != "imprint" &&
      to.name != "termsOfUse"
    ) return;
    next();
  }
  if (!vuex.ux.ready) {
    vuex.ux.$watch('isReady', (value: boolean) => {
      if (value === true) {
        proceed();
      }
    })
  }
  if (!vuex.auth.loggedIn) {
    vuex.auth.$watch('loggedIn', (value: boolean) => {
      if (value === true) {
        // console.log("logged in");
        proceed();
      }
    })
  }
  proceed();
});

router.afterEach((to, from) => {
  VueInstance.$wait.end("router");
});

export default router;
