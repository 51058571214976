





















// import _ from "lodash";
// import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { quizPlayerEventBus } from '@/main';

const globals = namespace("globals");

@Component({
  components: {}
})
export default class CompSurveyRating extends Vue {
  @Prop() text!: string;
  @Prop() textArray!: string[];
  @Prop() size!: string; // e.g. 24px
  @Prop() color!: string; // e.g. #00A1EF
  @Prop() weight!: string; // normal, bold, bolder, lighter, 100, 200 ... 900
  @Prop() fontStyle!: string; // normal, italic, oblique
  @Prop() finalized!: boolean;
  @Prop() compSettings!: string;

  settings: any = null;
  rating = 5;

  // $refs!: {};

  mounted() {
    if (this.compSettings) {
      this.settings = JSON.parse(this.compSettings);
      if (this.settings.preset)
        this.rating = this.settings.preset;
      this.onChanged(); // Save current rating to question result
    }
  }

  onChanged() {
    if (!this.settings)
      return;

    quizPlayerEventBus.$emit("changed:score", { questionId: this.settings.questionId, score: this.rating });
  }

  get ratingColor() {
    if (this.color)
      return this.color;
    return "yellow darken-2";
  }

  get backgroundColor() {
      if (this.$vuetify.theme.dark) {
        return "#ffffff30";
      }

      return "#ffffff80";
    }

  get ratingSize() {
    if (this.size == null || isNaN(Number(this.size)))
      return 18;
    return this.size;
  }

  get ratingLength() {
    if (this.settings && this.settings.length)
      return this.settings.length;
    return 5;
  }
}
