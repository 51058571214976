





























































































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { RestResponse } from "@/interfaces/RestResponse";
// import wretch, { WretcherError } from "wretch";
import { Component, Vue } from "vue-property-decorator";
// Interfaces
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ExSubject } from "@/interfaces/ExSubject";
import { ExBundleGroupVM, ExBundleVM } from '../interfaces/ExBundleGroupVM';
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { QrMediaLinkVM } from "@/interfaces/QrMediaLinkVM";
// Components
import FileDrop from "@/components/Shared/FileDrop.vue";
import TusFileDrop from "@/components/Shared/TusFileDrop.vue";
import UploadButton from "@/components/Shared/UploadButton.vue";
import UploadArchiveMenuButton from "@/components/UploadArchiveMenuButton.vue";
import EditScormDetailsDlg from "@/components/ExerciseManager/EditScormDetailsDlg.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
import { namespace } from "vuex-class";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";
import { List } from "linq-collections";
import { FileDetailsVm } from "@/interfaces/FileDetailsVm";
import { signalrEventBus } from "@/main";
// import de from 'vuetify/src/locale/de';

const auth = namespace("auth");
const ux = namespace("ux");

@Component({
  components: {
    FileDrop,
    TusFileDrop,
    UploadButton,
    UploadArchiveMenuButton,
    EditScormDetailsDlg,
    qrcode: VueQrcode
  }
})
export default class ContentManager extends Vue {
  $refs!: {
    detailsPanel: HTMLFormElement;
    qrcodeCanvas: HTMLCanvasElement;
  };
  @auth.Getter isAdmin: any;

  showDeleteFileDialog: boolean = false;
  showDeleteBundleDialog = false;
  showGuidDialog: boolean = false;
  showArchiveLog: boolean = false;
  showMediaLinkDialog: boolean = false;
  // grade: number = 1;
  // subjects: ExSubject[] = [];
  // selectedSubject: ExSubject | null = null;
  paramDir: ParamDictionary = { dictionary: {}};
  category1Items: ExCategory1[] = [];
  selectedCat1Item: ExCategory1 | null = null;
  category2Items: ExCategory2[] = [];
  selectedCat2Item: ExCategory2 | null = null;

  directorySize: number = 0;
  exBundleGroups: ExBundleGroupVM[] = [];
  selectedBundleGroupPanel: number | null = null;
  selectedBundle: ExBundleVM | null = null;
  fileUploadParam: ParamDictionary = { dictionary: {}};
  fileListParam: ParamDictionary = { dictionary: {}};
  fileDownloadParam: ParamDictionary = { dictionary: {}};
  bundleFileList: FileDetailsVm[] | null = null;
  selectedFile: FileDetailsVm | null = null;
  newGuid: string | null = null;
  qrMediaLink: QrMediaLinkVM | null = null;
  fileImportList: string[] = [];
  fileImportBusy = false;
  fileUploadList: string[] = [];
  fileUploadBusy = false;
  ziArchiveCreationBusy = false;
  bundleZipCreationBusy = false;
  scormZipCreationBusy = false;
  archiveLog = "";

  async mounted() {
    // await this.getCategories1();
    // await this.getCategories2();
    await this.loadCategories("cat1", "");
    this.getGroupedExBundles();
    this.getDirectorySize();
  }

  created() {
    // SignalR event listener
    signalrEventBus.$on('zipArchiveCreationFinished', this.zipArchiveCreationFinished);
  }

  beforeDestroy() {
    // clearAllBodyScrollLocks();
    // Make sure to cleanup SignalR event handlers when removing the component
    signalrEventBus.$off('zipArchiveCreationFinished', this.zipArchiveCreationFinished);
  }

  // SignalR events
  zipArchiveCreationFinished(response: any) {
    console.log("importFinished", response.zipFileName + " " + response.message);
    if (response.status == "SUC") {
      this.$globalHelper.download(`api/resource/Temp/${response.zipFileName}`, response.zipFileName);
    } else {
    }

    // this.$emit('content:ZipArchiveCreationFinished');
    this.ziArchiveCreationBusy = false;
    this.bundleZipCreationBusy = false;
    this.scormZipCreationBusy = false;
  }

  // async getSubjects() {
  //   this.subjects = await rest.url("exercises/getSubjects").get();
  //   if (this.selectedSubject != null && this.selectedSubject.id != 0) {
  //     this.selectedSubject = this.subjects.find(s => s.id == this.selectedSubject?.id ?? 0) ?? null;
  //   } else {
  //     this.selectedSubject = this.subjects[0];
  //   }
  // }

  async cat1Changed(cat1: ExCategory1) {
    this.selectedCat1Item = cat1;
    await this.loadCategories("cat1", "");
    this.getGroupedExBundles();
  }

  async cat2Changed(cat2: ExCategory2) {
    this.selectedCat2Item = cat2;
    await this.loadCategories("cat2", "");
    this.getGroupedExBundles();
  }

  async loadCategories(changeEvent: string, selectGuid: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["SelectGuid"] = selectGuid;
    // this.paramDir.dictionary["UserId"] = this.selectedUser.id!;
    // this.paramDir.dictionary["GroupId"] = this.selectedGroup.id.toString()!;
    this.paramDir.dictionary["Category1Id"] = this.selectedCat1Item?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCat2Item?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = "0";

    await rest.url("exercises/loadAllCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      this.selectedCat1Item = result.selectedCat1Item
      this.category2Items = result.category2Items!;
      this.selectedCat2Item = result.selectedCat2Item
      // this.category3Items = result.category3Items!;
      // this.selectedCat3Item = result.selectedCat3Item
    })
  }

  get selectedCat1ItemName() {
    if (!this.selectedCat1Item)
      return "-";
    return this.selectedCat1Item.name;
  }

  get selectedCat2ItemName() {
    if (!this.selectedCat2Item)
      return "-";
    return this.selectedCat2Item.name;
  }

  async getGroupedExBundles() {
    // let param: ParamGetExercises = {
    //   // grade: this.grade,
    //   // subjectId: this.selectedSubject?.id ?? 0,
    //   category1Id: this.selectedCat1Item?.id ?? 0,
    //   category2Id: this.selectedCat2Item?.id ?? 0,
    //   category3Id: 0, // not necessary
    //   orgId: 0, // not relevant
    //   groupId: 0, // not relevant
    //   subGroupId: 0, // not relevant
    //   userId: null, // not relevant
    //   bundleId: 0, // not relevant
    //   enabled: false, // not relevant
    //   writeResults: false , // not relevant
    //   showUserView: false // not relevant
    // };
    let param: ParamDictionary = { dictionary: {}};
    param.dictionary!["Category1Id"] = (this.selectedCat1Item?.id ?? 0).toString();
    param.dictionary!["Category2Id"] =  (this.selectedCat2Item?.id ?? 0).toString();
    param.dictionary!["AddSpecialFolders"] = "true";

    this.exBundleGroups = await rest.url("contentManager/getGroupedExBundles").post(param);

    if (this.selectedBundle == null)
      return;

    let catName = this.selectedBundle.category3;
    if (catName == null) {
      this.selectedBundle = null;
      return;
    }

    let category = this.exBundleGroups.find(g => g.categoryName?.startsWith(catName!));
    if (category) {
      this.selectedBundle = category?.bundles?.find(b => b.guid == this.selectedBundle?.guid) ?? null;
    } else {
      this.selectedBundle = null;
    }
  }

  // Reads Exercise directory on server and imports all bundle information's
  async updateExBundles() {
    await rest.url("contentManager/updateExBundles").post();
    this.getGroupedExBundles();
  }

  // async onNewFileUploaded(response: any) {
  //   if (!response)
  //     return;

  //   await this.loadCategories("selectGuid", response);
  //   this.getGroupedExBundles();
  // }

  onFilesUploaded() {
    this.getGroupedExBundles();
    this.getBundleFiles();
  }

  showExerciseBundle(bundle: ExBundleVM) {
    this.selectedBundle = bundle;
    this.getBundleFiles();
  }

  openDeleteBundleDialog(bundle: ExBundleVM) {
    this.selectedBundle = bundle;
    this.showDeleteBundleDialog = true;
  }

  async deleteBundle() {
    this.fileDownloadParam = { dictionary: {}};
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";

    await rest.url("contentManager/removeBundleWithFiles").post(this.fileDownloadParam);
    this.showDeleteBundleDialog = false;
    this.getGroupedExBundles();
  }

  async onBundleOrArchiveFileUploaded(response: any) {
    if (!response.success) {
      this.$store.commit("ux/SB_FAILURE", {
        message: response.message,
        timeout: 0
      });
      return;
    }

    // Add file to list and start import on server
    this.fileImportList.push(response.fileName);
    this.processFileImportList();
  }

  async processFileImportList() {
    if (this.fileImportList.length == 0 || this.fileImportBusy)
      return;

    this.fileImportBusy = true;
    let nextFile = this.fileImportList.pop();
    this.fileUploadParam.dictionary!["FileName"] = nextFile!;
    await rest.url("contentManager/importExerciseExcelOrZipArchiveFile").post(this.fileUploadParam)
    .then(async (response) => {
      if (!response) {
        await this.loadCategories("update", "");
        await this.getGroupedExBundles();
        return;
      }
      if (nextFile?.endsWith(".xlsx")) {
        // select category of uploaded Excel file
        await this.loadCategories("selectGuid", response);
        await this.getGroupedExBundles();

        let group = new List(this.exBundleGroups).singleOrDefault(g => new List(g.bundles!).any(b => b.guid == response));
        if (group) {
          // open expansion panel
          let index = this.exBundleGroups.indexOf(group);
          this.selectedBundleGroupPanel = index;
          // select bundle
          let bundle = new List(group.bundles!).single(b => b.guid == response);
          this.showExerciseBundle(bundle);
        }
        return;
      }

      this.archiveLog += "<br<br>" + response;
      this.showArchiveLog = true;
      await this.loadCategories("update", "");
      await this.getGroupedExBundles();
    })
    .catch(err => {
    })
    .finally(() => {
      this.fileImportBusy = false;
      this.processFileImportList();
    });
  }

  updateView() {
    this.getGroupedExBundles();
    this.getBundleFiles();
  }

  onFileUploadedToTempDir(response: any) {
    if (!response.success) {
      this.$store.commit("ux/SB_FAILURE", {
        message: response.message,
        timeout: 0
      });
      return;
    }

    // Add file to list and start import on server
    this.fileUploadList.push(response.fileName);
    this.processFileUploadList();
  }

  async processFileUploadList() {
    if (this.fileUploadList.length == 0 || this.fileUploadBusy)
      return;

    this.fileUploadBusy = true;
    let nextFile = this.fileUploadList.pop();

    this.fileUploadParam.dictionary!["BundleId"] = this.selectedBundle!.id.toString();
    this.fileUploadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath!; // Shared Resource and Temp dir didn't have a bundle ID. Use LocalPath instead.
    this.fileUploadParam.dictionary!["FileName"] = nextFile!;
    await rest.url("contentManager/moveFileToLocalDir").post(this.fileUploadParam)
    .then(async (response) => {
      if (!response) {
        return;
      }
      // select category of uploaded Excel file
      await this.loadCategories("selectGuid", response);
      await this.getGroupedExBundles();

      let group = new List(this.exBundleGroups).singleOrDefault(g => new List(g.bundles!).any(b => b.guid == response));
      if (group) {
        // open expansion panel
        let index = this.exBundleGroups.indexOf(group);
        this.selectedBundleGroupPanel = index;
        // select bundle
        let bundle = new List(group.bundles!).single(b => b.guid == response);
        this.showExerciseBundle(bundle);
      }
    })
    .catch(err => {
    })
    .finally(() => {
      this.fileUploadBusy = false;
      this.processFileUploadList();
    });

    // this.getGroupedExBundles();
    this.getBundleFiles();
  }

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.newGuid ?? "?");
    this.$store.commit("ux/SB_SUCCESS", {
      message: (this.newGuid ?? "?") + " in Zwischenablage kopiert",
      timeout: 2000
    });
  }

  onReady(canvas) {
    const context = canvas.getContext('2d');
    const image = new Image();

    image.src = 'qr-icon.png';
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const coverage = 0.15;
      const width = Math.round(canvas.width * coverage);
      const x = (canvas.width - width) / 2;

      this.drawImage(context, image, x, x, width, width);
    };
  }

  drawImage(context, image, x, y, width, height, radius = 4) {
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 2;
    context.shadowBlur = 4;
    context.shadowColor = '#00000040';
    context.lineWidth = 8;
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
    context.strokeStyle = '#fff';
    context.stroke();
    context.clip();
    context.fillStyle = '#fff';
    context.fillRect(x, x, width, height);
    context.drawImage(image, x, x, width, height);
  }

  async getDirectorySize() {
    // let pathParams = { dictionary: { LocalPath: this.selectedBundle.localPath!} };
    let pathParams = { dictionary: { LocalPath: ""} };
    this.directorySize = await rest.url("contentManager/getDirectorySize").post(pathParams);
  }

  get dirSize() {
    return this.sizeFormatted(this.directorySize);

    // if (!+this.directorySize) return '0 Bytes'

    // const k = 1024;
    // const dm = 2;
    // const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    // const i = Math.floor(Math.log(this.directorySize) / Math.log(k))

    // return `${parseFloat((this.directorySize / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  sizeFormatted(size: number): string {
    if (!+size) return '0 Bytes'

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(size) / Math.log(k))

    return `${parseFloat((size / Math.pow(k, i)).toFixed(dm)).toLocaleString()} ${sizes[i]}`
  }

  async getBundleFiles() {
    if (this.selectedBundle == null)
      return;

    this.bundleFileList = null;
    this.fileListParam.dictionary!["BundleId"] = this.selectedBundle.id.toString();
    this.fileListParam.dictionary!["LocalPath"] = this.selectedBundle.localPath!;
    this.bundleFileList = await rest.url("contentManager/getBundleFiles").post(this.fileListParam);
  }

  get fileCount() {
    if (!this.bundleFileList)
      return "";

    return ` (${this.bundleFileList.length})`
  }

  async downloadFile(fileInfo: FileDetailsVm) {
    if (!fileInfo)
      return;

    if (fileInfo.isDirectory) {
      await this.createScormZipFile(fileInfo);
      return;
    }

    let fileName = fileInfo?.name ?? ""
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";
    this.fileDownloadParam.dictionary!["FileName"] = fileName;

    // let token = window.localStorage.getItem("digiClassAuth");
    // wretch("/api/contentManager/downloadFileFromBundle")
    // .auth(`Bearer ${ token }`)
    // .post(this.fileDownloadParam)
    // .blob(data => {
    //   // const blob = new Blob([data], { type: 'application/pdf' });
    //   const blob = new Blob([data]);
    //   const link = document.createElement('a');
    //   link.href = URL.createObjectURL(blob);
    //   link.download = fileName;
    //   link.click();
    //   URL.revokeObjectURL(link.href);
    // })
    // .catch(err => {
    //   let apiError: RestResponse = JSON.parse(err.text);
    //   this.$store.commit("ux/SB_FAILURE", {
    //     message: `Server Error - ${apiError.description}`,
    //     timeout: 0
    //   });
    // });
    this.$globalHelper.download(`api/resource/${this.selectedBundle!.localPath ?? ""}/${fileName}`, fileName);
  }

  //onEditScorm(fileInfo: FileDetailsVm) {
  //}

  async readMediaLink(fileInfo: FileDetailsVm) {
    this.selectedFile = fileInfo;
    // this.fileDownloadParam.dictionary!["MediaLinkId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";
    this.fileDownloadParam.dictionary!["FileName"] = fileInfo?.name ?? "";
    this.fileDownloadParam.dictionary!["YouTubePath"] = this.qrMediaLink?.youTubePath ?? "";

    this.qrMediaLink = await rest.url("contentManager/readMediaLink").post(this.fileDownloadParam);

    this.showMediaLinkDialog = true;
    // Create qrcode logo
    await this.$globalHelper.delay(100);
    this.onReady((this.$refs.qrcodeCanvas as any).$el);
  }

  async writeMediaLink() {
    await rest.url("contentManager/writeMediaLink").post(this.qrMediaLink);
    this.showMediaLinkDialog = false;
  }

  // async downloadBundleZipFile() {
  //   this.fileDownloadParam.dictionary = {};
  //   this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();

  //   let token = window.localStorage.getItem("digiClassAuth");
  //   wretch("/api/contentManager/downloadBundleZip")
  //   .auth(`Bearer ${ token }`)
  //   .post(this.fileDownloadParam)
  //   .blob(data => {
  //     // const blob = new Blob([data], { type: 'application/pdf' });
  //     const blob = new Blob([data]);
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = `${ this.selectedBundle!.category1 }-${ this.selectedBundle!.category2 }-${ this.selectedBundle!.name }.zip`;
  //     link.click();
  //     URL.revokeObjectURL(link.href);
  //   })
  //   .catch(err => {
  //     let apiError: RestResponse = JSON.parse(err.text);
  //     this.$store.commit("ux/SB_FAILURE", {
  //       message: `Server Error - ${apiError.description}`,
  //       timeout: 0
  //     });
  //   });
  // }

  async createScormZipFile(fileInfo: FileDetailsVm) {
    this.scormZipCreationBusy = true;

    let params: ParamDictionary = { dictionary: {}};
    params.dictionary!["SignalRId"] = this.$l3rnOnlineHub.ConnectionId!;
    params.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    params.dictionary!["DirName"] = fileInfo?.name ?? "";

    await rest.url("contentManager/createScormZipFile")
      .post(params)
      // .then((fileName) => {
      //   this.$globalHelper.download(`api/resource/Temp/${fileName}`, `${ dirName }.zip`);
      // })
      // .finally(() => {
      //   this.scormZipCreationBusy = false;
      // });
  }

  async createBundleZipFile() {
    this.bundleZipCreationBusy = true;

    let params: ParamDictionary = { dictionary: {}};
    params.dictionary!["SignalRId"] = this.$l3rnOnlineHub.ConnectionId!;
    params.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();

    await rest.url("contentManager/createBundleZipFile")
      .post(params);
      // .then((fileName) => {
      //   this.$globalHelper.download(`api/resource/Temp/${fileName}`, `${ this.selectedBundle!.category1 }-${ this.selectedBundle!.category2 }-${ this.selectedBundle!.name }.zip`);
      // })
      // .finally(() => {
      //   this.bundleZipLoading = false;
      // });
  }

  // async downloadExerciseZipFile() {
  //   this.fileDownloadParam.dictionary = {};
  //   this.fileDownloadParam.dictionary!["Category1"] =  this.selectedCat1Item!.name ?? "";
  //   this.fileDownloadParam.dictionary!["Category2"] =  this.selectedCat2Item!.name ?? "";

  //   let token = window.localStorage.getItem("digiClassAuth");
  //   wretch("/api/contentManager/downloadCategory3BundlesAsZip")
  //   .auth(`Bearer ${ token }`)
  //   .post(this.fileDownloadParam)
  //   .blob(data => {
  //     // const blob = new Blob([data], { type: 'application/pdf' });
  //     const blob = new Blob([data]);
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = `${ this.selectedCat1Item!.name }-${ this.selectedCat2Item!.name }-all.zip`;
  //     link.click();
  //     URL.revokeObjectURL(link.href);
  //   })
  //   .catch(err => {
  //     let apiError: RestResponse = JSON.parse(err.text);
  //     this.$store.commit("ux/SB_FAILURE", {
  //       message: `Server Error - ${apiError.description}`,
  //       timeout: 0
  //     });
  //   });
  // }

  async createCategory2Archive() {
    this.ziArchiveCreationBusy = true;

    await this.$l3rnOnlineHub.ensureConnection();

    let params: ParamDictionary = { dictionary: {}};
    params.dictionary!["SignalRId"] = this.$l3rnOnlineHub.ConnectionId!;
    params.dictionary!["Category1"] =  this.selectedCat1Item!.name ?? "";
    params.dictionary!["Category2"] =  this.selectedCat2Item!.name ?? "";

    await rest.url("contentManager/createCategory2Archive")
      .post(params);
      // .then((fileName) => {
      //   this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
      // })
      // .finally(() => {
      //   this.zipArchiveCreationBusy = false;
      // })
  }

  async deleteFile () {
    if (!this.selectedBundle || !this.selectedFile)
      return;

    this.fileDownloadParam = { dictionary: {}};
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle.localPath ?? "";
    this.fileDownloadParam.dictionary!["FileName"] = this.selectedFile.name ?? "";
    this.fileDownloadParam.dictionary!["IsDirectory"] = this.selectedFile.isDirectory.toString();

    await rest.url("contentManager/deleteFileOrDirectoryFromBundle").post(this.fileDownloadParam);
    this.showDeleteFileDialog = false;
    this.getBundleFiles();
  }

  async generateGuid() {
    this.newGuid = await rest.url("contentManager/getNewGuid").get();
    this.showGuidDialog = true;
  }

  // use $vuetify.breakpoint.xs to determine current breakpoint instead of checking height of element
  isDetailsCardVisible(): boolean {
    // const style = getComputedStyle(this.$refs.detailsPanel);
    // let val = style.display !== 'none';
    // https://davidwalsh.name/offsetheight-visibility
    let height = this.$refs.detailsPanel.offsetHeight;
    // alert(height);
    let visible = height !== 0;
    return visible;
  }

  // replace with vuex variant
  get backgroundColor() {
    let currentThemeName = this.$vuetify.theme.dark ? 'dark' : 'light';
    return this.$vuetify.theme.themes[currentThemeName].background;
  }
}
