



































  // import _ from "lodash";
  // import rest from "@/rest";
  import { Component, Vue, Watch, Prop } from "vue-property-decorator";
  import { Action, namespace } from "vuex-class";
  import { quizPlayerEventBus } from '@/main';

  const globals = namespace("globals");

  @Component({
    components: {}
  })
  export default class CompSurveySlider extends Vue {
    @Prop() text!: string;
    @Prop() textArray!: string[];
    @Prop() size!: string; // e.g. 24px
    @Prop() color!: string; // e.g. #00A1EF
    @Prop() weight!: string; // normal, bold, bolder, lighter, 100, 200 ... 900
    @Prop() fontStyle!: string; // normal, italic, oblique
    @Prop() finalized!: boolean;
    @Prop() compSettings!: string;

    settings: any = null;
    rating = 5;
    min = 0;
    max = 5;
    lable1: string | null = null;
    lable2: string | null = null;

    // $refs!: {};

    mounted() {
      if (this.compSettings) {
        this.settings = JSON.parse(this.compSettings);
        if (this.settings.preset)
          this.rating = parseInt(this.settings.preset, 10);
          if (this.settings.min)
          this.min = this.settings.min;
          if (this.settings.max)
          this.max = this.settings.max;
          if (this.settings.lable1)
          this.lable1 = this.settings.lable1;
          if (this.settings.lable2)
          this.lable2 = this.settings.lable2;
      }
      this.onChanged(); // Save current rating to question result
    }

    onChanged() {
      if (!this.settings)
        return;

      quizPlayerEventBus.$emit("changed:score", { questionId: this.settings.questionId, score: this.rating });
    }

    get ratingColor() {
      if (this.color)
        return this.color;
      return this.$store.state.ux.colorAccent;
    }

    get backgroundColor() {
      if (this.$vuetify.theme.dark) {
        return "#ffffff30";
      }

      return "#ffffff80";
    }

    get sliderColor() {
      if (this.$vuetify.theme.dark) {
        return "light-blue lighten-1";
      }

      return this.$store.state.ux.colorAccent;
    }

    get ratingSize() {
      if (this.size == null || isNaN(Number(this.size)))
        return 18;
      return this.size;
    }

    get tickLables() {
      let ticks: any [] = []
      for (let tick = this.min; tick <= this.max; tick++) {
        ticks.push(tick);
      }
      return ticks;
    }

    // get ratingLength() {
    //   if (this.settings && this.settings.length)
    //     return this.settings.length;
    //   return 5;
    // }
  }
  