import { PortalUserVM, SelectListItem, GroupVm, OrganizationVm, UserTagVm } from "@/interfaces/PortalUserVM";

export class PortalUserVmClass implements PortalUserVM {
    id = "";
    firstName = null;
    lastName = "";
    nickname = "";
    fullName = "";
    userName = "";
    email = "";
    phoneNumber = "";
    department = "";
    location = "";
    organizationName = "";
    corporateId = null;
    jobTitle = "";
    costCenter = "";
    customAttribute01 = "";
    customAttribute02 = "";
    customAttribute03 = "";
    customAttribute04 = "";
    customAttribute05 = "";
    customAttribute06 = "";
    customAttribute07 = "";
    birthday = new Date("1900-01-01");
    disabled = false;
    selectRoles = [] as SelectListItem[];
    roles = [];
    showMenu = false;
    selected = false;
    avatar = "";
    disableAvatar = false;
    canEditDynamicGroups = false;
    entryDate = new Date();
    lastAccessDate = null;
    gdprApprovalDate = null;
    gdprApprovalRequired = false;
    activationKey = "";
    organizationId = 0;
    groupId = 0;
    groups = [] as GroupVm[];
    userTags =  [] as UserTagVm[];
    userOrganizations = [] as OrganizationVm[];
    groupAdminOrganizations = [] as OrganizationVm[];
}