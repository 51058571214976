import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { action, createModule, mutation } from "vuex-class-component";
import router from '@/router';
import { RootStore } from "../types";
import { List } from "linq-collections";
import {
  ExDataSet,
  ExViewElement,
  ExChoiceList,
  ExChoiceListItem,
  ExChoice,
  ExQuestion,
} from "@/interfaces/ExDataSet";

const VuexModule = createModule({
  strict: false,
  namespaced: "quizplayerstore",
});

export class QuizPlayerStore extends VuexModule {
  disableVerifyButton = false;
  _selectedQuestion: ExQuestion | null = null;
  _ChildQuestions: any = null;
  _selectedChildQuestionId = 0;
  choiceLists: ExChoiceList[] = [];
  // savedHistoryLength: number = 0;
  savedRouteName: string = "";
  navigationInProgress = false;

  // mounted() {
  //   router.beforeEach((to, from) => {
  //     console.log(">>router.beforeEach: " + from.name + " => " + to.name);
  //   });
  // }

  get browserBackNavigationInProgress() {
    return this.navigationInProgress;
  }

  get childQuestions() {
    return this._ChildQuestions;
  }

  get selectedChildQuestionId() {
    return this._selectedChildQuestionId;
  }

  get selectedChildQuestion() {
    if (!this.childQuestions) return null;

    return this.childQuestions[this.selectedChildQuestionId];
  }

  get choicesRightAnswered() {
    if (this._selectedQuestion?.hasChildQuestions) {
      return !new List(this.choiceLists).any((l) => l.questionId == this.selectedChildQuestion.questionId && l.state == "F");
    } else {
      return !new List(this.choiceLists).any((l) => l.questionId == this._selectedQuestion?.questionId && l.state == "F");
    }
  }

  get allChoicesSelected() {
    if (this._selectedQuestion?.hasChildQuestions) {
      return !new List(this.choiceLists).any((l) => l.questionId == this.selectedChildQuestion.questionId && l.state == "NA");
    } else {
      return !new List(this.choiceLists).any((l) => l.questionId == this._selectedQuestion?.questionId && l.state == "NA");
    }
  }

  @action()
  async setChoiceListsFromParentOrChildQuestions() {
    let choiceLists: ExChoiceList[] = [];
    this.setChoices(choiceLists);
    if (this._selectedQuestion == null)
      return choiceLists;

    // console.log("selected Question: ", this._selectedQuestion);
    if (this._selectedQuestion.hasChildQuestions) {
      for (const cq of this._selectedQuestion.questions!) {
        choiceLists = choiceLists.concat(await this.getChoiceListsFromQuestion(cq));
      };
    } else {
      choiceLists = await this.getChoiceListsFromQuestion(this._selectedQuestion);
    }

    this.setChoices(choiceLists);
  }

  @action()
  async getChoiceListsFromQuestion(question: ExQuestion): Promise<ExChoiceList[]> {
    let choiceLists: ExChoiceList[] = [];
    let viewElements: ExViewElement[] = [];
    if (question?.viewElements == null || question.viewElements.length == 0)
      return choiceLists;

    for (let i = 0, len = question.viewElements.length; i < len; i++) {
      if (question.viewElements[i].type != "CompBlock")
        continue;

      let resultList = await this.findChoiceLists(question.viewElements[i]);
      if (resultList && resultList.length > 0) {
        for (let i = 0, len = resultList.length; i < len; i++) {
          choiceLists.push(resultList[i]);
        }
      }
    }

    return choiceLists;
  }

  @action()
  async findChoiceLists(element: ExViewElement): Promise<ExChoiceList[]> {
    let selectLists: ExChoiceList[] = [];

    // Call recursive for all child elements
    if (element.elements && element.elements.length > 0) {
      for (let i = 0, len = element.elements.length; i < len; i++) {
        // console.log(element.elements[i].type);
        // if (element.elements[i].type == "CompMultipleSteps")
        //     continue;

        let resultList = await this.findChoiceLists(element.elements[i]);
        if (resultList && resultList.length > 0) {
          for (let i = 0, len = resultList.length; i < len; i++) {
            // console.log(resultList[i]);
            selectLists.push(resultList[i]);
          }
        }
      }
    }
    // Check if element itself has a choiceList
    if (element.choiceList) {
      // console.log(element.choiceList);
      selectLists.push(element.choiceList);
    }
    return selectLists;
  }

  // @mutation loadQuestionChoiceLists(question: ExQuestion) {
  //   let choiceLists = this.getSelectListsFromQuestion(question);
  //   console.log("choiceLists:", choiceLists);
  //   if (!choiceLists) this.choiceLists = [];
  //   this.choiceLists = choiceLists;
  // }

  @action async setQuestion (question: ExQuestion) {
    // this.setChoices([]);
    this.saveQuestion(question);
    await this.setChoiceListsFromParentOrChildQuestions();
  }

  @mutation saveCurrentRouteName() {
    this.navigationInProgress = false;
    // this.savedHistoryLength = history.length;
    this.savedRouteName = router.currentRoute?.name ?? "";
    // console.log("saveHistoryLength: " + this.savedHistoryLength);
    console.log("saveCurrentRouteName: " + this.savedRouteName)
  }

  @action async navigateBackToSavedRouteName () {
    // if (this.navigationInProgress || this.savedHistoryLength == 0)
    if (this.navigationInProgress)
      return;

      this.navigationInProgress = true;

      // Didn't work because history.length is sometimes higher than the current postion in the history stack
      // console.log("history.length " + history.length);
      // console.log("history.state " + history.state);
      // if (history.length < 50) {
      //   let backNavigationCnt =  this.savedHistoryLength - history.length;
      //   console.log("backNavigationCnt " + backNavigationCnt);
      //   router.go(backNavigationCnt);

      //   this.savedHistoryLength = 0;
      //   this.navigationInProgress = false;
      //   return;
      // }

      console.log("Current route: " + router.currentRoute.name);
      console.log("Navigate back to route: " + this.savedRouteName);
      let i = 0;
      for (i =  history.length; i > 0; i--) {
        // console.log("   history length: " + history.length);
        console.log(`    ${i} Current route: ${router.currentRoute.name}`);
        if (router.currentRoute.name == this.savedRouteName) {
          console.log("       reached target => break");
          break;
        }

        router.go(-1);
        await new Promise(resolve => setTimeout(resolve, 50));
        if (!this.navigationInProgress) {
          console.log("       navigation stopped");
          break;
        }
      }

      // console.log("history length at the end: " + history.length);
      if (i == 0) {
        console.log("route to /myExercises")
        router.replace("/myExercises");
      }

      this.navigationInProgress = false;
  }

  @mutation saveQuestion(question: ExQuestion) {
    this._selectedQuestion = question;
    this._ChildQuestions = question.questions;
    this._selectedChildQuestionId = 0;
  }

  @mutation incrementChildQuestionId() {
    this._selectedChildQuestionId += 1;
  }

  @mutation setSelectedChildQuestion(value: ExQuestion) {
    if (!this.childQuestions) return;
    this._ChildQuestions[this.selectedChildQuestionId] = value;
  }

  @mutation setChoices(choiceLists: ExChoiceList[]) {
    if (!choiceLists)
      this.choiceLists = [];

    this.choiceLists = choiceLists;
  }

  @mutation setChoiceState(params: { id: number; questionId: number; isRight: boolean; selectionLable: string }) {
    // console.log(`ID ${params.id}, Question ID ${params.questionId}, lable ${params.selectionLable}`);
    let choiceList = new List(this.choiceLists).singleOrDefault((l) => l.id == params.id && l.questionId == params.questionId);
    if (!choiceList) return;
    choiceList.state = params.isRight ? "T" : "F";
    choiceList.selectionLable = params.selectionLable;
    // Enable verify button if all selectBoxes have selections => separat getter
    // if (!new List(this.selectLists).any(l => l.state == "NA"))
    //   this.disableVerifyButton = false;
  }

  // @mutation setRating(params: { id: number; questionId: number; isRight: boolean; selectionLable: string }) {
  //   // console.log(`ID ${params.id}, Question ID ${params.questionId}, lable ${params.selectionLable}`);
  //   let choiceList = new List(this.choiceLists).singleOrDefault((l) => l.id == params.id && l.questionId == params.questionId);
  //   if (!choiceList) return;
  //   choiceList.state = params.isRight ? "T" : "F";
  //   choiceList.selectionLable = params.selectionLable;
  // }
}
