


























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";

@Component({
  components: {
  }
})
export default class SurveyCommentList extends Vue {
  @Prop() comments!: string[];

  show = false;
}
