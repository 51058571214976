













































































































































import _ from "lodash";
import rest from "@/rest";
import { RestResponse } from "@/interfaces/RestResponse";
import wretch, { WretcherError } from "wretch";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, OrganizationVm } from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';
import ExSurveyStatus from "../components/ExerciseComponents/ExSurveyStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import { GroupVm } from "@/interfaces/GroupVm";
import { SubGroupVm } from "@/interfaces/SubGroupVm";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory3 } from "@/interfaces/ExCategory3";
// import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";
import { vuex } from "@/store";
import { List } from "linq-collections";
import { ExSurveyVm } from "@/interfaces/ExSurveyVm";

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExSurveyStatus
  }
})
export default class SurveysView extends Vue {
  // @globals.Getter selectedOrg!: OrganizationVm;
  // @globals.Getter selectedGroup!: GroupVm;
  // @globals.Getter selectedSubGroup!: SubGroupVm;
  // @globals.Getter selectedUser!: PortalUserVM | null;
  @globals.Getter selectedCategory1!: ExCategory1 | null;
  @globals.Getter selectedCategory2!: ExCategory2 | null;
  @globals.Getter selectedCategory3!: ExCategory3 | null;
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isLearner: any;

  surveys: ExSurveyVm[] | null = [];
  paramDir: ParamDictionary = { dictionary: {}};
  category1Items: ExCategory1[] = [];
  category2Items: ExCategory2[] = [];
  category3Items: ExCategory3[] = [];

  resultsOverview: ResultOverviewVM | null = null;
  selectAll: boolean = true;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Umfragen', disabled: true, href: '/surveys' },
 ];

  async mounted() {
    await this.loadCategories("cat1");
    this.getSurveys();
  }

  async cat1Changed(cat1: ExCategory1) {
    // console.log("grade changed, get exercises")
    // this.selectedCat1Item = cat1;
    vuex.globals.setCategory1(cat1);
    await this.loadCategories("cat1");
    this.getSurveys();
  }

  async cat2Changed(cat2: ExCategory2) {
    // console.log("grade changed, get exercises")
    // this.selectedCat2Item = cat2;
    vuex.globals.setCategory2(cat2);
    await this.loadCategories("cat2");
    this.getSurveys();
  }

  async cat3Changed(cat3: ExCategory3) {
    // console.log("grade changed, get exercises")
    // this.selectedCat3Item = cat3;
    vuex.globals.setCategory3(cat3);
    await this.loadCategories("cat3");
    this.getSurveys();
  }

  async loadCategories(changeEvent: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["Category1Id"] = this.selectedCategory1?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCategory2?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = this.selectedCategory3?.id.toString() ?? "0";

    await rest.url("groupAdmin/LoadSurveyCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      // this.selectedCat1Item = result.selectedCat1Item
      vuex.globals.setCategory1(result.selectedCat1Item);
      this.category2Items = result.category2Items!;
      // this.selectedCat2Item = result.selectedCat2Item
      vuex.globals.setCategory2(result.selectedCat2Item);
      this.category3Items = result.category3Items!;
      // this.selectedCat3Item = result.selectedCat3Item
      vuex.globals.setCategory3(result.selectedCat3Item);
    });
  }

  get selectedCat1ItemName() {
    if (!this.selectedCategory1)
      return "-";
    return this.selectedCategory1.name;
  }

  get selectedCat2ItemName() {
    if (!this.selectedCategory2)
      return "-";
    return this.selectedCategory2.name;
  }

  get selectedCat3ItemName() {
    if (!this.selectedCategory3)
      return "-";
    return this.selectedCategory3.name;
  }

  async getResults() {
    this.resultsOverview = await rest.url("exercises/getResultsOverview").get();
    // console.log(this.resultsOverview);
  }

  async getSurveys() {
    if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.surveys = [];
    this.paramDir.dictionary["Category1Id"] = (this.selectedCategory1?.id ?? 0).toString();
    this.paramDir.dictionary["Category2Id"] = (this.selectedCategory2?.id ?? 0).toString();
    this.paramDir.dictionary["Category3Id"] = (this.selectedCategory3?.id ?? 0).toString();
    // this.paramDir.dictionary["timeFrame"] = this.selectedTimeFrame.value!;

    this.surveys = await rest.url("groupAdmin/loadSurveyList").post(this.paramDir);
  }

  showSurvey(survey: ExSurveyVm) {
    // this.$store.commit("globals/setSurvey", survey);
    vuex.globals.setSurvey(survey);

    router.push("/surveyEvaluation");
  }

  goBack() {
    router.go(-1);
  }
}
