






































































































































































































































































































































































































































import _ from "lodash";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import rest from "./rest";
import { vuex } from "@/store";
import router from "@/router";
import { Workbox } from 'workbox-window'
import { signalrEventBus } from '@/main';
// import { Messaging } from 'firebase/messaging';

// views & components
import About from "@/views/About.vue";
import LandingPage from "@/views/LandingPage.vue";
import AvatarMenu from "@/components/AvatarMenu.vue"; // @ is an alias to /src
import UserMessages from "@/components/Toolbar/UserMessages.vue";
import PrintUsersQrCodes from "@/components/Groups/PrintUserQrCodes.vue";
// import Notifications from "@/components/Toolbar/Notifications.vue";

// interfaces
import { PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
import { CustomizationVm } from "./interfaces/CustomizationVm";

// styles
import "@/sass/app.scss";

const auth = namespace("auth");
const ux = namespace("ux");
const globals = namespace("globals");
const notify = namespace("notify");
const approvalChat = namespace("approvalChat");
const customization = namespace("customization");

@Component({
  components: {
    AvatarMenu,
    UserMessages,
    LandingPage,
    PrintUsersQrCodes,
    About
  }
})
export default class App extends Vue {
  @auth.Action Login: any;
  @approvalChat.Action updateNewApprovalChatCount: any;

  @auth.Getter isAdmin: any;
  @auth.Getter isContentManager: any;
  @auth.Getter isNotificationAdmin: any;
  @auth.Getter isOrgAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isGroupViewer: any;
  @auth.Getter isLearner: any;

  @auth.Getter enableBundleApprovalView: any;
  @auth.Getter loginName!: string;
  @auth.Getter loggedIn!: boolean;
  @auth.Getter logoutTime!: number;
  @notify.Getter hasNewTopicNotifications: any;
  @notify.Getter newTopicNotificationCount: any;
  @notify.Action registerServiceWorker: any;
  @globals.Getter isInPrintMode!: boolean;
  @globals.Getter showPrintUsersQrCode!: boolean;
  @approvalChat.Getter newApprovalChatCount!: number;

  @customization.Action loadCustomizationSettings!: any;
  @customization.Getter customizationSettings!: CustomizationVm;

  @ux.Mutation COLORS_LIGHT: any;
  @ux.Mutation COLORS_DARK1: any;
  @ux.Mutation COLORS_DARK2: any;
  @ux.State darkTheme: any;

  dynGroupUpdateProgress = 0;
  dynGroupUpdateBusy = false;
  agreeToGdpr = false;
  showGdprDlg = false;
  showAboutDlg = false;
  showUpdateAvailable = false;
  showLogout = false;
  workbox: any = null;

  $refs!: {
    aMenu: HTMLElement;
  };

  rtviewkey: number = 5000;
  drawer: boolean = false;
  viewIndex: number = 0;
  showAppName: boolean = true;

  // testUserVm: PortalUserVM = _.cloneDeep(this.defaultUser);
  //views = this.$route.options.routes;

  deferPwaPrompt: any = null;
  isStandalone = false;
  showInstallButton = false;

  async mounted() {
    this.checkIfAppIsStandalone();

    this.$nextTick(() => {
      vuex.ux.setReady();
      this.init();
      // this.COLORS_DARK2();
      this.COLORS_LIGHT();
      this.$vuetify.theme.dark = this.darkTheme;
    });
  }

  created() {
    window.addEventListener("resize", this.onResize);

    // https://forum.quasar-framework.org/topic/2560/solved-pwa-force-refresh-when-new-version-released/56
    // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
    if ('service-worker' in navigator) {
      this.workbox = new Workbox("/app-sw.js")

      this.workbox.addEventListener("waiting", (event) => {
        this.showUpdateAvailable = true;
      })
      this.workbox.register();
    }

    // SignalR event listener
    signalrEventBus.$on('messageReceived', this.onSignalRMessageReceived);
    signalrEventBus.$on('dynamicGroupUpdateProgress', this.onDynGroupUpdateProgress);
    signalrEventBus.$on('dynamicGroupUpdateFinished', this.onDynGroupUpdateFinished);
    signalrEventBus.$on('updateApprovalChat', this.onUpdateApprovalChat);
  }

  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    signalrEventBus.$off('messageReceived', this.onSignalRMessageReceived);
    signalrEventBus.$off('dynamicGroupUpdateProgress', this.onDynGroupUpdateProgress);
    signalrEventBus.$off('dynamicGroupUpdateFinished', this.onDynGroupUpdateFinished);
    signalrEventBus.$off('updateApprovalChat', this.onUpdateApprovalChat);
  }

  destroyed() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener('beforeinstallprompt', this.deferPwaPromptCb);
  }

  // SignalR events
  onSignalRMessageReceived(message: string, messageType: string) {
    // console.log("SignalR message received on event bus: ", message + " " + messageType);
    switch (messageType) {
      case "SUC":
        vuex.ux.SB_SUCCESS({
            message: message,
            timeout: 5000
          });
        break;
        case "PRIM":
        vuex.ux.SB_PRIMARY({
            message: message,
            timeout: 5000
          });
        break;
        case "WAR":
        vuex.ux.SB_WARNING({
            message: message
          });
        break;
        case "ERR":
        vuex.ux.SB_FAILURE({
            message: message
          });
        break;

      default:
        break;
    }
  }

  onDynGroupUpdateProgress(progress: number) {
    this.dynGroupUpdateBusy = true;
    this.dynGroupUpdateProgress = progress;
  }

  onDynGroupUpdateFinished(response: any) {
    this.dynGroupUpdateBusy = false;
    if (response.status == "SUC") {
      vuex.ux.SB_SUCCESS({
        message: response.message,
        timeout: 8000
      });
    } else {
      vuex.ux.SB_FAILURE({
        message: response.message,
        timeout: 0
      });
    }
  }

  onUpdateApprovalChat() {
    // console.log("App.vue: onUpdateApprovalChat()");
    this.updateNewApprovalChatCount();
  }

  checkIfAppIsStandalone() {
    // console.log("addEventListener for beforeinstallprompt event");
    this.isStandalone = window.matchMedia('(display-mode: standalone)').matches; // For Android
    if (this.isStandalone) {
      console.log("Android standalone App detected");
    } else {
      this.isStandalone = (window.navigator as any)?.standalone === true; // For iOS
      if (this.isStandalone)
        console.log("iOS standalone App detected");
    }
    if (this.isStandalone)
      return;

    if (this.$globalHelper.isiOs) {
      this.showInstallButton = true;
    } else {
      window.addEventListener('beforeinstallprompt', this.deferPwaPromptCb);

      // Tracking Display Mode Transitions
      window.matchMedia('(display-mode: standalone)').addEventListener('change', ({ matches }) => {
          if (matches) {
              this.showInstallButton = false;
          } else {
              this.showInstallButton = true;
          }
      });
    }
  }

  deferPwaPromptCb(e: Event) {
    // console.log("defer PWA prompt event");

    e.preventDefault();
    this.deferPwaPrompt = e;
    this.showInstallButton = true;
  }

  async installApp() {
    if (!this.deferPwaPrompt) {
      console.log("Defer Pwa Prompt event not registered!");
      return;
    }

    this.deferPwaPrompt.prompt();
    let choiceResult = await this.deferPwaPrompt.userChoice;
    // console.log(choiceResult.outcome);
    if (choiceResult.outcome === "accepted") {
      this.deferPwaPrompt = null;
      this.showInstallButton = false;
    }
  }

  async onAgree() {
    let result = await rest.url("auth/agreeToGdpr").post();
      if (result == true) {
      // this.$store.commit("ux/SB_SUCCESS", {
      //   message: "Zustimmung gespeichert",
      //   timeout: 3000
      // });
      vuex.ux.SB_SUCCESS({
        message: "Zustimmung gespeichert",
        timeout: 3000
      });
    }

    // Update user
    let portalUser: PortalUserVM = await rest.url("Auth/getPortalUser").get();
    if (portalUser != null)
      // this.$store.commit("auth/setPortalUser", portalUser);
      vuex.auth.setPortalUser(portalUser);
  }

  @Watch("darkTheme")
  onThemeChanged (){
      this.$vuetify.theme.dark = this.darkTheme;
  }

  @Watch("drawer")
  onDrawerVisibilityChanged() {
     this.updateDrawerVisibility();
  }

  @Watch("$vuetify.breakpoint.mobile")
  onMobileBreakPointChanged() {
     this.updateDrawerVisibility();
  }

  @Watch("logoutTime")
  onLogoutTimeChanged() {
    if (!this.loggedIn) {
      this.showLogout = false;
      return;
    }
    this.showLogout = this.logoutTime > 0 && this.logoutTime <= 30;
  }

  get logoutTimeout() {
    if (this.logoutTime > 30)
      return 100;
    return this.logoutTime * 100 / 30;
  }

  updateDrawerVisibility() {
    if (!this.drawer)
      // this.$store.commit("ux/ReserveSpaceForDrawer", false);
      vuex.ux.ReserveSpaceForDrawer(false);
    else if (!this.$vuetify.breakpoint.mobile)
      // this.$store.commit("ux/ReserveSpaceForDrawer", true);
      vuex.ux.ReserveSpaceForDrawer(true);
  }

  updateApp() {
    if (!this.workbox) {
      window.location.reload();
      this.showUpdateAvailable = false;
      return;
    }
    this.workbox.addEventListener('controlling', () => {
      window.location.reload();
    })
   this.workbox.messageSkipWaiting();
  }

  onResize(event) {
    // this.updateDrawerVisibility();

    if (!this.$refs.aMenu)
      return;

    let avatarWidth = this.$refs.aMenu.offsetWidth;
    let windowWidth = window.innerWidth;
    // console.log(windowWidth);
    if (windowWidth - avatarWidth - 220 < 0)
      this.showAppName = false;
    else
      this.showAppName = true;
  }

  async init() {
    await this.loadCustomizationSettings()
    await this.registerServiceWorker();
    await this.Login();
    await this.updateNewApprovalChatCount();
  }

  refresh() {
    this.rtviewkey++;
    this.updateNewApprovalChatCount();
  }

  goHome() {
    if (router.currentRoute.path == "/")
      return;
    router.push("/");
  }

  goToGdprPage() {
    router.push("/gdpr");
  }

  goToImprintPage() {
    router.push("/imprint");
  }

  goToTermsOfUsePage() {
    router.push("/termsOfUse");
  }

  get currentTheme() {
    return this.darkTheme;
  }

  set currentTheme(val) {
    // console.log(this.darkTheme);

    if (this.darkTheme)
      this.COLORS_LIGHT();
    else
      this.COLORS_DARK2();
  }

  // get currentThemeName() {
  //   return (this.darkTheme) ? 'dark' : 'light'
  // }

  // get backgroundColor() {
  //   let currentThemeName = this.$vuetify.theme.dark ? 'dark' : 'light';
  //   return this.$vuetify.theme.themes[currentThemeName].appBackground;
  // }

  get themeName() {
    return this.darkTheme ? "Dunkles Design" : "Helles Design";
  }

  get gdprApprovalRequired() {
    return this.$store.state.auth.portalUser?.gdprApprovalRequired ?? false;
  }

  public get snackbar() {
    let sb = this.$store.state.ux.snackbar;
    return sb;
  }

  public set snackbar(value) {
    // this.$store.commit("ux/SB_UPDATE", value);
    vuex.ux.SB_UPDATE(value);
  }

  get snackbarColor() {
    // return this.$store.state.ux.sbcontext;
    return vuex.ux.sbcontext;
  }

  get snackbarTimeout() {
    // return this.$store.state.ux.sbtimeout;
    return vuex.ux.sbtimeout;
  }
}
