

































































































































import _, { sortBy } from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { List, Enumerable } from 'linq-collections';
import { PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
// import { ExSubject } from "@/interfaces/ExSubject";
// import { ExBundleAssignmentGroupVM, ExBundleAssignmentVM, ExBundleVM } from '../interfaces/ExBundleAssignmentGroupVM';
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from "@/interfaces/ResultOverviewVM";
// import { ExCategory1 } from '@/interfaces/PortalUser';
import { KeyValue } from '@/interfaces/KeyValue';
import PerformanceListItemStatus from "@/components/PerformanceOverview/PerformanceListItemStatus.vue";
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { vuex } from "@/store";
// import { ExTagAchievementVM } from '@/interfaces/ExTagAchievementVM';
// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");

@Component({
  components: {
    PerformanceListItemStatus
  }
})
export default class GroupPerformanceOverview extends Vue {
  @globals.Getter selectedGroup!: GroupExtVm;
  // @globals.Getter selectedSubject!: ExSubject;
  // @globals.Getter selectedUser!: PortalUserVM;
  // sortAscending: boolean = true;
  // showFilterMenu:boolean= false;
  // subjects: ExSubject[] = [];
  // localSubject: ExSubject | null = null;
  // categories: ExCategory[] = [];
  // selectedCategory: ExCategory | null = null;
  paramDir: ParamDictionary = { dictionary: {}};
  resultOverviews: ResultOverviewVM[] | null = null;
  resultOverviewSorted: ResultOverviewVM[] | null = null;
  sortByAlphabet: boolean = false;
  sortOrderAscending: boolean = false;
  windowInnerHeight = 0;

  breadcrumbItems = [
      { text: 'Start', disabled: false, href: '/home' },
      { text: 'Gruppen', disabled: false, href: '/exercises' },
      { text: 'Leistungsstand', disabled: true, href: '/groupPerformanceOverview' },
  ];

//   timeFrames: KeyValue[] = [
//     { key: 'alles', value: 'all' },
//     { key: '1 Woche', value: 'oneWeek' },
//     { key: '1 Monat', value: 'oneMonth' },
//     { key: '1. Halbjahr', value: 'firstHalfOfYear' },
//     { key: '2. Halbjahr', value: 'secondHalfOfYear' }
//   ];
//   selectedTimeFrame: KeyValue  = this.timeFrames[2];

  async mounted() {
    if (this.selectedGroup == null) {
      router.push("/");
      return;
    }

    this.setWindowHeight();
    //   await this.getSubjects();
    //   await this.getCategories();
    await this.getResults();
    //   await this.getTagAchievement();
      // this.getAssignmentsForAllExBundles();
  }

  created() {
    window.addEventListener("resize", this.setWindowHeight);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setWindowHeight);
  }

  setWindowHeight() {
    this.windowInnerHeight = window.innerHeight;
  }

  //   trainingColor(achivement: ExTagAchievementVM) {
  //     if (achivement.notWellTrained)
  //       return "grey";

  //     return "light-blue";
  //   }

  async getResults() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["GroupId"] = this.selectedGroup.id!.toString();

    this.resultOverviews = await rest.url("groupAdmin/groupPerformanceOverview").post(this.paramDir);
    // console.log(this.resultsOverview);
    this.onSort();
  }

  onUserPerformanceOverview(user: PortalUserVM) {
    if (user == null)
      return;

    // this.$store.commit('globals/setUser', user);
    vuex.globals.setUser(user);
    router.push("/userPerformanceOverview");
  }

  onSort() {
    if (this.resultOverviews == null)
      return;

    if (!this.sortByAlphabet && this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderBy(r => r.trophyValue).thenBy(r => r.cumulatedExerciseProgress).thenBy(r => r.starCountValue).toArray();
    if (!this.sortByAlphabet && !this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderByDescending(r => r.trophyValue).thenByDescending(r => r.cumulatedExerciseProgress).thenByDescending(r => r.starCountValue).toArray();
    if (this.sortByAlphabet && !this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderByDescending(r => r.pupil?.fullName).toArray();
    if (this.sortByAlphabet && this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderBy(r => r.pupil?.fullName).toArray();
  }

  get listHeight() {
    // if (this.$vuetify.breakpoint.xs)
    //   return this.windowInnerHeight - 255;
    // else
    //   return this.windowInnerHeight * 0.9 - 190;
    return this.windowInnerHeight - 265;
  }

  goBack() {
    router.go(-1);
  }
}
