










import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ICellRendererParams } from "ag-grid-community";
import { PortalUserVM } from "@/interfaces/PortalUserVM";

@Component
export default class AvatarLargeCellRenderer extends Vue {
  params!: ICellRendererParams;

  // $refs!: {
  // };

  mounted() {
    // console.log("AvatarCellRenderer", this.params);
    // if (this.params.node.rowPinned == "top")
    //   this.showCell = false;
  }

  // public init(params) {
  //   console.log("AvatarCellRenderer", params);
  // }

  get avatar() {
    // console.log("AvatarCellRenderer", this.params);
    return this.params.value;
  }

  // onCellRightClick(e) {
  //   let eventData = { event: e, params: this.params };
  //   console.log(eventData);

  //   this.$emit("onRightClick:aggridCell", eventData);
  // }

  /**
   * Helper
   */

  // delay(ms: number) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
}
