








































// import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';


const globals = namespace("globals");

@Component({
    components: {}
})
export default class PerformanceListItemStatus extends Vue {
  @Prop() result!: ResultOverviewVM;

  // $refs!: {};

  mounted() {}

  // get tropyProgress() {
  //   if (this.result.maxTrophyCount == 0)
  //     return 0;
  //   return Math.round(this.result.trophyCount * 100 / this.result.maxTrophyCount);
  // }
}
