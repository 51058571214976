



























import _ from "lodash";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { UserTagVm } from "@/interfaces/UserTagVm";
import { List } from "linq-collections";

const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class EditTag extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @PropSync("tag") syncedTag!: UserTagVm;
  @Prop() tags!: UserTagVm[];
  @Prop() isNewTag!: boolean;

  newTagName: string | null = null;

  mounted() {
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(open: boolean) {
    if (open) {
      // On dialog open
      this.newTagName = this.syncedTag.name;

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  get dialogTitle() {
    if (this.isNewTag)
      return "Neues Anwender-Tag";
    return "Anwender-Tag bearbeiten"
  }

  async saveTag() {
    if (!this.isUniqueTag) return;

    this.syncedTag.name = this.newTagName;

    if (this.isNewTag)
      this.$emit('newTagAdded:EditTag');

    this.syncedShowDialog = false;
  }

  get isUniqueTag() {
    if (!this.newTagName || this.newTagName.length == 0)
      return false;
    if (!this.tags)
      return false;
    if (new List(this.tags).any(t => t.name?.toUpperCase() == this.newTagName?.toUpperCase()))
      return false;

      return true;
  }

  get error() {
    if (!this.newTagName || this.newTagName.length == 0)
      return "Name ist erforderlich!";
    if (!this.tags)
      return null;
    if (new List(this.tags).any(t => t.name?.toUpperCase() == this.newTagName?.toUpperCase()))
      return "Tag mit diesem Namen existiert bereits!";

    return null;
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  closeDialog() {
    this.syncedShowDialog = false;
  }
}
