


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

// interfaces

// components
import L3onlineLandingPage from "@/components/LandingPage/L3onlineLandingPage.vue";
import L3schreinerLandingPage from "@/components/LandingPage/L3schreinerLandingPage.vue";

const ux = namespace("ux");

@Component({
  components: {
    L3onlineLandingPage,
    L3schreinerLandingPage
  }
})
export default class LandingPage extends Vue {
  @ux.State darkTheme: any;
  @ux.Getter isL3online: any;
  @ux.Getter isL3schreiner: any;
  
  mounted() {
    this.applyOrAdjustDigiClassTheme();
  }

  metaInfo() {
    return {
      titel: "L3RN online Home",
      meta: [
        {
          name: "description",
          content: "Die kostengünstige Schulungs-App"
        }
      ]
    }
  }

  applyOrAdjustDigiClassTheme() {
    // adjust old style
    let themeLink = document.getElementById('exerciseTheme');
    if (themeLink) {
      if (this.darkTheme)
        themeLink.setAttribute("href", "/styles/exercises-dark.css");
      else
        themeLink.setAttribute("href", "/styles/exercises-light.css");

      return;
    }

    // Create new one
    let styles = document.createElement('link');
    styles.type="text/css";
    styles.rel="stylesheet";
    styles.id="exerciseTheme";
    if (this.darkTheme)
      styles.href="/styles/exercises-dark.css";
    else
      styles.href="/styles/exercises-light.css";
    document.head.appendChild(styles);
  }

  get lightTransparentColor() {
      return this.$store.state.ux.colorAccent5 + "40";
  }

  get showLeftImage1() {
    return true;
  }

  get showLeftImage2() {
    return false;
  }

  get showRightImage1() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get showRightImage2() {
    return !this.$vuetify.breakpoint.xsOnly;
  }
}
