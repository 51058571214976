





















































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, namespace } from "vuex-class";
import rest from '@/rest';
import router from '@/router';
import _ from "lodash";
import { List } from 'linq-collections';
import FileDrop from '@/components/Shared/FileDrop.vue';
import DlgTopicNotification from '@/components/ManageTopicNotifications/DlgTopicNotification.vue'
import DlgTopic from '@/components/ManageTopicNotifications/DlgTopic.vue'
// interfaces
import { TopicNotificationVm } from '@/interfaces/TopicNotificationVm';
import { ParamDictionary } from '@/interfaces/ParamDictionary';
import { TopicVm } from '@/interfaces/TopicVm';

const auth = namespace("auth");
const ux = namespace("ux");
const globals = namespace("globals");

@Component({
  components: {
    FileDrop,
    DlgTopicNotification,
    DlgTopic
  }
})
export default class ManageTopicNotifications extends Vue {
  @auth.Getter isAdmin: any;
  @auth.Getter isNotificationAdmin: any;
  busy = false;
  tab = 0;

  // Notifications
  editMode = false;
  showNotificationDlg = false;
  showDeleteNotificationDlg = false;
  notifications: TopicNotificationVm[] | null = null;
  selectedNotification: TopicNotificationVm | null = null;

  // Topics
  editTopicMode = false;
  showTopicDlg = false;
  showDeleteTopicDlg = false;
  topics: TopicVm[] = [];
  selectedTopic: TopicVm | null = null;

  mounted() {
    if (!this.isAdmin && !this.isNotificationAdmin)
      router.push('/');

    this.loadNotificationList();
    this.loadTopics();
    this.selectedNotification = {
      id: 0,
      topics: <string[]>[],
      title: '',
      body: '',
      htmlBody: '',
      image: '',
      deepLink: '',
      urls: <string[]>[],
      createdDate: new Date(),
      createdBy: '',
      disabled: false,
      noted: false,
      outdated: false
    };
  }

  async loadNotificationList() {
    let result = await rest.url('notification/getAllTopicNotificationList').get();
    this.notifications = new List(result as TopicNotificationVm[]).orderBy(x => x.createdDate).toArray();
  }

  async loadTopics() {
    this.topics = await rest.url('notification/getTopics').get();
  }

  onNewNotification() {
    this.selectedNotification!.id = 0;
    this.selectedNotification!.topics = <string[]>['l3rn-online'];
    this.selectedNotification!.title = '';
    this.selectedNotification!.title = '';
    this.selectedNotification!.body = '';
    this.selectedNotification!.htmlBody = '';
    this.selectedNotification!.image = '';
    this.selectedNotification!.createdDate = new Date();
    this.selectedNotification!.disabled = false;
    this.selectedNotification!.noted = false;
    this.selectedNotification!.outdated = false;
    this.selectedNotification!.deepLink = '';
    this.selectedNotification!.urls = <string[]>[];

    this.editMode = false;
    this.showNotificationDlg = true;
  }

  onEdit(notification: TopicNotificationVm) {
    // this.selectedNotification!.id = notification.id;
    // this.selectedNotification!.topics = notification.topics;
    // this.selectedNotification!.title = notification.title;
    // this.selectedNotification!.title = notification.title;
    // this.selectedNotification!.body = notification.body;
    // this.selectedNotification!.htmlBody = notification.htmlBody;
    // this.selectedNotification!.image = notification.image;
    // this.selectedNotification!.createdDate = notification.createdDate;
    this.selectedNotification = _.clone(notification);

    this.editMode = true;
    this.showNotificationDlg = true;
  }

  onDelete(notification: TopicNotificationVm) {
    this.selectedNotification = notification;
    this.showDeleteNotificationDlg = true;
  }

  onUpdate() {
    this.loadNotificationList();
  }

  async deleteNotification() {
    if (this.busy == true)
      return;
    this.busy = true;

    rest.url('notification/removeTopicNotification').post(this.selectedNotification)
        .then(() => {
          this.$store.commit("ux/SB_SUCCESS", {
            message: "Benachrichtigung gelöscht",
            timeout: 3
          });
          this.loadNotificationList();
        })
        .finally(() => {
          this.busy = false;
          this.showDeleteNotificationDlg = false;
        });
  }

  // Topic functions
  onAddTopic() {
    this.selectedTopic = null;

    this.editTopicMode = false;
    this.showTopicDlg = true;
  }

  onEditTopic(topic: TopicVm) {
    this.selectedTopic = topic;

    this.editTopicMode = true;
    this.showTopicDlg = true;
  }

  onDeleteTopic(topic: TopicVm) {
    this.selectedTopic = topic;
    this.showDeleteTopicDlg = true;
  }

  async deleteTopic() {
    if (this.busy == true)
      return;
    this.busy = true;

    rest.url('notification/deleteTopic')
        .post(this.selectedTopic)
        .then(() => {
          this.$store.commit("ux/SB_SUCCESS", {
            message: "Thema gelöscht",
            timeout: 3
          });
          this.loadTopics();
        })
        .finally(() => {
          this.busy = false;
          this.showDeleteTopicDlg = false;
        });
  }

  get selectDescription() {
    if (!this.selectedTopic)
      return null;

    return new List(this.selectedTopic.descriptions!).firstOrDefault()?.item2 ?? null;
  }

  onUpdateTopics() {
    this.loadTopics();
  }
}
