















































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import router from "@/router";

// components
import UserManager from "@/components/Admin/UserManager.vue"; // @ is an alias to /src
import Logging from "@/components/Admin/Logging.vue";

// interfaces
// import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ServiceStatusVM } from "@/interfaces/ServiceStatusVM";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { namespace } from "vuex-class";

// Import pdfmake-wrapper and the fonts to use
// import { PdfMakeWrapper } from 'pdfmake-wrapper';
// import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
// import { Txt, IText } from 'pdfmake-wrapper';
// import { Img, IImg } from 'pdfmake-wrapper';

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
    UserManager,
    Logging
  }
})
export default class Admin extends Vue {
  @auth.Getter isAdmin: any;

  selectedView: string = "userAdmin";
  serviceStatus: ServiceStatusVM | null = null;
  sqlZipLoading = false;
  paramDir: ParamDictionary = { dictionary: {}};

  mounted() {
    // this.getServiceStatus();
    if(!this.isAdmin)
      router.push("/");
  }

  // For test only
  // async loadPdf() {
  //   // Set the fonts to use
  //   PdfMakeWrapper.setFonts(pdfFonts);

  //   // Define document
  //   const pdf = new PdfMakeWrapper();
  //   pdf.pageSize('A4'); // A4 paper is 595.35 x 841.995 pt. Since A4 is 210 x 297 mm => 1 mm = 2.835 pt
  //   // pdf.pageMargins([ 40, 60, 40, 60 ]);
  //   pdf.info({
  //     title: 'Zertifikat',
  //     author: 'L3RN online',
  //     subject: 'Titel der Schulung',
  //   });
  //   let def = pdf.getDefinition();
  //   console.log("doc definitions:", def);

  //   // Set Background image
  //   const backgroundImage: IImg = await new Img('Zertifikat.png').fit([595.35, 841.995]).build();
  //   pdf.background(backgroundImage);

  //   // Winner image
  //   // const winner: IImg = await new Img('82-winner.png').fit([200, 300]).alignment("center").absolutePosition(180, 200).build();
  //   // pdf.add(winner);

  //   // User name
  //   const text = new Txt([
  //     new Txt("Jörg Mändel\n\n").bold().fontSize(24).color("#4089be").end,
  //     new Txt("hat die Schulung\n").fontSize(18).color("#494949").end,
  //     new Txt("Temperaturkontrolle 1\n").bold().fontSize(24).color("#4089be").end,
  //     new Txt("am 01.10.2022 erfolgreich absolviert.\n").fontSize(18).color("#494949").end,
  //   ]).alignment("center").lineHeight(1.5).absolutePosition(180,350).end;
  //   pdf.add(text);

  //   // L3RN online
  //   // const l3rnOnline = new Txt([
  //   //   new Txt("L").color("#494949").end,
  //   //   new Txt("3").bold().color('#0C8AAD').end,
  //   //   new Txt("RN online").color("#494949").end,
  //   // ]).absolutePosition(480,785).end;
  //   // pdf.add(l3rnOnline);

  //   pdf.create().download();
  // }

  onCustomerSettings() {
      router.push("customerSettings");
  }

  async onCreateSqlZipFile() {
    this.sqlZipLoading = true;
    // this.paramDir.dictionary = {};
    // this.paramDir.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    await rest.url("admin/createSqlZipFile")
      .get()
      .then((fileName) => {
        this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
      })
      .finally(() => {
        this.sqlZipLoading = false;
      });
      // let fileName = `${this.grade}.${this.selectedSubject!.name ?? ""}-all.zip`;
  }

  async onCheckAssignment() {
    await rest.url("admin/checkForOrphanedAssignment")
      .get()
      .then((assignments) => {
        console.log("Orphaned Assignments", assignments);
      })
      .finally(() => {
      });
  }

  async onCheckDueDateNotifications() {
    await rest.url("admin/checkForDueDateNotifications")
      .get()
      .then((output) => {
        console.log(output);
      })
      .finally(() => {
      });
  }

  async getServiceStatus() {
    this.serviceStatus = await rest.url("admin/getServiceStatus").get();
    console.log(this.serviceStatus);
  }

  // async initOrganization() {
  //   let status = await rest.url("admin/initOrganizations").get();
  //   console.log(this.serviceStatus);
  // }
}
