







import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ICellRendererParams } from "ag-grid-community";
import moment, { duration } from "moment";

@Component
export default class UserMngActivityStatusCellRenderer extends Vue {
  params!: ICellRendererParams;
  // $refs!: {
  // };

  mounted() {
    // if (!this.params || this.params.value == undefined) return;
    // this.checked = this.params.value;
  }

  get iconColor() {
    if (!this.params || this.params.value == undefined) return this.$store.state.ux.colorError;
    // console.log("lastAccess", this.params.value);
    let lastAccess = moment(this.params.value);
    // console.log("lastAccess", lastAccess);
    if (lastAccess.isAfter(moment().subtract(30, "days")))
      return this.$store.state.ux.colorSuccess;
    if (lastAccess.isAfter(moment().subtract(90, "days")))
      return "#F5CD00";

    return this.$store.state.ux.colorError;
  }

  get lastAccess() {
    if (!this.params || this.params.value == undefined) return "-";
    // console.log("lastAccess", this.params.value);
    let lastAccess = moment(this.params.value).format("DD.MM.yyyy");
    return lastAccess;
  }
}
