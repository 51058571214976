

























import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import moment from "moment";

// interfaces

// components

const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class L3TimePicker extends Vue {
  @PropSync("dateTime")
  syncedDateTime!: Date | null;

  showTimeDlg = false;
  shownTime: string | null = null;
  selectedTime: string | null = null;

  $refs!: {};

  mounted() {
    this.init();
  }

  init() {
    this.shownTime = moment(this.syncedDateTime).format("HH:mm"); // HH => 24h format
    this.selectedTime = moment(this.syncedDateTime).format("HH:mm");
  }

  @Watch("syncedDateTime")
  onTimeChanged() {
    this.init();
  }

  openTimeDialog() {
    // console.log("openTimeDialog");
    this.selectedTime = moment(this.syncedDateTime).format("HH:mm");
    // console.log(this.selectedTime);
    this.showTimeDlg = true;
  }

  closeTimeDialog() {
    this.showTimeDlg = false;
  }

  updateTime() {
    // console.log("updateTime");
    let date =  moment(this.syncedDateTime).format("yyyy-MM-DD");
    let dateTime = `${date}T${this.selectedTime}`;
    this.syncedDateTime = moment(dateTime).toDate();
    this.$emit("timeChanged:TimePicker");
    this.closeTimeDialog();
  }
}
