






































import _, { templateSettings } from "lodash";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

// https://www.w3schools.com/tags/ref_av_dom.asp
@Component({
    components: {}
})
export default class CompVideo extends Vue {
  // @Prop() value!: string;
  @Prop() src!: string;
  @Prop() width!: string;
  @Prop() height!: string;
  @Prop() compSettings!: string;

  settings: any = null;
  currentTime: any = null;
  showDialog = false;
  showCloseBtn = false;

  $refs!: {
    l3VideoInline: any,
    l3VideoFullscreen: any
   };

  mounted() {
    if (this.compSettings) {
      this.settings = JSON.parse(this.compSettings);
    }
  }

  get isYoutube() {
    if (!this.src || this.src.toLowerCase().indexOf("youtube") == -1)
      return false;
    return true;
  }

  get youtubeLink() {
    if (!this.isYoutube)
      return "";

    let startIdx = this.src.toLowerCase().indexOf("?v=");
    if (startIdx == -1)
      return "";
    let endIdx = this.src.toLowerCase().indexOf(";", startIdx);
    if (endIdx == -1)
      endIdx = this.src.length;

    let ytCode = this.src.substring(startIdx + 3, endIdx);

    return `https://www.youtube-nocookie.com/embed/${ytCode}`; //_2m_kdXVmJQ
  }

  playPause() {
    if (this.$refs.l3VideoInline.paused)
      this.$refs.l3VideoInline.play();
    else
      this.$refs.l3VideoInline.pause();
  }

  async onOpenDlg() {
    if (!this.enableZoom)
      return;

    let paused = this.$refs.l3VideoInline.paused;
    this.$refs.l3VideoInline.pause();
    this.currentTime = this.$refs.l3VideoInline.currentTime;

    this.showDialog = true;
    await this.$globalHelper.delay(250);
    this.$refs.l3VideoFullscreen.currentTime = this.currentTime;
    if (paused)
      this.$refs.l3VideoFullscreen.pause();
    else
      this.$refs.l3VideoFullscreen.play();
    this.showCloseBtn = true;
  }

  async onCloseDlg() {
    let paused = this.$refs.l3VideoFullscreen.paused;
    this.$refs.l3VideoFullscreen.pause();
    this.currentTime = this.$refs.l3VideoFullscreen.currentTime;

    this.showCloseBtn = false;
    this.showDialog = false;
    await this.$globalHelper.delay(100);
    this.$refs.l3VideoInline.currentTime = this.currentTime;
    if (paused)
      this.$refs.l3VideoInline.pause();
    else
      this.$refs.l3VideoInline.play();
  }

  get widthValue() {
    if (!this.width && this.height)
      return Number(this.height) / 0.5625 + "px";

    if (!this.width)
      return "100%";

    if (this.width.endsWith("px") || this.width.endsWith("%"))
        return this.width;

    return this.width + "px";
  }

  get heightValue() {
    if (!this.height)
      return "100%";

    if (this.height.endsWith("px") || this.height.endsWith("%"))
      return this.height;

    return this.height + "px";
  }

  get ytPadding() {
    if (this.width && !this.height)
      return Number(this.width) * 0.5625 + "px";

    if (!this.width && this.height || this.width && this.height)
    return "0px";

    return "56.25%";
  }

  get enableZoom() {
    // Only for desktop
    if (this.$vuetify.breakpoint.xs)
      return false;

    if (this.settings?.enableZoom)
      return this.settings.enableZoom;

    return false;
  }

  get enableDownload() {
    if (this.settings?.enableDownload)
      return true;

    return false;
  }

  get controlsList() {
    if (this.enableDownload)
      return "";
    return "nodownload"
  }
}
